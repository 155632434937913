import "reflect-metadata"
import AmplilyTimelineWidget from "./timeline-widget.vue"
import AmplilySingle from "./single-widget.vue"
import AmplilyGridWidget from "./grid-widget.vue"
import AmplilyHorizontalWidget from "./horizontal-widget.vue"
import AmplilyPlaylistWidget from "./playlist-widget.vue"
import AmplilySliderWidget from "./slider-widget.vue"
import AmplilyResellerWidget from "@/pages/reseller-widget.vue"
import AmplilyTrailerTimelineWidget from "@/pages/trailer-timeline-widget.vue"
import { AwOptions } from "../models/AwOptions"
import { exposeAmplilyWidget } from "@/services/setupAmplilyWidget"
import { createWebinarVueApp } from "./create-webinar-vueapp"
;(window as any).setupAmplilyBundle = (options: AwOptions, layout: string, el: HTMLElement) => {
  let AmplilyWidget
  switch (layout) {
    case "single":
      AmplilyWidget = AmplilySingle
      break
    case "trailer-timeline":
      AmplilyWidget = AmplilyTrailerTimelineWidget
      break
    case "timeline":
      AmplilyWidget = AmplilyTimelineWidget
      break
    case "grid":
      AmplilyWidget = AmplilyGridWidget
      break
    case "horizontal":
      AmplilyWidget = AmplilyHorizontalWidget
      break
    case "playlist":
      AmplilyWidget = AmplilyPlaylistWidget
      break
    case "slider":
      AmplilyWidget = AmplilySliderWidget
      break
    case "story":
    case "reseller":
      AmplilyWidget = AmplilyResellerWidget
      break
  }
  createWebinarVueApp(AmplilyWidget, options, el)
}
exposeAmplilyWidget()
