
import { PosterUrl } from "@/models/PosterUrl"
import { PropType, defineComponent } from "vue"
export default defineComponent({
  props: {
    poster_url: { type: String, required: true },
    poster_urls: { type: Object as PropType<Array<PosterUrl>>, required: false, default: [] }
  },
  setup(props) {
    var poster = props.poster_urls.find(x => x.width == 240)?.url ?? props.poster_url
    return { poster }
  }
})
