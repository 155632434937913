<template>
  <div id="aw--c" :style="themeCss">
    <div id="aw-sl" style="font-size: 1em !important">
      <SeoJsonLdComponentVue :webinarInfo="webinarInfo" v-if="webinarInfo != undefined" />
      <TranslationDropdown :languages="webinarInfo.translation_language_codes" v-if="webinarInfo != undefined" />
      <TheaterPlayer
        :videos="lightboxVideos"
        :logoOverlay="webinarInfo.overlay_logo"
        :useDemoOverlay="webinarInfo.use_demo_overlay"
        :themeCss="themeCss"
        :ma="webinarInfo.ma"
        :cta="webinarInfo.cta"
        :isGated="webinarInfo.is_gated"
        :post_play="webinarInfo.post_play"
        v-if="webinarInfo && lightboxVideos && lightboxVideos.length > 0 && usetheaterMode == true"
      />
      <div v-if="webinarInfo != undefined" :key="webinarInfo.video_urls.dash_url">
        <MAGate :ma="webinarInfo.ma" :themeCss="themeCss" />
        <TitleTrailerCompoent
          :widgetData="webinarInfo"
          :usetheaterMode="usetheaterMode"
          v-if="webinarInfo.is_info_tab_visible == true"
        />
        <FullVideoCompoment
          v-if="webinarInfo.is_full_video_visible && webinarInfo.is_full_video_on_top"
          :fullvideo="webinarInfo.video_urls"
          :show-head="webinarInfo.moments.length > 0"
          :duration="webinarInfo.duration"
          :webid="webinarInfo.id"
          :title="translateWebinar(webinarInfo).title"
          :subtitleUrls="webinarInfo.subtitle_urls"
          :subtitles="webinarInfo.subtitles"
          :useDemoOverlay="webinarInfo.use_demo_overlay"
          :logoOverlay="webinarInfo.overlay_logo"
          :isgated="webinarInfo.is_gated"
          :ma="webinarInfo.ma"
          :usetheaterMode="usetheaterMode"
        />
        <HorizontalLayout :webinarInfo="webinarInfo" :usetheaterMode="usetheaterMode" />
        <FullVideoCompoment
          v-if="webinarInfo.is_full_video_visible && !webinarInfo.is_full_video_on_top"
          :fullvideo="webinarInfo.video_urls"
          :show-head="webinarInfo.moments.length > 0"
          :duration="webinarInfo.duration"
          :webid="webinarInfo.id"
          :title="translateWebinar(webinarInfo).title"
          :subtitleUrls="webinarInfo.subtitle_urls"
          :subtitles="webinarInfo.subtitles"
          :useDemoOverlay="webinarInfo.use_demo_overlay"
          :logoOverlay="webinarInfo.overlay_logo"
          :isgated="webinarInfo.is_gated"
          :ma="webinarInfo.ma"
          :usetheaterMode="usetheaterMode"
        />
        <div id="parmonic-resources">
          <RelatedResourceComponent :resources="webinarInfo.related_resources" />
        </div>
        <div id="parmonic-speakers">
          <SpeakerSection :speakers="webinarInfo.speakers" />
        </div>
      </div>
      <SliderSkeleton :loadingmsg="loadingmsg" v-else />
    </div>
  </div>
</template>

<script lang="ts">
import "@/assets/_base.scss"

import VideoPlayer from "../components/shared/VideoPlayer.vue"
import IframeVideoPlayer from "../components/shared/IframeVideoPlayer.vue"
import { WebinarInfo } from "@/models/WebinarInfo"
import { AwEventType } from "@/models/AwEventType"
import setThemeData from "@/services/setThemeData"
import { GoogleAnalyticsClient } from "@/services/GoogleAnalyticsService"
import { AnalyticsClient } from "@/services/AnalyticsService"
import { container } from "tsyringe"
import { WebinarDataService } from "@/services/WebinarDataServices"
import { getFullVideo } from "@/services/getFullVideoData"
import { computed, defineComponent, ref } from "@vue/runtime-core"
import injectStrict from "@/utils/injectStrict"
import { GlobalDataKey, LoggerKey, translationKey } from "@/symbols"
import HorizontalLayout from "@/components/layouts/HorizontalLayout.vue"
import { ContentType } from "@/models/ContentType"
import { AwEventStoreV2 } from "@/models/AwEventStoreV2"
import RelatedResourceComponent from "@/components/shared/RelatedResourceComponent.vue"
import MAGate from "@/components/MA/MAGate.vue"
import getCssVariables from "@/utils/getCssVariables"
import { isIOS } from "@/services/deviceDetection"
import TheaterPlayer from "@/components/shared/TheaterPlayer.vue"
import SeoJsonLdComponentVue from "@/components/shared/SeoJsonLdComponent.vue"
import momentAutoOpenTheatreMode from "@/composables/shared/moment-autoopen-theatre"
import { EventBusKey } from "@/symbols"
import TitleTrailerCompoent from "@/components/shared/InfoTab.vue"
import getTheaterVideos from "@/utils/getTheaterVideos"
import SpeakerSection from "@/components/ResellerLayout/SpeakerSection.vue"
import SliderSkeleton from "@/components/skeletons/slider.vue"
import handleAutoPlayEvent from "@/services/handle-autoplay"
import TranslationDropdown from "@/components/shared/Translation.vue"
import FullVideoCompoment from "@/components/shared/FullVideoComponent.vue"
export default defineComponent({
  components: {
    VideoPlayer,
    IframeVideoPlayer,
    HorizontalLayout,
    RelatedResourceComponent,
    MAGate,
    TheaterPlayer,
    SeoJsonLdComponentVue,
    TitleTrailerCompoent,
    SpeakerSection,
    SliderSkeleton,
    FullVideoCompoment,
    TranslationDropdown
  },
  props: {
    fetchId: { type: String, required: true }
  },
  setup() {
    let webinarId = ref(-1)
    let webinarInfo = ref<WebinarInfo | null>(null)
    let loadingmsg = ref("Loading video...")
    const globalData = injectStrict(GlobalDataKey).value
    let eventbus = injectStrict(EventBusKey)
    const themeCss = computed(() => getCssVariables(globalData.theme_data))
    const dataService = container.resolve(WebinarDataService)
    const analytics = container.resolve(AnalyticsClient)
    const gaAnalytics = container.resolve(GoogleAnalyticsClient)
    let logger = injectStrict(LoggerKey)
    let win = ref({
      width: window.innerWidth
    })
    window.addEventListener("resize", handleResize)
    function handleResize() {
      win.value.width = window.innerWidth
    }
    let lightboxVideos = computed(() => getTheaterVideos(webinarInfo))

    let usetheaterMode = computed(
      () =>
        webinarInfo.value &&
        webinarInfo.value.use_theater_mode == true &&
        !!window.parmonic_disable_theater_mode == false &&
        !isIOS(navigator.userAgent, navigator.maxTouchPoints)
    )
    handleAutoPlayEvent(globalData.instanceId)
    const { translateWebinar } = injectStrict(translationKey)
    return {
      eventbus,
      usetheaterMode,
      lightboxVideos,
      win,
      dataService,
      webinarInfo,
      themeCss,
      globalData,
      webinarId,
      loadingmsg,
      logger,
      analytics,
      gaAnalytics,
      translateWebinar,
      ContentType
    }
  },
  created() {
    this.loadData()
  },

  methods: {
    loadData() {
      this.dataService.getData(this.fetchId).then(async returnValue => {
        this.loadingmsg = "The video could not be loaded."
        if (returnValue == undefined) {
          this.logger!.error(`Parmonic Widget error: Webinar not found`)
          return
        }
        this.webinarInfo = returnValue as WebinarInfo
        this.webinarId = this.webinarInfo.id
        setThemeData(this.webinarInfo.theme_data, this.globalData)
        this.analytics.setUp(this.webinarId, null, null, null, this.globalData.analyticsEventsCaptureEndPoint, this.logger)
        this.gaAnalytics.setUp(this.webinarId, this.logger)
        var eventV2: AwEventStoreV2 = { eventType: AwEventType.WidgetViewed, type: ContentType.FullWidget }
        this.analytics.register(eventV2, null)
        this.globalData.fullVideoInfo = getFullVideo(this.webinarInfo)
        setTimeout(() => {
          momentAutoOpenTheatreMode(this.globalData.instanceId, this.eventbus)
        }, 500)
      })
    }
  }
})
</script>

<style lang="scss" scoped>
.full-video-label {
  font-size: 1.625em;
  margin-bottom: 0.625em;
  font-family: var(--font-family);
  font-weight: 500;
  line-height: normal;
  color: var(--font-color);
  text-align: center;
}
.mom-t {
  font-size: 1.25em;
  line-height: normal;
  margin: 0.625em 0px;
  font-weight: bold;
  width: 100%;
  color: var(--font-color);
}
.mom-d {
  font-size: 1em;
  margin-bottom: 0.375em;
  width: 100%;
  color: var(--font-color);
  line-height: normal;
}
.video-holder {
  padding-bottom: 2em;
}
.margin-bottom {
  margin-bottom: 2em;
}
.forWide {
  display: block;
}
.forNarrow {
  display: none;
}
@media (max-width: 450px) {
  .forWide {
    display: none;
  }
  .forNarrow {
    display: block;
  }
}
</style>
