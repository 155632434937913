import { Ref, ref, computed } from "vue"
export default function setUp(elm: Ref<HTMLElement | undefined>) {
  let playerHeight = ref(0)
  let width = ref(elm.value ? elm.value.clientWidth : 0)
  let height = computed(() => (playerHeight.value == 0 ? width.value * 0.55 : playerHeight.value))
  let containerClassList = computed(() => {
    return {
      small: width.value < 300,
      normal: width.value < 700 && width.value > 299,
      relative: true
    }
  })
  let handleResize = (h: number | undefined) => {
    if (h) playerHeight.value = h
    if (elm.value) width.value = elm.value.clientWidth
  }
  let setPlayerHeight = (height: number) => {
    playerHeight.value = height
  }
  return { width, height, containerClassList, handleResize, setPlayerHeight }
}
