import IGlobalData from "@/models/GlobalData"
import { ThemeData } from "../models/ThemeData"
export default function setThemeData(data: ThemeData | undefined, globalData: IGlobalData) {
  if (!data) return
  if (data.font_color) globalData.theme_data.font_color = data.font_color
  if (data.font_family) globalData.theme_data.font_family = data.font_family
  if (data.theme_color) globalData.theme_data.theme_color = data.theme_color
  if (data.font_size)
    globalData.theme_data.font_size = typeof data.font_size == "string" ? parseInt(data.font_size) : data.font_size
}
