import { MarketoLeadInfoResponse } from "@/models/ma/MALeadInfo"
import { CookieManagerService } from "@/services/CookieManagerService"
import axios from "axios"

export function getMarketoTrackerId(): string | undefined {
  const cookieMarketoLeadTracking = CookieManagerService.getByPattern(/_mkto_trk=([^;]+)/)
  return !!cookieMarketoLeadTracking && !!cookieMarketoLeadTracking[1] ? cookieMarketoLeadTracking[1].trim() : undefined
}

export function getPardotVisitorId(): number | undefined {
  const cookiePardotVisitor = CookieManagerService.getByPattern(/[;]*visitor_id[0-9]+=([^;]+)/)
  return !!cookiePardotVisitor && !!cookiePardotVisitor[1] ? Number(cookiePardotVisitor[1]) : undefined
}

export function getHubspotTrackerId(): string | undefined {
  const cookieLeadTracker = CookieManagerService.getByPattern(/hubspotutk=([^;]+)/)
  return !!cookieLeadTracker && !!cookieLeadTracker[1] ? cookieLeadTracker[1] : undefined
}

export function getEloquaTrackerId(): string | undefined {
  var fn = window.GetElqCustomerGUID
  return fn && typeof fn === "function" ? fn() : undefined
}
