<template>
  <div class="logo-overlay" :style="{ top: CTAVisible && !showEndedPage ? '45px !important' : '' }">
    <img :class="comClass" :src="logoUrl" alt="brand logo" />
  </div>
</template>
<script lang="ts">
import { PropType, Ref, computed, defineComponent } from "vue"
import { getColorSchemeFromString } from "@/utils/getColorSchemeFromString"
import { StringToSeconds } from "@/services/DurationFormatter"

export default defineComponent({
  props: {
    logoUrl: { type: String, required: true },
    cta: { type: Boolean, required: false, default: false },
    isPosterVisible: { type: Object as PropType<Ref<Boolean>>, required: true },
    colorScheme: { type: String, required: false, default: "LIGHT" },
    dur: { type: Object as PropType<Ref<number>>, required: true },
    continuefromTime: { type: Number, default: 0, required: false },
    hasEnded: { type: Object as PropType<Ref<Boolean>>, required: true },
    duration: { type: String, required: true },
    currentTime: { type: Object as PropType<Ref<number>>, required: true }
  },
  setup(props) {
    const comClass = ` ${getColorSchemeFromString(props.colorScheme)}`
    let CTAVisible = computed(() => {
      return props.cta && !props.isPosterVisible.value
    })
    let showEndedPage = computed(() => {
      return !props.continuefromTime && props.hasEnded.value && props.dur.value == StringToSeconds(props.duration)
    })
    return { comClass, CTAVisible, showEndedPage }
  }
})
</script>

<style scoped>
.logo-overlay {
  position: absolute;
  z-index: 4;
  width: 20%;
  height: calc(20% - 16px);
  max-width: 166px;
  max-height: 91px;
  top: 15px;
  right: 15px;
  display: flex;
  justify-content: right;
  align-content: flex-start;
  transition: top 0.35s;
}
.logo-overlay > img {
  display: block;
  max-width: 100%;
  max-height: 100%;
  object-fit: scale-down;
  border-radius: 6px;
  padding: 8px;
  height: fit-content;
}
.light {
  background-color: rgba(255, 255, 255, 0.5);
}
.dark {
  background-color: rgba(0, 0, 0, 0.5);
}
.small > .logo-overlay {
  top: 2px;
  right: 2px;
  padding: 2px 5px;
}
.normal > .logo-overlay {
  top: 10px;
  right: 10px;
}
</style>
