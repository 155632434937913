import { ContentType } from "@/models/ContentType"
export default function getVideoType(fileName: string | undefined | null): ContentType | undefined {
  if (!fileName) return undefined
  if (fileName.includes("/")) {
    if (fileName.indexOf("moment/") == 0) return ContentType.Moment
    if (fileName.indexOf("ad/") == 0) return ContentType.Ad
    if (fileName.indexOf("trailer/") == 0) return ContentType.Trailer
    if (fileName.indexOf("v/") == 0) return ContentType.Webinar
    if (fileName.indexOf("misc/") == 0) return ContentType.Misc
  } else {
    if (fileName.length < 16 && fileName.indexOf("m") == 0) return ContentType.Moment
    if (fileName.length > 16) {
      if (fileName.indexOf("ad") == 0) return ContentType.Ad
      if (fileName.indexOf("t") == 0) return ContentType.Trailer
      if (fileName.indexOf("v") == 0) return ContentType.Webinar
    }
  }
  return undefined
}
