import { AwEventV2 } from "@/models/AwEventV2"
import { AwEventType } from "@/models/AwEventType"
import { VideoCommandType } from "@/models/VideoCommandType"

export default function raiseBrowserEvents(event: AwEventV2) {
  const eventName = getEventName(event)
  event.eventType = eventName
  event.sessionId = null
  window.dispatchEvent(new CustomEvent("parmonic_event", { detail: event }))
}

const getEventName = (event: any) => {
  switch (event.eventType) {
    case AwEventType.VideoPlayed:
      if (event.command == VideoCommandType.Start) return "video_start"
      else if (event.command == VideoCommandType.End) return "video_end"
      else return "video_progress"
      break
    case AwEventType.WebinarViewed:
      return "widget_view"
      break
    case AwEventType.MomentShared:
      return "social_share"
      break
    default:
      return event.eventType
      break
  }
}
