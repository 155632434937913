<template>
  <div
    class="pw-player-poster player-adon poster-background"
    @click="() => play()"
    v-if="isPosterVisible.value"
    :style="cssVars"
  >
    <div class="poster-img"></div>
  </div>
</template>
<script lang="ts">
import { defineComponent, PropType, Ref } from "vue"
import { PosterUrl } from "@/models/PosterUrl"
export default defineComponent({
  props: {
    poster_url: { type: String, required: true },
    poster_urls: { type: Object as PropType<Array<PosterUrl>>, required: false, default: [] },
    play: { type: Function, required: true },
    isPosterVisible: {
      type: Object as PropType<Ref<Boolean>>,
      required: true
    }
  },
  setup(props) {
    var cssVars = {
      "--default-poster": `url(${props.poster_url})`,
      "--background-240": `url(${geturl(240)})`,
      "--background-480": `url(${geturl(480)})`,
      "--background-960": `url(${geturl(960)})`,
      "--background-1440": `url(${geturl(1440)})`,
      "--background-1920": `url(${geturl(1920)})`
    }
    function geturl(width: number): string {
      if (!props.poster_urls) props.poster_url
      var urlObj = props.poster_urls.find(x => x.width == width)
      return urlObj?.url ?? props.poster_url
    }
    return { cssVars }
  }
})
</script>
<style lang="scss">
.pw-player-poster {
  container-type: inline-size;
}
.poster-img {
  background: var(--default-poster);
}
@container (max-width: 240px) {
  .poster-img {
    background: var(--background-240);
  }
}
@container (min-width: 240px)and (max-width: 480px) {
  .poster-img {
    background: var(--background-480);
  }
}
@container (min-width: 480px ) and (max-width: 960px) {
  .poster-img {
    background: var(--background-960);
  }
}
@container (min-width: 960px)and (max-width: 1440px) {
  .poster-img {
    background: var(--background-1440);
  }
}
@container (min-width: 1440px)and (max-width: 1920px) {
  .poster-img {
    background-image: var(--background-1920);
  }
}
.poster-img {
  background-repeat: no-repeat;
  background-size: 100% 100%;
  width: 100%;
  height: 100%;
}
</style>
