
import "@/assets/_base.scss"

import VideoPlayer from "../components/shared/VideoPlayer.vue"
import IframeVideoPlayer from "../components/shared/IframeVideoPlayer.vue"
import { WebinarInfo } from "@/models/WebinarInfo"
import { AwEventType } from "@/models/AwEventType"
import setThemeData from "@/services/setThemeData"
import { GoogleAnalyticsClient } from "@/services/GoogleAnalyticsService"
import { AnalyticsClient } from "@/services/AnalyticsService"
import { container } from "tsyringe"
import { WebinarDataService } from "@/services/WebinarDataServices"
import { getFullVideo } from "@/services/getFullVideoData"
import { computed, defineComponent, ref } from "@vue/runtime-core"
import injectStrict from "@/utils/injectStrict"
import { GlobalDataKey, LoggerKey } from "@/symbols"
import SliderLayout from "@/components/layouts/SliderLayout.vue"
import { ContentType } from "@/models/ContentType"
import { AwEventStoreV2 } from "@/models/AwEventStoreV2"
import RelatedResourceComponent from "@/components/shared/RelatedResourceComponent.vue"
import MAGate from "@/components/MA/MAGate.vue"
import getCssVariables from "@/utils/getCssVariables"
import { isIOS, isMobile } from "@/services/deviceDetection"
import TheaterPlayer from "@/components/shared/TheaterPlayer.vue"
import SeoJsonLdComponentVue from "@/components/shared/SeoJsonLdComponent.vue"
import momentAutoOpenTheatreMode from "@/composables/shared/moment-autoopen-theatre"
import { EventBusKey, translationKey } from "@/symbols"
import TitleTrailerCompoent from "@/components/shared/InfoTab.vue"
import getTheaterVideos from "@/utils/getTheaterVideos"
import SpeakerSection from "@/components/ResellerLayout/SpeakerSection.vue"
import SliderSkeleton from "@/components/skeletons/slider.vue"
import { Postplay } from "@/models/PostPlayOptions"
import handleAutoPlayEvent from "@/services/handle-autoplay"
import TranslationDropdown from "@/components/shared/Translation.vue"
export default defineComponent({
  components: {
    VideoPlayer,
    IframeVideoPlayer,
    SliderLayout,
    RelatedResourceComponent,
    MAGate,
    TheaterPlayer,
    SeoJsonLdComponentVue,
    TitleTrailerCompoent,
    SpeakerSection,
    SliderSkeleton,
    TranslationDropdown
  },
  props: {
    fetchId: { type: String, required: true }
  },
  setup() {
    let webinarId = ref(-1)
    let webinarInfo = ref<WebinarInfo | null>(null)
    let loadingmsg = ref("Loading video...")
    const globalData = injectStrict(GlobalDataKey).value
    let eventbus = injectStrict(EventBusKey)
    const themeCss = computed(() => getCssVariables(globalData.theme_data))
    const dataService = container.resolve(WebinarDataService)
    const analytics = container.resolve(AnalyticsClient)
    const gaAnalytics = container.resolve(GoogleAnalyticsClient)
    let logger = injectStrict(LoggerKey)
    let win = ref({
      width: window.innerWidth
    })
    window.addEventListener("resize", handleResize)
    function handleResize() {
      win.value.width = window.innerWidth
    }
    let lightboxVideos = computed(() => getTheaterVideos(webinarInfo))

    let usetheaterMode = computed(
      () =>
        webinarInfo.value &&
        webinarInfo.value.use_theater_mode == true &&
        !!window.parmonic_disable_theater_mode == false &&
        !isIOS(navigator.userAgent, navigator.maxTouchPoints)
    )
    const { translateMoment, translateWebinar } = injectStrict(translationKey)
    return {
      eventbus,
      usetheaterMode,
      lightboxVideos,
      win,
      dataService,
      webinarInfo,
      themeCss,
      globalData,
      webinarId,
      loadingmsg,
      logger,
      analytics,
      gaAnalytics,
      Postplay,
      ContentType,
      translateMoment,
      translateWebinar
    }
  },
  created() {
    this.loadData()
  },
  mounted() {
    if (isMobile()) {
      handleAutoPlayEvent(this.globalData.instanceId)
    }
  },
  methods: {
    loadData() {
      this.dataService.getData(this.fetchId).then(async returnValue => {
        this.loadingmsg = "The video could not be loaded."
        if (returnValue == undefined) {
          this.logger!.error(`Parmonic Widget error: Webinar not found`)
          return
        }
        this.webinarInfo = returnValue as WebinarInfo
        this.webinarId = this.webinarInfo.id
        setThemeData(this.webinarInfo.theme_data, this.globalData)
        this.analytics.setUp(this.webinarId, null, null, null, this.globalData.analyticsEventsCaptureEndPoint, this.logger)
        this.gaAnalytics.setUp(this.webinarId, this.logger)
        var eventV2: AwEventStoreV2 = { eventType: AwEventType.WidgetViewed, type: ContentType.FullWidget }
        this.analytics.register(eventV2, null)
        this.globalData.fullVideoInfo = getFullVideo(this.webinarInfo)
        if (this.webinarInfo.use_theater_mode == true) {
          setTimeout(() => {
            momentAutoOpenTheatreMode(this.globalData.instanceId, this.eventbus)
          }, 500)
        }
      })
    }
  }
})
