import { Ref } from "vue"
export default function setupShortcutKeys(video: Ref<HTMLVideoElement | undefined>, isPlaying: Ref<Boolean>) {
  const seek = (duration: number) => {
    if (!video.value) return
    const newTime: number = video.value.currentTime + duration
    if (duration > 0) {
      video.value.currentTime = newTime <= video.value.duration ? newTime : video.value.duration
    } else {
      video.value.currentTime = newTime > 0 ? newTime : 0
    }
  }
  document.addEventListener(
    "keydown",
    event => {
      if (!video.value) return
      var name = event.key
      if (name == " " && window.parmonicFocusedVideo) {
        window.parmonicFocusedVideo.togglePlay()
        event.preventDefault()
      }
      if (isPlaying.value == false) return
      switch (name) {
        case "l":
          if (video) seek(5)
          break
        case "j":
          seek(-5)
          break
        case "ArrowRight":
          seek(10)
          break
        case "ArrowLeft":
          seek(-10)
          break
        case "0":
        case "1":
        case "2":
        case "3":
        case "4":
        case "5":
        case "6":
        case "7":
        case "8":
        case "9":
          const seek_time: number = (parseInt(name) / 10) * video.value.duration - video.value.currentTime
          seek(seek_time)
          break
      }
    },
    false
  )
}
