import { ref, computed, Ref } from "vue"
const VOLUME_BTN = `<svg class="icon-control" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M15.4249 8.21205L10.2 11.002H6.73333C5.77604 11.002 5 11.7479 5 12.668V19.332C5 20.2521 5.77604 20.998 6.73333 20.998H10.2L15.4249 23.788C16.5802 24.4049 18 23.6019 18 22.3316V9.66839C18 8.3981 16.5802 7.59514 15.4249 8.21205Z" fill="white"/>
<path d="M20 20.3378C20 21.1305 20.8091 21.6541 21.4393 21.1732C22.9957 19.9857 24 18.1114 24 16.0025C24 13.8937 22.9957 12.0194 21.4393 10.8318C20.8091 10.351 20 10.8745 20 11.6672C20 12.0378 20.1915 12.3769 20.4698 12.6216C21.4079 13.4463 22 14.6553 22 16.0025C22 17.3498 21.4079 18.5587 20.4698 19.3834C20.1915 19.6281 20 19.9672 20 20.3378Z" fill="white"/>
<path d="M22 23.4123C22 24.1701 22.7713 24.6764 23.4138 24.2745C26.1681 22.5515 28 19.491 28 16.0027C28 12.5144 26.1681 9.4539 23.4138 7.73087C22.7713 7.32897 22 7.83533 22 8.59312C22 8.97919 22.213 9.33 22.5345 9.54371C24.6233 10.9321 26 13.3066 26 16.0027C26 18.6988 24.6233 21.0734 22.5345 22.4617C22.213 22.6754 22 23.0262 22 23.4123Z" fill="white"/>
</svg>`
const VOLUME_MUTE = `<svg class="icon-control" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M15.4249 8.21205L10.2 11.002H6.73333C5.77604 11.002 5 11.7479 5 12.668V19.332C5 20.2521 5.77604 20.998 6.73333 20.998H10.2L15.4249 23.788C16.5802 24.4049 18 23.6019 18 22.3316V9.66839C18 8.3981 16.5802 7.59514 15.4249 8.21205Z" fill="white"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M27.6316 12C28.0269 12.3857 28.0346 13.0189 27.6488 13.4141L25.2215 15.9012L27.6487 18.3883C28.0345 18.7835 28.0268 19.4166 27.6315 19.8024C27.2363 20.1881 26.6031 20.1804 26.2174 19.7852L23.8242 17.333L21.4311 19.7851C21.0453 20.1804 20.4122 20.1881 20.017 19.8023C19.6217 19.4166 19.614 18.7835 19.9998 18.3882L22.4269 15.9012L19.9997 13.4142C19.6139 13.0189 19.6216 12.3858 20.0169 12C20.4121 11.6143 21.0453 11.622 21.431 12.0173L23.8242 14.4695L26.2175 12.0172C26.6032 11.622 27.2364 11.6143 27.6316 12Z" fill="white"/>
</svg>`
export default function setUp(video: Ref<HTMLMediaElement | undefined>) {
  let volume = ref(1)
  let isMuted = ref(false)
  let volumeLevel = computed(() => {
    var val = isMuted.value ? 100 : (1 - volume.value) * 100
    return val
  })
  const volumecss = computed(() => {
    if (video.value) video.value.volume = 1 - volumeLevel.value / 100
    return {
      width: `60px !important`,
      height: `4px !important`,
      background: "linear-gradient(to left, #9f9f9f " + volumeLevel.value + "%, #efefef 1% 81%, #ffffff) !important"
    }
  })
  const volumeBtnSvg = computed(() => (isMuted.value ? VOLUME_MUTE : VOLUME_BTN))
  return {
    isMuted,
    volume,
    volumecss,
    volumeBtnSvg
  }
}
export const BUTTON_SVGS = { VOLUME_BTN, VOLUME_MUTE }
