import { Ref } from "vue"
import IGlobalData from "@/models/GlobalData"
import shaka from "shaka-player"
import injectStrict from "@/utils/injectStrict"
import { LoggerKey } from "@/symbols"
export default function setUp(
  video: Ref<HTMLVideoElement | undefined>,
  videoPlaybackError: Ref<boolean>,
  isPreview: boolean
) {
  var player: typeof shaka.Player
  let logger = injectStrict(LoggerKey)
  async function loadPlayer() {
    if (shaka.log) shaka.log.setLevel(shaka.log.Level.ERROR)

    if (!shaka.Player.isBrowserSupported()) {
      await shaka.polyfill.installAll()
    }
    player = new shaka.Player(video.value)
    // For more Shaka Player options, see: https://github.com/google/shaka-player
    const PLAYER_CONFIG = {
      abr: {
        defaultBandwidthEstimate: 16000000 // 2 MB/s or 1.6e+7 bits/s
      },
      playRangeStart: 0,
      playRangeEnd: Infinity,
      streaming: {
        bufferingGoal: 20,
        retryParameters: {
          maxAttempts: 5
        },
        failureCallback: (err: any) => {
          videoPlaybackError.value = true
          logger?.error(`Video Buffering failed::${err}`)
        }
      }
    }
    const PREVIEW_PLAYER_CONFIG = {
      playRangeStart: 0,
      playRangeEnd: Infinity,
      streaming: {
        bufferBehind: 600,
        bufferingGoal: 20,

        failureCallback: (err: any) => {
          videoPlaybackError.value = true
          logger?.error(`Video Buffering failed::${err}`)
        }
      }
    }
    player.configure(isPreview ? PREVIEW_PLAYER_CONFIG : PLAYER_CONFIG)
  }
  async function loadhls(hls_url: string, continuefromTime: number = 0) {
    await loadPlayer()
    player
      .load(hls_url)
      .then(() => {
        if (continuefromTime > 0) {
          video.value!.currentTime = continuefromTime
          video.value!.play()
        }
      })
      .catch(async (error: any) => {
        if (/InvalidStateError/i.test(error)) {
          logger?.warn("Parmonic Widget Warning: State Error occured reloading video")
          await loadhls(hls_url)
          return
        } else {
          videoPlaybackError.value = true
          logger?.error(`Parmonic Widget Error: Loading stream failed: ${error}`)
        }
      })
  }
  async function loaddash(dash_url: string) {
    await loadPlayer()
    try {
      await player.load(dash_url)
    } catch (error: any) {
      if (/InvalidStateError/i.test(error)) {
        logger?.warn("Parmonic Widget Warning: State Error occured reloading video ")
        await loaddash(dash_url)
        return
      } else {
        videoPlaybackError.value = true
        logger?.error(`Parmonic Widget Error: Loading stream failed: ${error}`)
      }
    }
  }
  async function detachShakaPlayer() {
    if (player) await player.detach()
  }

  return { loadhls, loaddash, detachShakaPlayer }
}
