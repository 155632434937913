export default {
  "Full Video": "Vídeo completo",
  "Related Resources": "Recursos Relacionados",
  Blog: "Blog",
  Play: "Jogar",
  "No Captions": "Sem Legendas",
  Captions: "Legendas",
  "Toggle fullscreen": "Alternar tela cheia",
  "playback speed": "Velocidade de reprodução",
  "Play with captions in": "Reproduzir com legendas em",
  Seek: "Avançar/Retroceder",
  "Watch Trailer": "Assistir ao Trailer",
  "Watch full video": "Assistir ao vídeo completo",
  "Watch Video": "Assistir vídeo",
  Speakers: "Alto-falantes",
  Infographic: "Infográfico",
  eBook: "eBook",
  Webinar: "Webinar",
  Video: "Vídeo",
  "White Paper": "White Paper",
  Slides: "Slides",
  Article: "Artigo",
  "Icon not found exception": "Exceção de ícone não encontrado",
  "Key Moments": "Momentos Chave",
  Resources: "Recursos"
}
