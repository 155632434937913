
import { computed, defineComponent, PropType } from "vue"
import injectStrict from "@/utils/injectStrict"
import { translationKey } from "@/symbols"
import { RelatedResourceData } from "@/models/RelatedResourceData"
import getResourceSvg from "@/services/relatedResourceSvgProvider"
import { container } from "tsyringe"
import { AnalyticsClient } from "@/services/AnalyticsService"
import { AwEventType } from "@/models/AwEventType"
export default defineComponent({
  props: {
    resources: { type: Object as PropType<Array<RelatedResourceData>>, required: false },
    allowaccess: { type: Boolean, required: false, default: true }
  },
  setup(props) {
    var showRelatedResources = computed(() => props.resources && props.resources.length > 0)
    const analytics = container.resolve(AnalyticsClient)

    let onClick = (res: RelatedResourceData) => {
      if (res.id) analytics.register({ eventType: AwEventType.ResourceClicked, relatedResourceId: res.id }, null)
    }

    const { translateResource, translateLabels } = injectStrict(translationKey)
    return { showRelatedResources, getResourceSvg, onClick, translateResource, translateLabels }
  },
  methods: {
    getResourceLabel(lbl: string): string {
      switch (lbl) {
        case "WEB":
          return this.translateLabels("Web") ?? ""
          break
        case "INFOGRAPHIC":
          return this.translateLabels("Infographic") ?? ""
          break
        case "EBOOK":
          return this.translateLabels("eBook") ?? ""
          break
        case "BLOGPOST":
          return this.translateLabels("Blog") ?? ""
          break
        case "WEBINAR":
          return this.translateLabels("Webinar") ?? ""
          break
        case "VIDEO":
          return this.translateLabels("Video") ?? ""
          break
        case "PAPER":
          return this.translateLabels("White Paper") ?? ""
          break
        case "SLIDEDECK":
          return this.translateLabels("Slides") ?? ""
          break
        case "ARTICLE":
          return this.translateLabels("Article") ?? ""
          break
        default:
          throw new Error(this.translateLabels("Icon not found exception ") ?? "")
          break
      }
    }
  }
})
