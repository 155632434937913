<template>
  <div class="pw-continue-play player-adon" v-if="showEndedPage && !pressed_close">
    <div class="cont-plya-close" @click="clickClose()">
      <svg viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M5 5L15 15" class="stroke" stroke-width="2" stroke-linecap="round" />
        <path d="M5 15L15 5" class="stroke" stroke-width="2" stroke-linecap="round" />
      </svg>
    </div>
    <div class="pw-continue-play-button" @click="() => updateWithFullVideo()">
      <svg class="cont-play-arrow" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M8.00001 8.73444C8.00001 7.13218 9.7458 6.14062 11.1219 6.96127L23.132 14.1234C24.4748 14.9241 24.4748 16.8689 23.132 17.6697L11.1219 24.8319C9.7458 25.6526 8 24.661 8 23.0587L8.00001 8.73444Z"
          class="play-arrow"
        />
      </svg>
      <span class="cont-plat-txt" v-html="cont_play_btn_txt"></span>
    </div>
  </div>
</template>
<style scoped>
.pw-continue-play-button:hover {
  opacity: 1;
}
</style>
<script lang="ts">
import { isIOS } from "@/services/deviceDetection"
import { GlobalDataKey, translationKey } from "@/symbols"
import injectStrict from "@/utils/injectStrict"
import { computed, defineComponent, PropType, ref, Ref } from "vue"

export default defineComponent({
  props: {
    continuefromTime: { type: Number, required: true },
    hasEnded: { type: Object as PropType<Ref<Boolean>>, required: true },
    dur: { type: Object as PropType<Ref<Number>>, required: true },
    updateWithFullVideo: { type: Function, required: true }
  },
  setup(props) {
    const globalData = injectStrict(GlobalDataKey)
    const { translateLabels } = injectStrict(translationKey)
    const pressed_close = ref(false)
    let showEndedPage = computed(() => {
      pressed_close.value = false
      return props.continuefromTime > 0 && props.hasEnded.value && globalData.value.fullVideoInfo.isReady
    })
    let cont_play_btn_txt = computed(() =>
      isIOS(navigator.userAgent, navigator.maxTouchPoints)
        ? translateLabels(`Watch full video`)
        : translateLabels(`Continue watching`)
    )
    return { showEndedPage, cont_play_btn_txt, pressed_close, translateLabels }
  },
  methods: {
    clickClose() {
      this.pressed_close = true
    }
  }
})
</script>
