import { ContentType } from "@/models/ContentType"
import { WebinarInfo } from "@/models/WebinarInfo"
import { LightboxVideo } from "@/models/lightbox/LightboxModels"
import { Ref } from "vue"

export default function getTheaterVideos(webinarInfo: Ref<WebinarInfo | null>): Array<LightboxVideo> {
  let lgtboxmoments: Array<LightboxVideo> = []
  if (!webinarInfo.value) return lgtboxmoments
  if (webinarInfo.value.moments && webinarInfo.value.moments.length > 0) {
    webinarInfo.value.moments.forEach(m => {
      lgtboxmoments.push(
        new LightboxVideo(
          parseInt(m.id),
          m.video_urls,
          m.subtitles,
          m.duration,
          parseInt(m.id),
          ContentType.Moment,
          m.title,
          m.end_time
        )
      )
    })
  }
  if (webinarInfo.value.video_urls) {
    lgtboxmoments.push(
      new LightboxVideo(
        -1,
        webinarInfo.value.video_urls,
        webinarInfo.value.subtitles,
        webinarInfo.value.duration,
        -1,
        ContentType.Webinar,
        webinarInfo.value.title,
        0
      )
    )
  }
  if (webinarInfo.value.trailer_data) {
    lgtboxmoments.push(
      new LightboxVideo(
        -1,
        webinarInfo.value.trailer_data.video_urls,
        webinarInfo.value.trailer_data.subtitles,
        webinarInfo.value.trailer_data.duration,
        -1,
        ContentType.Trailer,
        webinarInfo.value.title,
        0
      )
    )
  }
  return lgtboxmoments
}
