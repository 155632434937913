<template>
  <div class="moment-box amplily-player">
    <div class="content-holder">
      <div class="title" v-html="title_txt" v-if="title"></div>
      <div class="timeline-hldr">
        <div class="trailer-timeline" :style="css"></div>
        <svg
          v-for="(m, i) in momentDataArr"
          :key="i"
          :style="{ left: `${m.start_per}% !important` }"
          class="pt start"
          width="4"
          height="4"
          viewBox="0 0 4 4"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle
            cx="2"
            cy="2"
            r="2"
            :class="{
              'fill-themecolor-5': !m.isResource,
              'fill-tranparent': m.isResource,
              'fill-themecolor': activeMoments.includes(m.id) && !m.isResource
            }"
          />
        </svg>
        <svg
          v-for="(m, i) in momentDataArr"
          :key="i"
          :style="{ left: `${m.end_per}% !important` }"
          class="pt end"
          width="4"
          height="4"
          viewBox="0 0 4 4"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <circle
            cx="2"
            cy="2"
            r="2"
            :class="{
              'fill-themecolor-5': !m.isResource,
              'fill-tranparent': m.isResource,
              'fill-themecolor': activeMoments.includes(m.id) && !m.isResource
            }"
          />
        </svg>
      </div>
      <div class="moments" ref="trackElm">
        <div class="track">
          <div
            :class="{
              'moment-holdr': true,
              animate: true,
              slow: !isVisited
            }"
            v-for="(m, i) in momentDataArr"
            :key="i"
            :style="{
              transform: `translateX(${m.transform}px) !important`,
              opacity: `${m.opacity} !important`
            }"
            :ref="
              el => {
                if (el) momentElms[i] = el
              }
            "
          >
            <div class="relative" v-if="!m.isResource">
              <img class="image" :src="m.poster_url" alt="" />
              <div class="duration" v-html="formatDate(m.duration)"></div>
            </div>
            <div class="relative" v-else>
              <div class="icon-holder">
                <div class="icon icon-color" v-html="getResourceSvg(m.resourceType)"></div>
              </div>
              <div class="duration" v-html="m.resourceType"></div>
            </div>
            <span class="title truncate-overflow" v-html="translateTrailerBox(m).title"></span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<style lang="scss" scoped>
.relative {
  position: relative;
}
.icon-holder {
  width: 150px;
  height: 85px;
  border: 1px solid var(--theme-contrast);
  background-color: var(--theme-color-6);
  border-radius: 8px;
}
.type {
  position: absolute;
  right: 3px;
  bottom: 3px;
  margin: 0;
  color: #ffffff;
  font-size: 14px;
  padding: 3px 5px;
  background-color: rgba(156, 156, 156, 1);
  width: fit-content;
  border-radius: 6px;
  text-transform: lowercase;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans",
    "Helvetica Neue", sans-serif;
}
.icon {
  position: absolute;
  width: 31px;
  height: 31px;
  display: flex;
  justify-content: center;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
}
.icon-color {
  fill: var(--theme-contrast);
}
</style>
<script lang="ts">
import { defineComponent, Ref, ref, PropType, computed } from "vue"
import { formatDatefromSeconds, StringToSeconds, formatDate } from "@/services/DurationFormatter"
import injectStrict from "@/utils/injectStrict"
import { LoggerKey, translationKey } from "@/symbols"
import { TrailerMomentInfo } from "@/models/TrailerMomentInfo"
import { RelatedResourceData } from "@/models/RelatedResourceData"
import { Translation } from "@/models/translations"
import getResourceSvg from "@/services/relatedResourceSvgProvider"
const WIDTH_PER_MOMENT = 175

let count = 0
export default defineComponent({
  props: {
    moments: {
      type: Object as PropType<Array<TrailerMomentInfo>>,
      required: true
    },
    resources: {
      type: Object as PropType<Array<RelatedResourceData> | null>,
      required: false,
      default: null
    },
    webinar_duration: { type: String, required: true },
    title: { type: String, required: false }
  },
  setup(props) {
    const ZERO_WIDTH_SPACES_REGEX = /([\u200B]+|[\u200C]+|[\u200D]+|[\u200E]+|[\u200F]+|[\uFEFF]+)/g

    let title_txt = props.title
      ? props.title.replace(ZERO_WIDTH_SPACES_REGEX, "").replace("{{ moment_count }}", props.moments.length.toString())
      : ""
    let number_of_moments = ref(4.5)
    const logger = injectStrict(LoggerKey)
    const trackElm = ref<HTMLElement>()
    let base_moment = 0
    let momentElms = ref<Array<HTMLElement>>([])
    let isTransitioning = ref(false)
    let totDuration = StringToSeconds(props.webinar_duration)
    let dir_isRight = false
    props.moments.sort((a, b) => (a.start_time < b.start_time ? -1 : 1))
    let prev = 0
    let used_per = 0
    let momentDataArr: Ref<Array<MomentData>> = ref([])
    let isVisited = ref(false)
    let translate = ref(false)
    let current_moment = ref(0)
    props.moments.forEach((moment, i) => {
      let space = moment.start_time - prev
      let dur = moment.end_time - moment.start_time
      let space_per = (space * 100) / totDuration
      let dur_per = (dur * 100) / totDuration
      momentDataArr.value.push(
        new MomentData(
          i,
          Math.floor((used_per + space_per) * 100) / 100,
          Math.floor((used_per + space_per + dur_per) * 100) / 100,
          moment.poster_url,
          moment.title,
          moment.duration,
          false,
          "",
          moment.translations
        )
      )
      prev = moment.end_time
      used_per += space_per + dur_per
    })
    props.resources?.forEach((resource, i) => {
      momentDataArr.value.push(
        new MomentData(i + props.moments.length, 0, 0, "", resource.title, "", true, resource.icon, resource.translations)
      )
    })
    let activeMoments: Ref<Array<number>> = ref([])
    let css = computed(() => {
      let end =
        current_moment.value + Math.floor(number_of_moments.value) < momentDataArr.value.length
          ? current_moment.value + Math.floor(number_of_moments.value)
          : momentDataArr.value.length
      activeMoments.value = []
      for (var i = current_moment.value; i < end; i++) activeMoments.value.push(i)
      end = Math.floor(number_of_moments.value) - activeMoments.value.length
      for (var i = 0; i < end; i++) activeMoments.value.push(i)
      let cs = "linear-gradient(to right,"
      prev = 0
      momentDataArr.value.forEach(moment => {
        let isActive = activeMoments.value.includes(moment.id)
        cs += `var(--theme-color-2) ${prev}% ${moment.start_per}%,
      ${isActive ? "var(--theme-color)" : "var(--theme-color-5)"} ${moment.start_per}% ${moment.end_per}%,`
        prev = moment.end_per
      })
      cs += `var(--theme-color-2) ${prev}% 100%) !important`
      return { background: cs }
    })
    const { translateTrailerBox } = injectStrict(translationKey)
    return {
      title_txt,
      trackElm,
      activeMoments,
      css,
      base_moment,
      current_moment,
      dir_isRight,
      momentElms,
      logger,
      isTransitioning,
      translate,
      isVisited,
      momentDataArr,
      number_of_moments,
      formatDatefromSeconds,
      formatDate,
      getResourceSvg,
      translateTrailerBox
    }
  },
  mounted() {
    this.number_of_moments = (this.trackElm?.clientWidth ?? 0) / WIDTH_PER_MOMENT
    this.number_of_moments = this.number_of_moments < 1 ? 1 : this.number_of_moments
    if (Math.ceil(this.number_of_moments) < this.momentDataArr.length - 1) this.setUpCarousel()
  },
  methods: {
    setUpCarousel() {
      this.momentElms.forEach(moment => {
        moment.addEventListener("transitionend", this.transitionComplted)
      })
      this.right()
    },
    right(to_skip: number = 1) {
      if (this.isTransitioning) return
      this.dir_isRight = true
      this.isTransitioning = true
      this.current_moment += to_skip
      if (this.current_moment >= this.momentDataArr.length) this.current_moment -= this.momentDataArr.length
      this.momentDataArr.forEach(moment => {
        moment.transform -= WIDTH_PER_MOMENT * to_skip
      })
      this.checkRight(to_skip)
    },
    transitionComplted() {
      this.isTransitioning = false
      count++
      if (count == this.momentElms.length) {
        count = 0
        this.momentDataArr.forEach(m => (m.opacity = 1))
        this.right()
      }
    },
    checkRight(to_skip: number = 1) {
      this.momentDataArr.forEach(moment => {
        if (moment.transform < moment.id * WIDTH_PER_MOMENT * -1 - to_skip * WIDTH_PER_MOMENT) {
          moment.opacity = 0
          moment.transform += WIDTH_PER_MOMENT * this.momentDataArr.length
        }
      })
    }
  }
})

class MomentData {
  id: number
  duration: string
  start_per: number = 0
  end_per: number = 0
  poster_url: string
  title: string = ""
  isActive = false
  transform: number = 0
  opacity: number = 1
  isResource: boolean = false
  resourceType: string = ""
  translations: Translation[] | null | undefined
  constructor(
    id: number,
    start_per: number,
    end_per: number,
    poster_url: string,
    title: string,
    duration: string,
    isResource: boolean,
    resourceType: string,
    translations: Translation[] | null | undefined
  ) {
    this.id = id
    this.start_per = start_per
    this.end_per = end_per
    this.poster_url = poster_url
    this.title = title
    this.duration = duration
    this.translations = translations
    ;(this.isResource = isResource), (this.resourceType = resourceType)
  }
}
</script>
