<template>
  <div id="parmonic-moments" ref="momentContainer" :style="cssVariables">
    <div class="timeline-line"></div>
    <div class="moment-holder" v-for="(m, i) in moments" :key="i">
      <div class="video-icon">
        <div class="icon">
          <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 293.11 293.1">
            <g>
              <circle class="icon-video-color-1" cx="146.55" cy="146.55" r="139.55" />
              <path
                class="icon-video-color-2"
                d="M146.55,293.1C65.74,293.1,0,227.36,0,146.55S65.74,0,146.55,0s146.55,65.74,146.55,146.55-65.74,146.55-146.55,146.55Zm0-279.1C73.46,14,14,73.46,14,146.55s59.46,132.55,132.55,132.55,132.55-59.46,132.55-132.55S219.64,14,146.55,14Z"
              />
            </g>
            <rect class="icon-video-color-2" x="85.2" y="103.77" width="83.72" height="83.72" rx="11.5" ry="11.5" />
            <path
              class="icon-video-color-2"
              d="M174.85,129.54v32.87l24.09,17.93c.25,.11,4.53,1.91,8.4-.75,3.54-2.42,3.72-6.49,3.74-6.91v-55.47c-.08-.54-.72-4.39-4.3-6.54-2.82-1.69-6.4-1.68-9.34,0-7.53,6.29-15.07,12.58-22.6,18.86Z"
            />
          </svg>
        </div>
      </div>
      <div class="momemt-box">
        <div class="video-holder">
          <div class="rnd-crns amplily-player" :id="'moment-' + m.id">
            <Player
              :poster="m.video_urls.poster_url"
              :video_urls="m.video_urls"
              :subtitles="m.subtitles"
              :subtitleUrls="m.subtitle_urls"
              :duration="m.duration"
              :videoType="ContentType.Moment"
              :title="translateMoment(m).title"
              :mid="m.id"
              :useDemoOverlay="useDemoOverlay"
              :logoOverlay="logoOverlay"
              :usetheaterMode="usetheaterMode"
              :ma="ma"
              :isgated="isgated"
              :continuefrom-time="m.end_time"
              :cta="cta"
              :post_play="post_play"
            />
          </div>
          <div>
            <SocialShare
              :id="m.id"
              :title="translateMoment(m).title"
              :description="translateMoment(m).description"
              :share_buttons="share_buttons"
            />
          </div>
        </div>
        <div class="info">
          <h3 v-html="translateMoment(m).title"></h3>
          <div class="quotes" v-if="quotePattern(translateMoment(m).description)">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 291.06 219.57">
              <g>
                <circle class="icon-color" cx="76.56" cy="162.31" r="57.27" />
                <path
                  class="icon-color"
                  d="M51.5,124.15c.22-14.31,2.4-36.03,12.45-60.04,12.96-30.95,33-47.62,47.35-57.83,2.73-1.94,1.14-6.25-2.19-5.94-14.96,1.37-37.13,5.13-58.5,22.41C17.57,49.47,7.29,83.66,4.35,95.24c-2.26,7.53-11.32,40.7,6.23,75.61,13.5,26.86,35.23,39.77,43.53,44.16-.87-30.29-1.74-60.57-2.61-90.86Z"
                />
              </g>
              <g>
                <circle class="icon-color" cx="233.79" cy="161.99" r="57.27" />
                <path
                  class="icon-color"
                  d="M208.72,123.83c.22-14.31,2.4-36.03,12.45-60.04,12.96-30.95,33-47.62,47.35-57.83,2.73-1.94,1.14-6.25-2.19-5.94-14.96,1.37-37.13,5.13-58.5,22.41-33.04,26.73-43.31,60.92-46.25,72.49-2.26,7.53-11.32,40.7,6.23,75.61,13.5,26.86,35.23,39.77,43.53,44.16-.87-30.29-1.74-60.57-2.61-90.86Z"
                />
              </g>
            </svg>
          </div>
          <p v-html="translateMoment(m).description"></p>
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import injectStrict from "@/utils/injectStrict"
import { GlobalDataKey, translationKey } from "@/symbols"
import { MomentInfo } from "@/models/MomentInfo"
import { defineComponent, PropType } from "@vue/runtime-core"
import Player from "@/components/shared/VideoPlayer.vue"
import { ContentType } from "@/models/ContentType"
import LogoOverlayData from "@/models/LogoOverlayData"
import { MAData } from "@/models/ma/MAData"
import SocialShare from "@/components/shared/SocialShare.vue"
import { computed, ref } from "vue"
import { RelatedResourceData } from "@/models/RelatedResourceData"
import eventBus from "@/event-bus"
import { Postplay } from "@/models/PostPlayOptions"
import handleAutoPlayEvent from "@/services/handle-autoplay"

export default defineComponent({
  components: { SocialShare, Player },
  props: {
    moments: {
      type: Object as PropType<Array<MomentInfo>>,
      required: true
    },
    useDemoOverlay: { type: Boolean, required: false, default: false },
    logoOverlay: { type: Object as PropType<LogoOverlayData>, required: false, default: null },
    usetheaterMode: { type: Boolean, required: false, default: false },
    ma: Object as PropType<MAData>,
    cta: { type: Object as PropType<RelatedResourceData>, required: false, default: null },
    isgated: Boolean,
    post_play: { type: String, required: false, default: undefined },
    share_buttons: { type: Object, required: true }
  },
  setup(props) {
    const globalData = injectStrict(GlobalDataKey).value
    const quotePattern = (description: string) => {
      const pattern = /^["“”].*["“”]$/
      return pattern.test(description)
    }
    var momentContainer = ref<HTMLElement>()
    var cssVariables = computed(() => {
      var n = props.moments.length
      if (n < 1) return {}
      var height = (momentContainer.value?.clientHeight ?? 0) / n - 40
      return {
        "--reseller-moment-height": `${height}px`
      }
    })
    handleAutoPlayEvent(globalData.instanceId)
    const { translateMoment } = injectStrict(translationKey)
    return { ContentType, quotePattern, momentContainer, cssVariables, translateMoment }
  }
})
</script>

<style lang="scss" scoped>
@media (max-width: 500px) {
  #parmonic-moments {
    .timeline-line {
      display: none;
    }
  }
  #parmonic-moments .moment-holder {
    .video-icon {
      display: none;
    }
    .momemt-box {
      display: block;
      padding: 10px;
      width: 100%;
      .video-holder {
        width: 100%;
      }
      .info {
        width: 100%;
        padding: 0px 5px;
      }
    }
  }
}
#parmonic-moments {
  width: 100%;
  margin-top: 50px;
  position: relative;
  .timeline-line {
    position: absolute;
    height: calc(100% - var(--reseller-moment-height));
    left: 20px;
    width: 5px;
    background-color: var(--theme-color-1);
    top: calc(var(--reseller-moment-height) / 2);
    border-radius: 20px;
  }
}
.moment-holder:nth-child(odd) {
  .momemt-box {
    flex-direction: row-reverse;
    .info {
      padding-left: 0px;
      padding-right: 20px;
    }
  }
}
.moment-holder:nth-child(even) {
  .momemt-box {
    flex-direction: row;
    .info {
      padding-left: 20px;
      padding-right: 0px;
    }
  }
}
.moment-holder {
  display: flex;
  margin-bottom: 40px;
  .video-icon {
    width: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    margin-right: 20px;
    .icon {
      width: 44px;
      height: 44px;
      border-radius: 50px;
      .icon-video-color-1 {
        fill: var(--theme-contrast);
      }
      .icon-video-color-2 {
        fill: var(--theme-color);
      }
      &::after {
        position: absolute;
        right: 0;
        transform: translateX(100%) translateY(-50%);
        top: 50%;
        content: "";
        margin-left: auto;
        margin-right: 0px;
        padding: 0;
        border-top: 20px solid transparent;
        border-bottom: 20px solid transparent;
        border-right: 20px solid var(--theme-color-1);
      }
    }
  }
  .momemt-box {
    width: calc(100% - 70px);
    display: flex;
    background-color: var(--theme-color-1);
    border-radius: 4px;
    padding: 40px;
    line-height: initial; /* A hack... TODO: try to not set a line height everywhere, so that it larger font sizes do not look squished */
    .quotes {
      width: 40px;
      height: 40px;
      display: flex;
      justify-content: center;
      align-items: center;
      opacity: 0.3;
      .icon-color {
        fill: var(--theme-color);
      }
    }
    .video-holder {
      width: 50%;
    }

    .info {
      width: 50%;

      h3 {
        padding: 0px;
        font-size: 1.625em;
        line-height: normal;
        font-family: var(--font-family);
        color: var(--font-color);
      }
      p {
        font-size: 1.25em;
        line-height: normal;
        font-family: var(--font-family);
        color: var(--font-color);
        margin: 0.5em 0px;
      }
    }
  }
}
</style>
