export default {
  "Full Video": "フルビデオ",
  "Related Resources": "関連リソース",
  Blog: "ブログ",
  Play: "プレイ",
  "No Captions": "キャプションなし",
  Captions: "キャプション",
  "Toggle fullscreen": "フルスクリーンの切り替え",
  "playback speed": "再生速度",
  "Play with captions in": "キャプション付きで再生する",
  Seek: "シーク",
  "Watch Trailer": "予告編を見る",
  "Watch full video": "フル動画を見る",
  "Watch Video": "動画を見る",
  Speakers: "スピーカー",
  Infographic: "インフォグラフィック",
  eBook: "電子書籍",
  Webinar: "ウェビナー",
  Video: "ビデオ",
  "White Paper": "ホワイトペーパー",
  Slides: "スライド",
  Article: "記事",
  "Icon not found exception": "アイコンが見つかりません例外",
  "Key Moments": "キーモーメント",
  Resources: "リソース"
}
