<template>
  <div class="aw-marketo-form">
    <div class="aw-form" ref="root">
      <form :id="`mktoForm_${formId}`"></form>
      <ScriptComponent :value="formScript" @onscriptloaded="scriptLoaded" />
    </div>
  </div>
</template>

<!-- <script src="//${munchkin_id}.mktoweb.com/js/forms2/js/forms2.min.js"><\/script>
<form id="mktoForm_${form_id}"></form>
<script>MktoForms2.loadForm("//${munchkin_id}.mktoweb.com", "${munchkin_id}", ${form_id})<\/script> -->

<script lang="ts">
import { computed, defineComponent, PropType, ref } from "vue"
import ScriptComponent from "./ScriptComponent.vue"
import { MAData } from "@/models/ma/MAData"
import { setMarketoKnownCache } from "@/services/MarketingAutomationApi"

declare var MktoForms2: any

export default defineComponent({
  components: { ScriptComponent },
  props: {
    config: Object as PropType<MAData>
  },
  setup: function (props, { emit }) {
    const munchkinId = computed(() => props.config?.ma_account_id)
    const formId = computed(() => Number(props.config?.form_id))

    let formScript = computed(() => {
      if (!munchkinId.value || !formId.value) return undefined
      return `<script src="//${munchkinId.value}.mktoweb.com/js/forms2/js/forms2.min.js"><\/script>`
    })

    let root = ref<HTMLElement>()

    let scriptLoaded = () => {
      MktoForms2.loadForm(`//${munchkinId.value}.mktoweb.com`, munchkinId.value, formId.value, function (form: any) {
        form.onSuccess(function (values: any, followUpUrl: string) {
          // set cache and hide modal
          setMarketoKnownCache(true)
          emit("formsubmitted", form)
          // Return false to prevent the submission handler from taking the lead to the follow up url
          return false
        })
      })
    }

    return {
      root,
      scriptLoaded,
      formScript,
      formId
    }
  }
})
</script>

<style lang="scss">
@use "../../assets/base.css";
@import "../../assets/ma/marketo.css";

.aw-marketo-form {
  .mktoForm .mktoFormCol {
    float: none;
    display: block;
  }

  .mktoForm .mktoFieldWrap {
    display: block;
    float: none;
    flex-wrap: wrap;
    margin-bottom: 15px;
    position: relative;
  }

  .mktoForm .mktoFieldWrap .mktoField {
    width: 100%;
  }

  .mktoForm .mktoLabel {
    padding-top: 0px;
  }

  .mktoForm {
    font-family: var(--font-family);
    font-size: 1em;
    line-height: normal;
    width: 100%;
  }

  .mktoForm button.mktoButton {
    @extend .aw-btn;
    @extend .aw-btn-theme;
  }

  .mktoForm .mktoFieldWrap .mktoField {
    @extend .aw-form-control;
  }

  .mktoForm .mktoRadioList,
  .mktoForm .mktoCheckboxList,
  .mktoForm .mktoRangeField {
    width: 100%;
  }

  .mktoForm .mktoRadioList .mktoField,
  .mktoForm .mktoCheckboxList .mktoField {
    width: auto;
    margin-top: 0;
  }

  .mktoForm .mktoRadioList label,
  .mktoForm .mktoCheckboxList label {
    cursor: pointer;
  }

  .mktoForm .mktoFieldWrap .mktoRangeValue {
    margin-top: -32px;
    right: 0;
  }

  .mktoForm .mktoFieldWrap select {
    appearance: auto;
  }

  .mktoForm .mktoFieldWrap fieldset legend {
    display: none;
  }

  .mktoForm .mktoFieldWrap .mktoError {
    position: relative;
    top: initial;
    left: initial;
    right: initial;
    bottom: initial;
    clear: both;
  }

  .mktoForm .mktoError .mktoErrorMsg {
    background-color: initial;
    background-image: initial;
    box-shadow: none;
    border: none;
    text-shadow: initial;
    color: #e51b00;
    padding: 0px;
  }

  .mktoForm .mktoError .mktoErrorMsg .mktoErrorDetail {
    display: inline;
  }

  .mktoForm .mktoFieldWrap .mktoError .mktoErrorArrowWrap {
    display: none;
  }

  .mktoForm .mktoFieldWrap .mktoField.mktoInvalid .mktoError {
    bottom: -51px;
  }

  .mktoForm .mktoFieldWrap .mktoField.mktoValid + .mktoError {
    display: none;
  }

  .mktoLabel {
    width: initial;
  }

  .mktoOffset {
    width: 0px;
  }

  .mktoForm input[type="text"],
  .mktoForm input[type="url"],
  .mktoForm input[type="email"],
  .mktoForm input[type="tel"],
  .mktoForm input[type="number"],
  .mktoForm input[type="date"],
  .mktoForm select.mktoField,
  .mktoForm textarea.mktoField {
    box-shadow: none;
  }

  .mktoForm input[type="radio"] + label:before,
  .mktoForm input[type="checkbox"] + label:before {
    display: none;
  }
}
</style>
