import { container } from "tsyringe"
import { Analytics, MockAnalytics } from "@/services/AnalyticsService"
import { OptionDataService, ApiDataService } from "@/services/WebinarDataServices"
import { MockGoogleAnalyticsService, GoogleAnalyticsService } from "@/services/GoogleAnalyticsService"
export function registerAnalyticsService(useMockAnalytics: boolean) {
  useMockAnalytics
    ? container.register("AnalyticsService", {
        useClass: MockAnalytics
      })
    : container.register("AnalyticsService", {
        useClass: Analytics
      })
}
export function registerWebinarDataService(useOptions: boolean) {
  useOptions
    ? container.register("WebinarDataService", {
        useClass: OptionDataService
      })
    : container.register("WebinarDataService", {
        useClass: ApiDataService
      })
}
export function registerGoogleAnalyticsService(useAnalytic: boolean) {
  useAnalytic
    ? container.register("GoogleAnalyticsService", {
        useClass: GoogleAnalyticsService
      })
    : container.register("GoogleAnalyticsService", {
        useClass: MockGoogleAnalyticsService
      })
}
