
import { defineComponent, PropType, Ref } from "vue"
import injectStrict from "@/utils/injectStrict"
import { translationKey } from "@/symbols"

export default defineComponent({
  props: {
    play: { type: Function, required: true },
    isPosterVisible: {
      type: Object as PropType<Ref<Boolean>>,
      required: true
    },
    totalDuration: { type: String, required: false },
    isTrailer: { type: Boolean, required: false, default: false },
    title: { type: String, required: true }
  },
  setup() {
    const { translateLabels } = injectStrict(translationKey)
    return { translateLabels }
  }
})
