
import { MAData } from "@/models/ma/MAData"
import { computed, defineComponent, PropType, ref } from "vue"
import Modal from "../shared/Modal.vue"
import MAHubspotForm from "./MAHubspotForm.vue"
import MAMarketoForm from "./MAMarketoForm.vue"
import MAPardotForm from "./MAPardotForm.vue"

export default defineComponent({
  components: { Modal, MAMarketoForm, MAHubspotForm, MAPardotForm },
  props: {
    ma: Object as PropType<MAData>,
    formScript: String
  },
  setup(props) {
    let modal = ref<InstanceType<typeof Modal>>()
    var callback: (() => void) | undefined = undefined
    let formTitle = !props.ma?.gate_after || props.ma.gate_after == 0 ? "Watch Video" : "Continue Watching Video"
    const showModal = (cb: () => void) => {
      modal.value?.showModal()
      callback = cb
    }

    const hideModal = () => modal.value?.hideModal()

    const formSubmitted = () => {
      hideModal()
      if (callback) callback()
    }

    return {
      showModal,
      hideModal,
      modal,
      formSubmitted,
      formTitle
    }
  }
})
