<template>
  <div class="holder">
    <div class="webinar-section">
      <h2 class="webinar-title" v-html="translateWebinar(widgetData).title"></h2>
      <p class="webinar-description" v-html="translateWebinar(widgetData).description"></p>
      <div class="nav" v-if="widgetData.show_info_tab_anchors">
        <a href="#parmonic-moments" v-if="showMoments"
          ><div class="icon">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512">
              <path
                class="icon-color"
                d="M608 0H160a32 32 0 0 0-32 32v96h160V64h192v320h128a32 32 0 0 0 32-32V32a32 32 0 0 0-32-32zM232 103a9 9 0 0 1-9 9h-30a9 9 0 0 1-9-9V73a9 9 0 0 1 9-9h30a9 9 0 0 1 9 9zm352 208a9 9 0 0 1-9 9h-30a9 9 0 0 1-9-9v-30a9 9 0 0 1 9-9h30a9 9 0 0 1 9 9zm0-104a9 9 0 0 1-9 9h-30a9 9 0 0 1-9-9v-30a9 9 0 0 1 9-9h30a9 9 0 0 1 9 9zm0-104a9 9 0 0 1-9 9h-30a9 9 0 0 1-9-9V73a9 9 0 0 1 9-9h30a9 9 0 0 1 9 9zm-168 57H32a32 32 0 0 0-32 32v288a32 32 0 0 0 32 32h384a32 32 0 0 0 32-32V192a32 32 0 0 0-32-32zM96 224a32 32 0 1 1-32 32 32 32 0 0 1 32-32zm288 224H64v-32l64-64 32 32 128-128 96 96z"
              />
            </svg>
          </div>
          {{ translateLabels("Key Moments") }}
        </a>
        <a href="#parmonic-resources" v-if="showResources"
          ><div class="icon">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 640 512">
              <path
                class="icon-color"
                d="M537.6 226.6c4.1-10.7 6.4-22.4 6.4-34.6 0-53-43-96-96-96-19.7 0-38.1 6-53.3 16.2C367 64.2 315.3 32 256 32c-88.4 0-160 71.6-160 160 0 2.7.1 5.4.2 8.1C40.2 219.8 0 273.2 0 336c0 79.5 64.5 144 144 144h368c70.7 0 128-57.3 128-128 0-61.9-44-113.6-102.4-125.4zm-132.9 88.7L299.3 420.7c-6.2 6.2-16.4 6.2-22.6 0L171.3 315.3c-10.1-10.1-2.9-27.3 11.3-27.3H248V176c0-8.8 7.2-16 16-16h48c8.8 0 16 7.2 16 16v112h65.4c14.2 0 21.4 17.2 11.3 27.3z"
              />
            </svg>
          </div>
          {{ translateLabels("Resources") }}
        </a>
        <a href="#parmonic-speakers" v-if="showSpeakers"
          ><div class="icon">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512">
              <path
                class="icon-color"
                d="M224 256c70.7 0 128-57.3 128-128S294.7 0 224 0S96 57.3 96 128s57.3 128 128 128zm-45.7 48C79.8 304 0 383.8 0 482.3C0 498.7 13.3 512 29.7 512H418.3c16.4 0 29.7-13.3 29.7-29.7C448 383.8 368.2 304 269.7 304H178.3z"
              />
            </svg>
          </div>
          {{ translateLabels("Speakers") }}
        </a>
      </div>
    </div>
    <div class="timeline-section" v-if="widgetData.trailer_data">
      <TrailerTimeline
        :moments="moments"
        :resources="widgetData.related_resources"
        :webinar_duration="widgetData.duration"
        :video_urls="widgetData.trailer_data.video_urls"
        :subtitles="widgetData.trailer_data.subtitles"
        :duration="widgetData.trailer_data.duration"
        :title="translateWebinar(widgetData).title"
        :use_demo_overlay="widgetData.use_demo_overlay"
        :overlay_logo="widgetData.overlay_logo"
        :usetheaterMode="usetheaterMode"
      />
    </div>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, PropType } from "@vue/runtime-core"
import injectStrict from "@/utils/injectStrict"
import { translationKey } from "@/symbols"
import { WebinarInfo } from "@/models/WebinarInfo"
import TrailerTimeline from "@/components/TrailerTimelineComponents/TrailerTimeline.vue"

export default defineComponent({
  components: { TrailerTimeline },
  props: {
    widgetData: {
      type: Object as PropType<WebinarInfo>,
      required: true
    },
    usetheaterMode: { type: Boolean, required: false, default: false }
  },
  setup(props) {
    const moments = computed(() => {
      if (!props.widgetData.moments) return []
      return Array.from(props.widgetData.moments, x => {
        return {
          id: x.id,
          duration: x.duration,
          start_time: x.start_time,
          end_time: x.end_time,
          title: x.title,
          poster_url: x.video_urls.poster_url,
          translations: x.translations
        }
      })
    })
    const showResources = computed(() => props.widgetData.related_resources && props.widgetData.related_resources.length > 0)
    const showMoments = computed(() => moments.value.length > 0)
    const showSpeakers = computed(() => props.widgetData.speakers && props.widgetData.speakers.length > 0)
    const { translateWebinar, translateLabels } = injectStrict(translationKey)
    return { moments, showResources, showMoments, showSpeakers, translateWebinar, translateLabels }
  }
})
</script>

<style lang="scss" scoped>
.holder {
  display: flex;
  p {
    margin: 0px;
  }
}
.webinar-section {
  flex-grow: 1;
  .webinar-title {
    font-family: var(--font-family);
    font-size: 2em;
    line-height: normal;
    color: var(--font-color);
    margin: 0px;
    padding: 0px;
  }
  .webinar-description {
    font-size: 1.25em;
    line-height: normal;
    font-family: var(--font-family);
    color: var(--font-color);
    margin: 0px;
    margin-top: 1em;
  }
}
.timeline-section {
  padding-left: 0.875em;
  display: block;
  width: 50%;
  flex-shrink: 0;
}
.nav {
  display: flex;
  flex-wrap: wrap;
  a {
    display: flex;
    align-items: center;
    padding-top: 1em;
    padding-right: 20px;
    padding-left: 0px;
    font-size: 1.25em;
    line-height: normal;
    font-family: var(--font-family);
    color: var(--font-color);
    text-decoration: none;
    font-weight: bold;

    &:hover {
      color: var(--theme-color);
    }

    .icon {
      width: 20px;
      height: 20px;
      padding: 6px;
      display: flex;
      border-radius: 5px;
      margin-right: 10px;
      justify-content: center;
      align-items: center;
      background-color: var(--theme-color);
      .icon-color {
        fill: var(--theme-contrast);
      }
    }
  }
}
@media (max-width: 765px) {
  .nav {
    flex-direction: column;
  }
}
@media (max-width: 500px) {
  .timeline-section {
    display: none;
  }
  .webinar-section {
    width: 100%;
  }
}
</style>
