import { AwEventStoreV2 } from "@/models/AwEventStoreV2"

import { AwEventType } from "@/models/AwEventType"
import { AnalyticsClient } from "@/services/AnalyticsService"
import { GoogleAnalyticsClient } from "@/services/GoogleAnalyticsService"
import { container } from "tsyringe"
import GaEvent from "@/models/googleAnalytics/GaEvent"
import { ContentType } from "@/models/ContentType"

export function setupSocialShare() {
  let analytics = container.resolve(AnalyticsClient)
  let googleAnalytics = container.resolve(GoogleAnalyticsClient)
  function registerSocialShare(momentId: number, momentTitle: string) {
    //Register analytics
    var event = {
      eventType: AwEventType.MomentShared,
      momentId: momentId
    }
    analytics.register(event, null)
    //Register Google analytics
    googleAnalytics.registerVideoShared({
      item_id: momentId,
      content_type: ContentType.Moment,
      video_title: momentTitle
    } as GaEvent)
  }
  return { registerSocialShare }
}
