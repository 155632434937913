
import { WebinarInfo } from "@/models/WebinarInfo"
import VideoPlayer from "@/components/shared/VideoPlayer.vue"
import IframeVideoPlayer from "@/components/shared/IframeVideoPlayer.vue"
import HorizontalOption from "@/components/layouts/HorizontalOption.vue"
import { defineComponent, PropType, ref } from "@vue/runtime-core"
import setupOptions from "@/composables/option-handler"
import { ContentType } from "@/models/ContentType"
import { formatDate } from "@/services/DurationFormatter"
import injectStrict from "@/utils/injectStrict"
import { EventBusKey } from "@/symbols"
import SocialShare from "@/components/shared/SocialShare.vue"
import getMomentId from "@/services/ParameterServices"
import { listenOnScrollForArrows } from "@/services/scrollArrowStatus"
import eventBus from "@/event-bus"
import { GlobalDataKey, translationKey } from "@/symbols"
import { Postplay } from "@/models/PostPlayOptions"
import { isMobile } from "@/services/deviceDetection"

export default defineComponent({
  components: { VideoPlayer, IframeVideoPlayer, HorizontalOption, SocialShare },
  props: {
    webinarInfo: { type: Object as PropType<WebinarInfo>, required: true },
    usetheaterMode: { type: Boolean, required: false, default: false }
  },
  setup(props) {
    let pos = { top: 0, left: 0, x: 0, y: 0 }
    const scrollContainer = ref<HTMLElement | undefined>()
    var optionsObj = setupOptions(props.webinarInfo)
    var was_dragger = false
    const globalData = injectStrict(GlobalDataKey).value
    let eventbus = injectStrict(EventBusKey)
    var height = ref<Number | null>(null)
    let isAnySocialMediaButtonEnable = false
    if (props.webinarInfo.share_buttons != null)
      isAnySocialMediaButtonEnable = Boolean(
        Object.values(props.webinarInfo.share_buttons).reduce((prev, now) => false || prev || now)
      )
    var autoPlay = ref(false)

    var arrowStatusService = listenOnScrollForArrows(scrollContainer)
    const { translateMoment } = injectStrict(translationKey)
    return {
      optionsObj,
      ContentType,
      scrollContainer,
      pos,
      was_dragger,
      formatDate,
      height,
      eventbus,
      arrowStatusService,
      autoPlay,
      globalData,
      isAnySocialMediaButtonEnable,
      translateMoment
    }
  },
  created() {
    this.eventbus.on("setHeight", (value: any) => {
      this.height = value
    })
  },
  mounted() {
    this.addScroller()
    this.focus()
    this.arrowStatusService.setup()
    if (!isMobile()) {
      eventBus.on(Postplay.AUTO_PLAY, (mid: any) => {
        const currentMomentIndex = window.momentList.findIndex(
          m => m.mid == mid.mid && m.instanceId == this.globalData.instanceId
        )
        if (currentMomentIndex + 1 < window.momentList.length) {
          this.autoPlay = true
          const nextMoment = window.momentList[currentMomentIndex + 1]
          this.optionsObj.updateCurrentSelection(`${nextMoment.mid}`)
        }
      })
    }
  },
  unmounted() {
    this.arrowStatusService.unset()
  },
  methods: {
    changeSelection(id: string) {
      this.autoPlay = false
      if (!this.was_dragger) this.optionsObj.updateCurrentSelection(id)
      else this.was_dragger = false
    },
    addScroller() {
      var self = this
      self.scrollContainer?.addEventListener("mousedown", (e: any) => mouseDownHandler(e))
      const mouseDownHandler = function (e: any) {
        if (!self.scrollContainer) return
        self.pos = {
          // The current scroll
          left: self.scrollContainer.scrollLeft,
          top: self.scrollContainer.scrollTop,
          // Get the current mouse position
          x: e.clientX,
          y: e.clientY
        }
        self.scrollContainer.style.cursor = "grabbing"
        self.scrollContainer.style.userSelect = "none"
        document.addEventListener("mousemove", self.mouseMoveHandler)
        document.addEventListener("mouseup", self.mouseUpHandler)
      }
    },
    mouseMoveHandler(e: any) {
      if (!this.scrollContainer) return
      // How far the mouse has been moved
      const dx = e.clientX - this.pos.x
      const dy = e.clientY - this.pos.y
      if (dx > 5 || dx < -5) {
        this.was_dragger = true
      }
      // Scroll the element
      //this.scrollContainer.scrollTop = pos.top - dy;
      this.scrollContainer.scrollLeft = this.pos.left - dx
    },
    mouseUpHandler() {
      document.removeEventListener("mousemove", this.mouseMoveHandler)
      document.removeEventListener("mouseup", this.mouseUpHandler)
      if (!this.scrollContainer) return
      this.scrollContainer.style.cursor = "default"
      this.scrollContainer.style.removeProperty("user-select")
    },
    scrollLeft() {
      if (!this.scrollContainer) return
      this.scrollContainer.scrollLeft = this.scrollContainer.scrollLeft - 190
      //this.scrollContainer.scrollLeft = this.pos.left - dx
    },
    scrollRight() {
      if (!this.scrollContainer) return
      this.scrollContainer.scrollLeft = this.scrollContainer.scrollLeft + 190
    },
    focus(i: string | null = null) {
      const url = window.location.search
      if (url.length == 0) return
      var id = getMomentId(url)
      if (parseInt(id) > 0) {
        const momentdiv = document.getElementById("slider-player") as HTMLElement
        if (momentdiv) {
          this.changeSelection(id)
          momentdiv.scrollIntoView(true)
        }
      }
    }
  }
})
