<template>
  <div>
    <div class="rnd-crns amplily-player">
      <VideoPlayer
        :poster="video_urls.poster_url"
        :video_urls="video_urls"
        :mid="null"
        :subtitleUrls="subtitle_urls"
        :subtitles="subtitles"
        :continuefromTime="end_time"
        :duration="duration"
        :videoType="ContentType.Trailer"
        :title="title"
        :isTrailer="true"
        v-if="!video_urls.iframe_url || video_urls.iframe_url == ''"
        :useDemoOverlay="use_demo_overlay"
        :logoOverlay="overlay_logo"
        :isgated="isgated"
        :ma="ma"
        :cta="cta"
        :usetheaterMode="usetheaterMode"
      />
      <IframeVideoPlayer v-else :iframe_url="video_urls.iframe_url" :poster="video_urls.poster_url" :duration="duration" />
    </div>
    <MomentBox
      :moments="moments"
      :title="cta_text"
      :resources="resources"
      :webinar_duration="webinar_duration"
      v-if="showTimeline"
    />
  </div>
</template>

<script lang="ts">
import { SubtitleUrls } from "@/models/captions/SubtitleUrls"
import VideoPlayer from "@/components/shared/VideoPlayer.vue"
import IframeVideoPlayer from "@/components/shared/IframeVideoPlayer.vue"
import MomentBox from "@/components/TrailerTimelineComponents/MomentBox.vue"
import { ContentType } from "@/models/ContentType"
import LogoOverlayData from "@/models/LogoOverlayData"
import { RelatedResourceData } from "@/models/RelatedResourceData"
import { TrailerMomentInfo } from "@/models/TrailerMomentInfo"
import { VideoInfo } from "@/models/VideoInfo"
import { defineComponent, PropType, computed } from "@vue/runtime-core"
import { MAData } from "@/models/ma/MAData"

export default defineComponent({
  components: { VideoPlayer, IframeVideoPlayer, MomentBox },
  props: {
    moments: {
      type: Object as PropType<Array<TrailerMomentInfo>>,
      required: true
    },
    resources: {
      type: Object as PropType<Array<RelatedResourceData> | null>,
      required: false,
      default: null
    },
    webinar_duration: { type: String, required: true },
    title: { type: String, required: false },
    cta_text: { type: String, required: false },
    video_urls: { type: Object as PropType<VideoInfo>, required: true },
    subtitle_urls: Object as PropType<SubtitleUrls>,
    subtitles: Array as PropType<Array<SubtitleUrls>>,
    end_time: { type: Number, default: 0, required: false },
    duration: String,
    use_demo_overlay: { type: Boolean, required: false, default: false },
    overlay_logo: { type: Object as PropType<LogoOverlayData>, required: false, default: null },
    isgated: Boolean,
    ma: { type: Object as PropType<MAData>, required: false, default: null },
    cta: { type: Object as PropType<RelatedResourceData>, required: false, default: null },
    usetheaterMode: { type: Boolean, required: false, default: false },
    is_single: { type: Boolean, required: false, default: false }
  },
  setup(props) {
    let showTimeline = computed(() => props.moments && (!window.parmonic_hide_trailer_timeline || props.is_single))
    return { ContentType, showTimeline }
  }
})
</script>

<style scoped>
@import "../../assets/trailer-timeline.scss";
</style>
