<template>
  <div ref="rootElm" :style="themeCss">
    <div id="aw--c">
      <div id="aw-sl" style="font-size: 1em !important">
        <SeoJsonLdComponentVue :webinarInfo="webinarInfo" v-if="webinarInfo != undefined" />
        <TranslationDropdown :languages="webinarInfo.translation_language_codes" v-if="webinarInfo != undefined" />
        <TheaterPlayer
          :videos="lightboxVideos"
          :logoOverlay="webinarInfo.overlay_logo"
          :useDemoOverlay="webinarInfo.use_demo_overlay"
          :themeCss="themeCss"
          :ma="webinarInfo.ma"
          :cta="webinarInfo.cta"
          :isGated="webinarInfo.is_gated"
          :post_play="webinarInfo.post_play"
          v-if="webinarInfo && lightboxVideos && lightboxVideos.length > 0 && usetheaterMode == true"
        />
        <div v-if="webinarInfo != undefined" :key="webinarInfo.id" class="wc">
          <MAGate :ma="webinarInfo.ma" :themeCss="themeCss" />
          <TitleTrailerCompoent
            :widgetData="webinarInfo"
            :usetheaterMode="usetheaterMode"
            v-if="webinarInfo.is_info_tab_visible == true"
          />
          <FullVideoCompoment
            v-if="webinarInfo.video_urls && webinarInfo.is_full_video_visible && webinarInfo.is_full_video_on_top"
            :fullvideo="webinarInfo.video_urls"
            :show-head="webinarInfo.moments.length > 0"
            :duration="webinarInfo.duration"
            :webid="webinarInfo.id"
            :title="translateWebinar(webinarInfo).title"
            :subtitleUrls="webinarInfo.subtitle_urls"
            :subtitles="webinarInfo.subtitles"
            :useDemoOverlay="webinarInfo.use_demo_overlay"
            :logoOverlay="webinarInfo.overlay_logo"
            :isgated="webinarInfo.is_gated"
            :ma="webinarInfo.ma"
            :usetheaterMode="usetheaterMode"
            :cta="webinarInfo.cta"
          />
          <MomentSection
            :moments="webinarInfo.moments"
            :useDemoOverlay="webinarInfo.use_demo_overlay"
            :logoOverlay="webinarInfo.overlay_logo"
            :usetheaterMode="usetheaterMode"
            :ma="webinarInfo.ma"
            :cta="webinarInfo.cta"
            :post_play="webinarInfo.post_play"
            :isgated="webinarInfo.is_gated"
            :share_buttons="webinarInfo.share_buttons"
          />
          <FullVideoCompoment
            v-if="webinarInfo.video_urls && webinarInfo.is_full_video_visible && !webinarInfo.is_full_video_on_top"
            :fullvideo="webinarInfo.video_urls"
            :show-head="webinarInfo.moments.length > 0"
            :duration="webinarInfo.duration"
            :webid="webinarInfo.id"
            :title="translateWebinar(webinarInfo).title"
            :subtitleUrls="webinarInfo.subtitle_urls"
            :subtitles="webinarInfo.subtitles"
            :useDemoOverlay="webinarInfo.use_demo_overlay"
            :logoOverlay="webinarInfo.overlay_logo"
            :isgated="webinarInfo.is_gated"
            :ma="webinarInfo.ma"
            :cta="webinarInfo.cta"
            :usetheaterMode="usetheaterMode"
          />
          <div id="parmonic-resources">
            <RelatedResourceComponent :resources="webinarInfo.related_resources" />
          </div>
          <div id="parmonic-speakers">
            <SpeakerSection :speakers="webinarInfo.speakers" />
          </div>
        </div>
        <StorySkeleton v-else :loadingmsg="loadingmsg" />
      </div>
    </div>
  </div>
</template>
<script lang="ts">
import "../assets/_base.scss"
import { WebinarInfo } from "@/models/WebinarInfo"
import { AwEventType } from "../models/AwEventType"
import setThemeData from "../services/setThemeData"
import { GoogleAnalyticsClient } from "@/services/GoogleAnalyticsService"
import { AnalyticsClient } from "@/services/AnalyticsService"
import { WebinarDataService } from "@/services/WebinarDataServices"
import { container } from "tsyringe"
import { computed, defineComponent, ref } from "@vue/runtime-core"
import injectStrict from "@/utils/injectStrict"
import { GlobalDataKey, LoggerKey, translationKey } from "@/symbols"
import getVideoType from "@/utils/getVideoType"
import { ContentType } from "@/models/ContentType"
import { AwEventStoreV2 } from "@/models/AwEventStoreV2"
import TitleTrailerCompoent from "@/components/shared/InfoTab.vue"
import MomentSection from "@/components/ResellerLayout/MomentSection.vue"
import RelatedResourceComponent from "@/components/shared/RelatedResourceComponent.vue"
import SpeakerSection from "@/components/ResellerLayout/SpeakerSection.vue"
import getCssVariables from "@/utils/getCssVariables"
import { isIOS } from "@/services/deviceDetection"
import TheaterPlayer from "@/components/shared/TheaterPlayer.vue"
import SeoJsonLdComponentVue from "@/components/shared/SeoJsonLdComponent.vue"
import momentAutoOpenTheatreMode from "@/composables/shared/moment-autoopen-theatre"
import { EventBusKey } from "@/symbols"
import FullVideoCompoment from "@/components/shared/FullVideoComponent.vue"
import MAGate from "@/components/MA/MAGate.vue"
import getTheaterVideos from "@/utils/getTheaterVideos"
import { getFullVideo } from "@/services/getFullVideoData"
import StorySkeleton from "@/components/skeletons/story.vue"
import TranslationDropdown from "@/components/shared/Translation.vue"
export default defineComponent({
  components: {
    MAGate,
    TitleTrailerCompoent,
    MomentSection,
    RelatedResourceComponent,
    SpeakerSection,
    TheaterPlayer,
    SeoJsonLdComponentVue,
    FullVideoCompoment,
    StorySkeleton,
    TranslationDropdown
  },
  props: {
    fetchId: { type: String, required: true }
  },
  setup(props) {
    const globalData = injectStrict(GlobalDataKey).value
    const logger = injectStrict(LoggerKey)
    const videoType: ContentType | undefined = getVideoType(props.fetchId)
    const webinarId = ref(-1)
    const webinarInfo = ref<WebinarInfo | null>(null)
    const loadingmsg = ref("Loading video...")
    const widgetHeight = computed<number>(() => globalData.height)
    let redirect_url = computed(() => globalData.redirect_data.url)
    let redirectText = computed(() => globalData.redirect_data.text)
    let rootElm = ref<HTMLElement>()
    let rootcolor = ref<string | undefined>()
    const themeCss = computed(() => getCssVariables(globalData.theme_data))
    const dataService = container.resolve(WebinarDataService)
    const analytics = container.resolve(AnalyticsClient)
    const gaAnalytics = container.resolve(GoogleAnalyticsClient)
    let lightboxVideos = computed(() => getTheaterVideos(webinarInfo))
    let usetheaterMode = computed(
      () =>
        webinarInfo.value &&
        webinarInfo.value.use_theater_mode == true &&
        !!window.parmonic_disable_theater_mode == false &&
        !isIOS(navigator.userAgent, navigator.maxTouchPoints)
    )
    let eventbus = injectStrict(EventBusKey)
    const { translateWebinar } = injectStrict(translationKey)
    return {
      eventbus,
      usetheaterMode,
      lightboxVideos,
      dataService,
      widgetHeight,
      themeCss,
      globalData,
      gaAnalytics,
      webinarId,
      webinarInfo,
      loadingmsg,
      analytics,
      redirect_url,
      logger,
      redirectText,
      videoType,
      rootElm,
      rootcolor,
      translateWebinar
    }
  },
  created() {
    this.dataService.getData(this.fetchId).then(returnValue => {
      this.loadingmsg = "The video could not be loaded."
      if (returnValue == undefined) {
        this.logger!.error(`Parmonic Widget error: Video not found`)
        return
      }
      this.webinarInfo = returnValue as WebinarInfo
      this.webinarId = this.webinarInfo.id
      setThemeData(this.webinarInfo.theme_data, this.globalData)
      this.analytics.setUp(this.webinarId, null, null, null, this.globalData.analyticsEventsCaptureEndPoint, this.logger)
      this.gaAnalytics.setUp(this.webinarId, this.logger)
      var eventV2: AwEventStoreV2 = { eventType: AwEventType.WidgetViewed, type: ContentType.FullWidget }
      this.analytics.register(eventV2, null)
      this.globalData.fullVideoInfo = getFullVideo(this.webinarInfo)
      setTimeout(() => {
        momentAutoOpenTheatreMode(this.globalData.instanceId, this.eventbus)
      }, 500)
    })
  }
})
</script>
<style scoped>
.amplily-player {
  width: 100% !important;
}
</style>
