export default {
  "Full Video": "完整视频",
  "Related Resources": "相关资源",
  Blog: "博客",
  Play: "播放",
  "No Captions": "没有字幕",
  Captions: "字幕",
  "Toggle fullscreen": "切换全屏模式",
  "playback speed": "播放速度",
  "Play with captions in": "在中播放带字幕",
  Seek: "搜索",
  "Watch Trailer": "观看预告片",
  "Watch full video": "观看完整视频",
  "Watch Video": "观看视频",
  Speakers: "扬声器",
  Infographic: "信息图表",
  eBook: "电子书",
  Webinar: "网络研讨会",
  Video: "视频",
  "White Paper": "白皮书",
  Slides: "幻灯片",
  Article: "文章",
  "Icon not found exception": "找不到图标异常",
  "Key Moments": "关键时刻",
  Resources: "资源"
}
