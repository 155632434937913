
import { VideoInfo } from "@/models/VideoInfo"
import VideoPlayer from "@/components/shared/VideoPlayer.vue"
import IframeVideoPlayer from "@/components/shared/IframeVideoPlayer.vue"
import { SubtitleUrls } from "@/models/captions/SubtitleUrls"
import { translationKey } from "@/symbols"
import injectStrict from "@/utils/injectStrict"
import { defineComponent, PropType } from "vue"
import { ContentType } from "@/models/ContentType"
import LogoOverlayData from "@/models/LogoOverlayData"
import { MAData } from "@/models/ma/MAData"
import { RelatedResourceData } from "@/models/RelatedResourceData"

export default defineComponent({
  components: { VideoPlayer, IframeVideoPlayer },
  props: {
    fullvideo: Object as PropType<VideoInfo>,
    showHead: Boolean,
    duration: String,
    subtitleUrls: Object as PropType<SubtitleUrls>,
    subtitles: Array as PropType<Array<SubtitleUrls>>,
    useDemoOverlay: { type: Boolean, required: false, default: false },
    title: { type: String, required: true },
    logoOverlay: { type: Object as PropType<LogoOverlayData>, required: false, default: null },
    isgated: Boolean,
    ma: Object as PropType<MAData>,
    cta: { type: Object as PropType<RelatedResourceData>, required: false },
    usetheaterMode: { type: Boolean, required: false, default: false }
  },
  setup() {
    const { translateLabels } = injectStrict(translationKey)
    return { ContentType, translateLabels }
  }
})
