import { VideoInfo } from "./VideoInfo"
import { SubtitleUrls } from "./captions/SubtitleUrls"
export default interface IFullVideoData {
  video_urls?: VideoInfo
  subtitles: SubtitleUrls[]
  isReady: boolean
}
export class FullVideoData implements IFullVideoData {
  video_urls?: VideoInfo
  subtitles: SubtitleUrls[]
  isReady: boolean
  constructor(video_urls?: VideoInfo, subtitles?: SubtitleUrls[]) {
    this.video_urls = video_urls
    this.subtitles = subtitles ?? []
    this.isReady = !(video_urls == undefined)
  }
}
