export default {
  "Full Video": "Volledige video",
  "Related Resources": "Gerelateerde Bronnen",
  Blog: "Blog",
  Play: "Spelen",
  "No Captions": "Geen Ondertitels",
  Captions: "Ondertitels",
  "Toggle fullscreen": "Volledig scherm aan/uit",
  "playback speed": "Afspelen snelheid",
  "Play with captions in": "Afspelen met ondertitels in",
  Seek: "Zoeken",
  "Watch Trailer": "Bekijk de trailer",
  "Watch full video": "Bekijk volledige video",
  "Watch Video": "Bekijk video",
  Speakers: "Luidsprekers",
  Infographic: "Infographic",
  eBook: "eBook",
  Webinar: "Webinar",
  Video: "Video",
  "White Paper": "White Paper",
  Slides: "Dia's",
  Article: "Artikel",
  "Icon not found exception": "Icoon niet gevonden uitzondering",
  "Key Moments": "Belangrijke Momenten",
  Resources: "Bronnen"
}
