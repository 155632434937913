export default {
  "Full Video": "전체 비디오",
  "Related Resources": "관련 자료",
  Blog: "블로그",
  Play: "놀이",
  "No Captions": "자막 없음",
  Captions: "자막",
  "Toggle fullscreen": "전체화면 전환",
  "playback speed": "재생 속도",
  "Play with captions in": "자막으로 재생하기",
  Seek: "이동",
  "Watch Trailer": "예고편 보기",
  "Watch full video": "전체 동영상 보기",
  "Watch Video": "비디오 시청",
  Speakers: "스피커",
  Infographic: "인포그래픽",
  eBook: "전자책",
  Webinar: "웨비나",
  Video: "비디오",
  "White Paper": "백서",
  Slides: "슬라이드",
  Article: "기사",
  "Icon not found exception": "아이콘을 찾을 수 없는 예외",
  "Key Moments": "중요한 순간",
  Resources: "자원"
}
