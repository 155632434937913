import { toggleFullScreen, isFullScreenAvailable, exitFullScreen } from "@/services/fullScreenService"
import { Ref } from "vue"
export default function setup(playerContainer: Ref<HTMLElement | undefined>) {
  function togglefullscreen() {
    if (playerContainer.value && isFullScreenAvailable()) toggleFullScreen(playerContainer.value)
  }
  function exitFullscreen() {
    if (playerContainer.value && isFullScreenAvailable()) exitFullScreen()
  }

  return { togglefullscreen, exitFullscreen }
}
