export default {
  "Full Video": "Полное видео",
  "Related Resources": "Связанные Ресурсы",
  Blog: "Блог",
  Play: "Играть",
  "No Captions": "Без Подписей",
  Captions: "Субтитры",
  "Toggle fullscreen": "Переключить полноэкранный режим",
  "playback speed": "Скорость воспроизведения",
  "Play with captions in": "Воспроизвести с субтитрами на",
  Seek: "Поиск",
  "Watch Trailer": "Смотреть трейлер",
  "Watch full video": "Смотреть полное видео",
  "Watch Video": "Смотреть видео",
  Speakers: "Динамики",
  Infographic: "Инфографика",
  eBook: "электронная книга",
  Webinar: "Вебинар",
  Video: "Видео",
  "White Paper": "Белая бумага",
  Slides: "Слайды",
  Article: "Статья",
  "Icon not found exception": "Исключение: иконка не найдена",
  "Key Moments": "Ключевые Моменты",
  Resources: "Ресурсы"
}
