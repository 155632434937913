<template>
  <div class="aw-modal-container" v-if="shown" @click.stop="hideModal">
    <div class="aw-modal">
      <div class="aw-modal-body" :style="{ width: getWidth() }" @click.stop>
        <slot></slot>
      </div>
    </div>
  </div>
</template>
<style lang="scss" scoped>
@import "../../assets/modal/modal.css";
</style>
<script lang="ts">
import { EventBusKey } from "@/symbols"
import injectStrict from "@/utils/injectStrict"
import { defineComponent, nextTick, PropType, ref } from "vue"
type modalSize = "small" | "medium" | "large"

export default defineComponent({
  props: {
    size: { type: String as PropType<modalSize> }
  },
  setup(props) {
    let shown = ref(false)
    let eventbus = injectStrict(EventBusKey)

    const showModal = () => {
      shown.value = true
      nextTick(() => eventbus.emit("onshown", this))
    }
    const hideModal = () => {
      shown.value = false
      nextTick(() => eventbus.emit("onhidden", this))
    }

    const getWidth = () => {
      switch (props.size) {
        case "small":
          return "400px !important"
        case "medium":
          return "800px !important"
        case "large":
          return "1024px !important"
        default:
          return "1024px !important"
      }
    }

    return {
      showModal,
      hideModal,
      shown,
      eventbus,
      getWidth
    }
  }
})
</script>
