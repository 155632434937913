
import { PropType, Ref, computed, defineComponent } from "vue"
import { getColorSchemeFromString } from "@/utils/getColorSchemeFromString"
import { StringToSeconds } from "@/services/DurationFormatter"

export default defineComponent({
  props: {
    logoUrl: { type: String, required: true },
    cta: { type: Boolean, required: false, default: false },
    isPosterVisible: { type: Object as PropType<Ref<Boolean>>, required: true },
    colorScheme: { type: String, required: false, default: "LIGHT" },
    dur: { type: Object as PropType<Ref<number>>, required: true },
    continuefromTime: { type: Number, default: 0, required: false },
    hasEnded: { type: Object as PropType<Ref<Boolean>>, required: true },
    duration: { type: String, required: true },
    currentTime: { type: Object as PropType<Ref<number>>, required: true }
  },
  setup(props) {
    const comClass = ` ${getColorSchemeFromString(props.colorScheme)}`
    let CTAVisible = computed(() => {
      return props.cta && !props.isPosterVisible.value
    })
    let showEndedPage = computed(() => {
      return !props.continuefromTime && props.hasEnded.value && props.dur.value == StringToSeconds(props.duration)
    })
    return { comClass, CTAVisible, showEndedPage }
  }
})
