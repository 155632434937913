
import { defineComponent } from "vue"
import { getLanguageString } from "@/utils/getLanguageString"
import injectStrict from "@/utils/injectStrict"
import { translationKey } from "@/symbols"

export default defineComponent({
  props: {
    languages: {
      type: Array as () => string[],
      required: false
    }
  },
  setup(props) {
    const { language, setLang } = injectStrict(translationKey)
    const handleLanguageChange = (event: Event) => {
      const selectedLanguage = (event.target as HTMLSelectElement).value
      setLang(selectedLanguage)
    }
    return {
      languages: props.languages || [],
      handleLanguageChange,
      getLanguageString,
      language
    }
  }
})
