import { Emitter } from "mitt"
import { LightboxEventBusModal } from "@/models/lightbox/LightboxModels"
import getMomentId from "../../services/ParameterServices"
import { ContentType } from "@/models/ContentType"

export default function momentAutoOpenTheatreMode(instanceId: string | undefined, eventbus: Emitter<any>) {
  const url = window.location.search
  if (url.length == 0) return
  eventbus.emit(
    "setup-lightbox",
    new LightboxEventBusModal(instanceId, parseInt(getMomentId(url)), null, ContentType.Moment, false)
  )
}
