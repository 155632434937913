
import "@/assets/_base.scss"

import { WebinarDataService } from "@/services/WebinarDataServices"
import { WebinarInfo } from "@/models/WebinarInfo"
import { AwEventType } from "@/models/AwEventType"
import setThemeData from "@/services/setThemeData"
import { AnalyticsClient } from "@/services/AnalyticsService"
import { container } from "tsyringe"
import { getFullVideo } from "@/services/getFullVideoData"
import { computed, defineComponent, getCurrentInstance, ref } from "@vue/runtime-core"
import injectStrict from "@/utils/injectStrict"
import { GlobalDataKey, LoggerKey, translationKey } from "@/symbols"
import { focusOnMoment } from "@/services/momentFocusService"
import FullVideoCompoment from "@/components/shared/FullVideoComponent.vue"
import MomentSection from "@/components/TimelineComponents/MomentsSection.vue"
import RelatedResourceComponent from "@/components/shared/RelatedResourceComponent.vue"
import getCssVariables from "@/utils/getCssVariables"
import { ContentType } from "@/models/ContentType"
import { AwEventStoreV2 } from "@/models/AwEventStoreV2"
import MAGate from "@/components/MA/MAGate.vue"
import { GoogleAnalyticsClient } from "@/services/GoogleAnalyticsService"
import { isIOS } from "@/services/deviceDetection"
import TheaterPlayer from "@/components/shared/TheaterPlayer.vue"
import SeoJsonLdComponentVue from "@/components/shared/SeoJsonLdComponent.vue"
import momentAutoOpenTheatreMode from "@/composables/shared/moment-autoopen-theatre"
import { EventBusKey } from "@/symbols"
import TitleTrailerCompoent from "@/components/shared/InfoTab.vue"
import getTheaterVideos from "@/utils/getTheaterVideos"
import SpeakerSection from "@/components/ResellerLayout/SpeakerSection.vue"
import timelineWidgetSkeleton from "@/components/skeletons/timeline.vue"
import { Postplay } from "@/models/PostPlayOptions"
import handleAutoPlayEvent from "@/services/handle-autoplay"
import TranslationDropdown from "@/components/shared/Translation.vue"
const AmplilyWidget = defineComponent({
  components: {
    FullVideoCompoment,
    MomentSection,
    RelatedResourceComponent,
    MAGate,
    TheaterPlayer,
    SeoJsonLdComponentVue,
    TitleTrailerCompoent,
    SpeakerSection,
    timelineWidgetSkeleton,
    TranslationDropdown
  },
  props: {
    fetchId: { type: String, required: true }
  },
  setup() {
    const globalData = injectStrict(GlobalDataKey).value
    const themeCss = computed(() => getCssVariables(globalData.theme_data))
    let webinarId = ref(-1)
    let webinarInfo = ref<WebinarInfo | null>(null)
    let loadingmsg = ref("")
    const dataService = container.resolve(WebinarDataService)
    const analytics = container.resolve(AnalyticsClient)
    const gaAnalytics = container.resolve(GoogleAnalyticsClient)
    let logger = injectStrict(LoggerKey)
    var app = getCurrentInstance()
    let lightboxVideos = computed(() => getTheaterVideos(webinarInfo))
    let usetheaterMode = computed(
      () =>
        webinarInfo.value &&
        webinarInfo.value.use_theater_mode == true &&
        !!window.parmonic_disable_theater_mode == false &&
        !isIOS(navigator.userAgent, navigator.maxTouchPoints)
    )
    let eventbus = injectStrict(EventBusKey)
    let hasMoments = computed(() => !!webinarInfo.value?.moments && webinarInfo.value.moments.length > 0)
    handleAutoPlayEvent(globalData.instanceId)
    const { translateWebinar } = injectStrict(translationKey)
    return {
      hasMoments,
      eventbus,
      usetheaterMode,
      lightboxVideos,
      logger,
      dataService,
      webinarInfo,
      themeCss,
      globalData,
      webinarId,
      loadingmsg,
      analytics,
      translateWebinar,
      gaAnalytics
    }
  },
  created() {
    this.loadData()
  },
  methods: {
    loadData() {
      this.dataService.getData(this.fetchId).then(async returnValue => {
        this.loadingmsg = "The video could not be loaded."
        if (returnValue == undefined) {
          this.logger!.error(`Parmonic Widget error: Webinar not found`)
          return
        }
        this.webinarInfo = returnValue as WebinarInfo
        if (this.webinarInfo.hide_branding == null) this.webinarInfo.hide_branding = true
        this.webinarId = this.webinarInfo.id
        setThemeData(this.webinarInfo.theme_data, this.globalData)
        this.analytics.setUp(this.webinarId, null, null, null, this.globalData.analyticsEventsCaptureEndPoint, this.logger)
        this.gaAnalytics.setUp(this.webinarId, this.logger)
        var eventV2: AwEventStoreV2 = { eventType: AwEventType.WidgetViewed, type: ContentType.FullWidget }
        this.analytics.register(eventV2, null)

        this.globalData.fullVideoInfo = getFullVideo(this.webinarInfo)
        setTimeout(() => {
          focusOnMoment()
          momentAutoOpenTheatreMode(this.globalData.instanceId, this.eventbus)
        }, 500)
      })
    }
  }
})
export default AmplilyWidget
