export function formatDate(duration: string | undefined) {
  if (!duration) return `00:00`
  const hrs = duration.split(":")[0]
  const mins = duration.split(":")[1]
  const seconds = duration.split(":")[2]
  return `${hrs != "00" ? parseInt(hrs).toString() + ":" + mins + ":" : parseInt(mins).toString() + ":"}${
    seconds.split(".")[0]
  }`
}

export function formatDatefromSeconds(duration?: number) {
  if (!duration) return `0:00`
  const hrs = Math.floor(duration / 3600)
  const mins = Math.floor((duration - hrs * 3600) / 60)
  const seconds = Math.floor(duration - hrs * 3600 - mins * 60)
  return `${hrs != 0 ? hrs + ":" + getdoubledigits(mins) + ":" : mins + ":"}${getdoubledigits(seconds)}`
}
function getdoubledigits(num: number): string {
  return num.toLocaleString("en-US", {
    minimumIntegerDigits: 2,
    useGrouping: false
  })
}
export function StringToSeconds(duration: string | undefined, preserveMS: boolean = false): number {
  if (!duration) return 0
  duration = duration.replace(",", ".")
  const hrs = parseInt(duration.split(":")[0] ?? "0") * 60 * 60
  const mins = parseInt(duration.split(":")[1] ?? "0") * 60
  const seconds = preserveMS ? parseFloat(duration.split(":")[2] ?? "0") : parseInt(duration.split(":")[2] ?? "0")
  return hrs + mins + seconds
}

export function formatDurationForSeo(duration: string | undefined) {
  if (!duration) return `PT00M00S`
  const hrs = duration.split(":")[0]
  const mins = duration.split(":")[1]
  const seconds = duration.split(":")[2]
  return `PT${hrs != "00" ? parseInt(hrs).toString() + "H" : ""}${getdoubledigits(
    parseInt(mins)
  ).toString()}M${getdoubledigits(parseInt(seconds.split(".")[0]))}S`
}
