import { ref, Ref, computed } from "vue"

export default function setUp(currentTime: Ref<number>, totalDuration: Ref<number>) {
  let totalBufferedTime = ref(0)
  const seekWidth = computed(() => (currentTime.value * 100) / totalDuration.value)
  const bufferWidth = computed(() => {
    var val = totalBufferedTime.value - currentTime.value
    if (totalBufferedTime.value > totalDuration.value) val = totalDuration.value - currentTime.value
    return (val * 100) / totalDuration.value + seekWidth.value
  })

  const seekbarCss = computed(() => {
    return {
      background: `linear-gradient(to right, #ffffff ${seekWidth.value}%, #6e6e6e ${seekWidth.value}% ${bufferWidth.value}%, #202020 ${bufferWidth.value}% 100%) !important`
    }
  })

  return { totalBufferedTime, seekbarCss, seekWidth }
}
