import { WebinarInfo } from "@/models/WebinarInfo"
import { MomentInfo } from "@/models/MomentInfo"
import { computed, Ref, ref } from "vue"
export default function setUp(webInfo: WebinarInfo) {
  var options: MomentInfo[] = webInfo.moments
  if (webInfo.is_full_video_visible && !checkExists("-1", options))
    if (webInfo.is_full_video_on_top == true) {
      options.unshift({
        id: "-1",
        duration: webInfo.duration,
        start_time: 0,
        end_time: 0,
        title: webInfo.title,
        description: webInfo.description,
        video_urls: webInfo.video_urls,
        subtitle_urls: webInfo.subtitle_urls,
        subtitles: webInfo.subtitles,
        translations: webInfo.translations
      } as MomentInfo)
    } else {
      options.push({
        id: "-1",
        duration: webInfo.duration,
        start_time: 0,
        end_time: 0,
        title: webInfo.title,
        description: webInfo.description,
        video_urls: webInfo.video_urls,
        subtitle_urls: webInfo.subtitle_urls,
        subtitles: webInfo.subtitles,
        translations: webInfo.translations
      } as MomentInfo)
    }
  const currentSelection = ref<MomentInfo>(options[0])
  function updateCurrentSelection(id: string) {
    options.forEach(opt => {
      if (id == opt.id) currentSelection.value = opt
    })
  }
  return { options, updateCurrentSelection, currentSelection }
}
function checkExists(id: string, moments: MomentInfo[]) {
  if (!id) return false
  var exists = false
  moments.forEach((el: any) => {
    if (el.id == id) exists = true
  })
  return exists
}
