
import { RelatedResourceData } from "@/models/RelatedResourceData"
import { computed, defineComponent, PropType, ref, Ref } from "vue"
import injectStrict from "@/utils/injectStrict"
import { translationKey } from "@/symbols"
import { AnalyticsClient } from "@/services/AnalyticsService"
import { container } from "tsyringe"
import { AwEventType } from "@/models/AwEventType"

export default defineComponent({
  props: {
    hasEnded: { type: Object as PropType<Ref<Boolean>>, required: true },
    dur: { type: Object as PropType<Ref<Number>>, required: true },
    cta: { type: Object as PropType<RelatedResourceData>, required: false, default: null }
  },
  setup(props) {
    const analytics = container.resolve(AnalyticsClient)
    const pressed_close = ref(false)
    let showEndedPage = computed(() => {
      pressed_close.value = false
      return props.hasEnded.value
    })

    const openCtaNewTab = () => {
      window.open(props.cta.link, "_blank")
      if (props.cta?.id)
        analytics.register({ eventType: AwEventType.ResourceClicked, relatedResourceId: props.cta?.id }, null)
    }

    const { translateResource } = injectStrict(translationKey)
    return { showEndedPage, pressed_close, openCtaNewTab, translateResource }
  },
  methods: {
    clickClose() {
      this.pressed_close = true
    }
  }
})
