import eventBus from "@/event-bus"
import { Postplay } from "@/models/PostPlayOptions"

export default function handleAutoPlayEvent(instanceId: string | undefined) {
  if (instanceId == undefined) return
  eventBus.on(Postplay.AUTO_PLAY, (mid: any) => {
    const currentMomentIndex = window.momentList.findIndex(m => m.mid == mid.mid && m.instanceId == instanceId)
    if (currentMomentIndex + 1 < window.momentList.length) {
      const nextMoment = window.momentList[currentMomentIndex + 1]
      if (nextMoment.instanceId != instanceId) return
      const targetPlayer = document.getElementById(`moment-${nextMoment.mid}`) as HTMLDivElement
      targetPlayer.scrollIntoView({ behavior: "smooth", block: "center", inline: "center" })
      nextMoment.play()
    }
  })
}
