
import { computed, ComputedRef, defineComponent, PropType, ref, Ref } from "vue"
import replaceClass from "@/services/replaceClass"
import setUpVolume from "@/composables/shared/volume-handler"
import { translationKey } from "@/symbols"
import injectStrict from "@/utils/injectStrict"
export default defineComponent({
  props: {
    IS_IOS: { type: Boolean, required: false, default: false },
    togglePlay: { type: Function, required: true },
    playbtnString: {
      type: Object as PropType<ComputedRef<String>>,
      required: true
    },
    mediaElm: {
      type: Object as PropType<Ref<HTMLMediaElement>>,
      required: true
    },
    isPlaying: {
      type: Object as PropType<Ref<Boolean>>,
      required: true
    }
  },
  setup(props) {
    let volumeBar = ref<HTMLElement>()
    let volumeSeekTimer = 0
    let volumeSeeker = ref<HTMLInputElement>()
    let { isMuted, volume, volumecss, volumeBtnSvg } = setUpVolume(props.mediaElm)
    const { translateLabels } = injectStrict(translationKey)
    let playBtnAriaLabel = computed<String>(() =>
      props.isPlaying.value ? translateLabels("Pause Video") ?? "" : translateLabels("Play Video") ?? ""
    )
    let muteBtnAriaLabel = computed<String>(() =>
      isMuted.value ? translateLabels("Unmute Video") ?? "" : translateLabels("Mute Video") ?? ""
    )
    return {
      volumeSeekTimer,
      volumeBar,
      volumeSeeker,
      isMuted,
      volume,
      volumecss,
      volumeBtnSvg,
      playBtnAriaLabel,
      muteBtnAriaLabel
    }
  },
  mounted() {
    this.volumeSeekerSetUp()
  },
  methods: {
    volumeSeekerSetUp() {
      if (!this.volumeSeeker) return //TODO throw or log error
      var self = this
      this.volumeSeeker.oninput = function () {
        var value: number = parseFloat(self.volumeSeeker?.value ?? "0")
        self.volume = value
        if (value == 0) self.isMuted = true
        else self.isMuted = false
      }
    },
    onVolumeHover() {
      clearTimeout(this.volumeSeekTimer)
    },
    offVolumeHover() {
      this.volumeSeekTimer = setTimeout(() => {
        this.hideVolume()
      }, 200)
    },
    hideVolume() {
      clearTimeout(this.volumeSeekTimer)
      replaceClass(this.volumeBar as HTMLElement, `show`, `hide`)
    },
    showVolume() {
      clearTimeout(this.volumeSeekTimer)
      replaceClass(this.volumeBar as HTMLElement, `hide`, `show`)
    }
  }
})
