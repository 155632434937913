<template>
  <div :id="`moment-${moment.id}`">
    <div class="rnd-crns">
      <Player
        v-if="!moment.video_urls.iframe_url || moment.video_urls.iframe_url == ''"
        :video_urls="moment.video_urls"
        :duration="moment.duration"
        :mid="moment.id"
        :title="translateMoment(moment).title"
        :subtitleUrls="moment.subtitle_urls"
        :subtitles="moment.subtitles"
        :continuefromTime="moment.end_time"
        :videoType="ContentType.Moment"
        :useDemoOverlay="useDemoOverlay"
        :logoOverlay="logoOverlay"
        :isgated="isgated"
        :ma="ma"
        :cta="cta"
        :usetheaterMode="usetheaterMode"
        :post_play="post_play"
      />
      <IframePlayer
        v-else
        :iframe_url="moment.video_urls.iframe_url"
        :poster="moment.video_urls.poster_url"
        :duration="moment.duration"
      />
    </div>
    <p v-html="translateMoment(moment).title" class="mom-t" v-if="moment.title" />
    <div v-html="translateMoment(moment).description" class="mom-d" v-if="moment.description" />
    <SocialShare
      :id="moment.id"
      :title="translateMoment(moment).title"
      :description="translateMoment(moment).description"
      :share_buttons="share_buttons"
    />
  </div>
</template>
<script lang="ts">
import { MomentInfo } from "@/models/MomentInfo"
import { defineComponent, PropType, ref } from "vue"
import { ContentType } from "@/models/ContentType"
import Player from "@/components/shared/VideoPlayer.vue"
import IframePlayer from "@/components/shared/IframeVideoPlayer.vue"
import injectStrict from "@/utils/injectStrict"
import { GlobalDataKey, translationKey } from "@/symbols"
import LogoOverlayData from "@/models/LogoOverlayData"
import { MAData } from "@/models/ma/MAData"
import { setupSocialShare } from "@/composables/social-share"
import { ShareButtons } from "@/models/ShareButtons"
import SocialShare from "@/components/shared/SocialShare.vue"
import { RelatedResourceData } from "@/models/RelatedResourceData"

export default defineComponent({
  components: { Player, IframePlayer, SocialShare },
  props: {
    moment: { type: Object as PropType<MomentInfo>, required: true },
    useDemoOverlay: { type: Boolean, required: false, default: false },
    cta: { type: Object as PropType<RelatedResourceData>, required: false, default: null },
    logoOverlay: { type: Object as PropType<LogoOverlayData>, required: false, default: null },
    isgated: Boolean,
    ma: { type: Object as PropType<MAData>, required: false, default: null },
    usetheaterMode: { type: Boolean, required: false, default: false },
    post_play: { type: String, required: false, default: undefined },
    share_buttons: { type: Object as PropType<ShareButtons | null>, required: false, default: null }
  },
  setup(props) {
    const globalData = injectStrict(GlobalDataKey).value
    let socialShareUrl = ref(globalData.socialShareUrl)
    const { registerSocialShare } = setupSocialShare()
    const { translateMoment } = injectStrict(translationKey)
    return { ContentType, socialShareUrl, registerSocialShare, translateMoment }
  }
})
</script>
<style lang="scss" scoped>
.mom-t {
  font-size: 1.25em;
  line-height: normal;
  margin: 0.625em 0px;
  margin-bottom: 0.25em;
  font-weight: bold;
  color: var(--font-color);
  width: 100%;
}
.mom-d {
  font-size: 1em;
  margin-bottom: 0.375em;
  color: var(--font-color);
  width: 100%;
  line-height: normal;
}
</style>
