<template>
  <div ref="rootElm" :style="themeCss">
    <div id="aw--c">
      <div id="aw-sl" style="font-size: 1em !important">
        <SeoJsonLdComponentVue :videoInfo="videoInfo" v-if="videoInfo != undefined" />
        <TheaterPlayer
          :videos="lightboxVideos"
          :logoOverlay="videoInfo.overlay_logo"
          :useDemoOverlay="videoInfo.use_demo_overlay"
          :themeCss="themeCss"
          :ma="videoInfo.ma"
          :cta="videoInfo.cta"
          :isGated="videoInfo.is_gated"
          :post_play="videoInfo.post_play"
          v-if="videoInfo && lightboxVideos && lightboxVideos.length > 0 && usetheaterMode == true"
        />
        <div v-if="videoInfo != undefined" :key="videoInfo.video_urls.dash_url" class="amplily-player">
          <TrailerTimeline
            :moments="videoInfo.moments"
            :cta_text="videoInfo.cta_text"
            :resources="videoInfo.related_resources"
            :webinar_duration="videoInfo.webinar_duration"
            :video_urls="videoInfo.video_urls"
            :subtitle_urls="videoInfo.subtitle_urls"
            :subtitles="videoInfo.subtitles"
            :duration="videoInfo.duration"
            :title="translateWebinar(videoInfo).title"
            :use_demo_overlay="videoInfo.use_demo_overlay"
            :overlay_logo="videoInfo.overlay_logo"
            :isgated="videoInfo.is_gated"
            :ma="videoInfo.ma"
            :cta="videoInfo.cta"
            :usetheater-mode="usetheaterMode"
            :is_single="true"
          />
          <MAGate :ma="videoInfo.ma" :themeCss="themeCss" />
        </div>
        <TrailerTimelineSkeleton :loadingmsg="loadingmsg" v-else />
      </div>
    </div>
  </div>
</template>
<script lang="ts">
import "@/assets/_base.scss"

import VideoPlayer from "../components/shared/VideoPlayer.vue"
import IframeVideoPlayer from "../components/shared/IframeVideoPlayer.vue"
import { SingleVideoInfo } from "../models/SingleVideoInfo"
import { AwEventType } from "../models/AwEventType"
import setThemeData from "../services/setThemeData"
import { AnalyticsClient } from "@/services/AnalyticsService"
import { WebinarDataService } from "@/services/WebinarDataServices"
import { container } from "tsyringe"
import { computed, defineComponent, ref } from "@vue/runtime-core"
import injectStrict from "@/utils/injectStrict"
import { GlobalDataKey, LoggerKey, translationKey } from "@/symbols"
import getVideoType from "@/utils/getVideoType"
import { ContentType } from "@/models/ContentType"
import MomentBox from "@/components/TrailerTimelineComponents/MomentBox.vue"
import TrailerTimeline from "@/components/TrailerTimelineComponents/TrailerTimeline.vue"
import { getBackgroundColor } from "@/utils/getBackgroundColor"
import { AwEventStoreV2 } from "@/models/AwEventStoreV2"
import MAGate from "@/components/MA/MAGate.vue"
import getCssVariables from "@/utils/getCssVariables"
import { GoogleAnalyticsClient } from "@/services/GoogleAnalyticsService"
import SeoJsonLdComponentVue from "@/components/shared/SeoJsonLdComponent.vue"
import TheaterPlayer from "@/components/shared/TheaterPlayer.vue"
import { LightboxVideo } from "@/models/lightbox/LightboxModels"
import { isMobile, isIOS } from "@/services/deviceDetection"
import TrailerTimelineSkeleton from "@/components/skeletons/trailer-timeline.vue"

export default defineComponent({
  components: {
    VideoPlayer,
    IframeVideoPlayer,
    MomentBox,
    TrailerTimeline,
    MAGate,
    SeoJsonLdComponentVue,
    TheaterPlayer,
    TrailerTimelineSkeleton
  },
  props: {
    fetchId: { type: String, required: true }
  },
  setup(props) {
    const globalData = injectStrict(GlobalDataKey).value
    const logger = injectStrict(LoggerKey)
    const videoType: ContentType | undefined = getVideoType(props.fetchId)
    const webinarId = ref(-1)
    const videoInfo = ref<SingleVideoInfo | null>(null)
    const loadingmsg = ref("")
    const widgetHeight = computed<number>(() => globalData.height)
    let redirect_url = computed(() => globalData.redirect_data.url)
    let redirectText = computed(() => globalData.redirect_data.text)
    let rootElm = ref<HTMLElement>()
    let rootcolor = ref<string | undefined>()
    const themeCss = computed(() => getCssVariables(globalData.theme_data, rootcolor.value))
    const dataService = container.resolve(WebinarDataService)
    const analytics = container.resolve(AnalyticsClient)
    const gaAnalytics = container.resolve(GoogleAnalyticsClient)
    let lightboxVideos = computed<Array<LightboxVideo>>(() => {
      let lgtboxmoments: Array<LightboxVideo> = []
      if (videoInfo.value) {
        lgtboxmoments.push(
          new LightboxVideo(
            -1,
            videoInfo.value.video_urls,
            videoInfo.value.subtitles,
            videoInfo.value.duration,
            -1,
            ContentType.Trailer,
            videoInfo.value.title,
            0
          )
        )
      }
      return lgtboxmoments
    })

    let usetheaterMode = computed(
      () =>
        videoInfo.value &&
        videoInfo.value.use_theater_mode == true &&
        !!window.parmonic_disable_theater_mode == false &&
        !isIOS(navigator.userAgent, navigator.maxTouchPoints)
    )

    const { translateMoment, translateWebinar } = injectStrict(translationKey)
    return {
      lightboxVideos,
      dataService,
      widgetHeight,
      themeCss,
      globalData,
      webinarId,
      videoInfo,
      loadingmsg,
      analytics,
      redirect_url,
      logger,
      redirectText,
      videoType,
      rootElm,
      rootcolor,
      gaAnalytics,
      usetheaterMode,
      translateWebinar
    }
  },
  created() {
    this.dataService.getData(this.fetchId).then(returnValue => {
      this.loadingmsg = "The video could not be loaded."
      if (returnValue == undefined) {
        this.logger!.error(`Parmonic Widget error: Video not found`)
        return
      }
      this.videoInfo = returnValue as SingleVideoInfo
      this.webinarId = this.videoInfo.id
      setThemeData(this.videoInfo.theme_data, this.globalData)
      this.analytics.setUp(this.webinarId, null, null, null, this.globalData.analyticsEventsCaptureEndPoint, this.logger)
      this.gaAnalytics.setUp(this.webinarId, this.logger)
      var eventV2: AwEventStoreV2 = { eventType: AwEventType.WidgetViewed, type: ContentType.Trailer }
      this.analytics.register(eventV2, null)
    })
  },
  mounted() {
    this.logger.debug(this.rootcolor)
    this.rootcolor = getBackgroundColor(this.rootElm)
    this.logger.debug(this.rootcolor)
  }
})
</script>
<style scoped>
@import "../assets/trailer-timeline.scss";
.amplily-player {
  width: 100% !important;
}
</style>
