<template>
  <div class="caption-button-container player-adon" v-if="isPosterVisible.value">
    <div class="caption-button-list-flex">
      <button
        type="button"
        role="button"
        :aria-label="`${translateLabels('Play with captions in')} ${getLanguageString(c.language)}`"
        class="caption-play-buttons"
        v-for="(c, i) in posterCaptions"
        :key="i"
        @click="clickCaption(c.language ? c.language : `en`, true)"
      >
        <span
          ><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" class="play-arrow">
            <path d="M0 0h24v24H0z" fill="none" />
            <path d="M8 5v14l11-7z" /></svg></span
        >{{ c.language ? getLanguageString(c.language) : "English" }}
      </button>
    </div>
  </div>
</template>
<style lang="scss" scoped>
@import "../../assets/video/poster-captions.css";
</style>
<script lang="ts">
import { SubtitleUrls } from "@/models/captions/SubtitleUrls"
import { computed, defineComponent, PropType, Ref } from "vue"
import { translationKey } from "@/symbols"
import injectStrict from "@/utils/injectStrict"
import { getLanguageString } from "@/utils/getLanguageString"
export default defineComponent({
  props: {
    isPosterVisible: {
      type: Object as PropType<Ref<Boolean>>,
      required: true
    },
    subtitleArray: {
      type: Object as PropType<Ref<SubtitleUrls[]>>,
      required: true
    },
    clickCaption: { type: Function, required: true }
  },
  setup(props) {
    const { translateLabels } = injectStrict(translationKey)
    const posterCaptions = computed(() => props.subtitleArray.value.filter(x => x.language != "en").filter((x, i) => i < 5))
    return { posterCaptions, getLanguageString, translateLabels }
  }
})
</script>

<style scoped>
button:focus {
  outline: 2px solid var(--theme-color);
  outline-offset: 2px;
}

.full-video-player .caption-button-container {
  right: 0px;
}
.caption-button-list-flex {
  position: absolute;
  bottom: 16px;
  right: 5px;
  left: 16px;
  display: flex;
  flex-wrap: wrap-reverse;
  justify-content: right;
}
</style>
