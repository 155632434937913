import { Ref, ref } from "@vue/runtime-core"
export function getScrollArrowStatus(scrollContainer: Ref<HTMLElement | undefined>) {
  const veritcalScroll = scrollContainer.value
    ? scrollContainer.value?.scrollHeight > scrollContainer.value?.clientHeight
    : false
  const veritcalScrollAtTop = scrollContainer.value ? scrollContainer.value?.scrollTop == 0 : false
  const veritcalScrollAtBottom = scrollContainer.value
    ? scrollContainer.value?.scrollTop + scrollContainer.value?.clientHeight >= scrollContainer.value?.scrollHeight
    : false

  const horizontalScroll = scrollContainer.value
    ? scrollContainer.value?.scrollWidth > scrollContainer.value?.clientWidth
    : false
  const horizontalScrollAtLeft = scrollContainer.value ? scrollContainer.value?.scrollLeft == 0 : false
  const horizontalScrollAtRight = scrollContainer.value
    ? scrollContainer.value?.scrollLeft + scrollContainer.value?.clientWidth >= scrollContainer.value?.scrollWidth - 3
    : false

  return {
    veritcalScroll,
    veritcalScrollAtTop,
    veritcalScrollAtBottom,
    horizontalScroll,
    horizontalScrollAtLeft,
    horizontalScrollAtRight
  }
}

export function listenOnScrollForArrows(scrollContainer: Ref<HTMLElement | undefined>) {
  const arrowStatus = ref(getScrollArrowStatus(scrollContainer))
  function setup() {
    if (scrollContainer.value == undefined) return
    arrowStatus.value = getScrollArrowStatus(scrollContainer)
    scrollContainer.value.addEventListener("scroll", (e: any) => {
      arrowStatus.value = getScrollArrowStatus(scrollContainer)
    })
  }
  const unset = () => {
    if (scrollContainer.value == undefined) return
    scrollContainer.value.removeEventListener("scroll", () => {})
  }

  return { arrowStatus, setup, unset }
}
