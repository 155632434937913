<template>
  <div>
    <div class="skeleton-holder skeleton-desktop-view-only">
      <div class="main-video">
        <div class="loading-video main-video-skeleton">
          <svg class="loading-svg-icon shaka-big-play" viewBox="0 0 61 61" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect width="61" height="61" rx="12" fill="#ccc" />
            <path
              fill="#fff"
              d="M22 21.5763C22 19.5799 24.1634 18.3445 25.8688 19.367L40.752 28.2906C42.416 29.2884 42.416 31.7115 40.752 32.7092L25.8688 41.633C24.1635 42.6555 22 41.4201 22 39.4237L22 21.5763Z"
            />
          </svg>
          <div class="loading-message">{{ loadingmsg }}</div>
        </div>
      </div>
      <div class="holder holder-skeleton"></div>
    </div>
    <div :class="`skeleton-mobile-view-only`">
      <GridSkeleton :loadingmsg="loadingmsg" />
    </div>
  </div>
</template>
<style scoped>
@import "../../assets/skeleton.css";
.skeleton-holder {
  display: flex;
  gap: 15px;
}
.main-video {
  flex: 3;
}
.holder {
  flex: 2;
}
.moment {
  display: flex;
}
.holder-skeleton {
  background-color: #f0f0f0;
  margin-top: 30px;
  animation: skeleton-loading 1s linear infinite alternate;
}
@media screen and (max-width: 750px) {
  .skeleton-holder {
    flex-direction: column;
    gap: 5px;
    transform: translateX(-1em);
    padding-top: 30px;
  }
  .holder-skeleton {
    min-height: 170px;
  }
}
</style>
<script lang="ts">
import { defineComponent } from "@vue/runtime-core"
import GridSkeleton from "./grid.vue"
export default defineComponent({
  props: {
    loadingmsg: { type: String, required: false, default: "" }
  },
  components: { GridSkeleton }
})
</script>
