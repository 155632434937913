
import { defineComponent, PropType, ref } from "@vue/runtime-core"
import LogoOverlayData from "@/models/LogoOverlayData"
import injectStrict from "@/utils/injectStrict"
import { EventBusKey, GlobalDataKey } from "@/symbols"
import VideoPlayer from "@/components/shared/VideoPlayer.vue"
import { LightboxEventBusModal, LightboxVideo } from "@/models/lightbox/LightboxModels"
import { MAData } from "@/models/ma/MAData"
import { RelatedResourceData } from "@/models/RelatedResourceData"
import eventBus from "@/event-bus"
import { ContentType } from "@/models/ContentType"
import { Postplay } from "@/models/PostPlayOptions"

export default defineComponent({
  components: { VideoPlayer },
  props: {
    videos: { type: Object as PropType<Array<LightboxVideo>>, required: true },
    useDemoOverlay: { type: Boolean, required: false, default: false },
    logoOverlay: { type: Object as PropType<LogoOverlayData>, required: false, default: null },
    isTrailer: { type: Boolean, required: false, default: false },
    themeCss: { type: Object, required: true, default: {} },
    ma: { type: Object as PropType<MAData>, required: false, default: null },
    cta: { type: Object as PropType<RelatedResourceData>, default: null },
    post_play: { type: String, required: false, default: undefined },
    isGated: { type: Boolean, required: false, default: false }
  },
  setup(props) {
    let currentVideo = ref<LightboxVideo | null>(props.videos[0] ?? null)
    let eventbus = injectStrict(EventBusKey)
    let globalData = injectStrict(GlobalDataKey)
    let isLightboxVisible = ref<Boolean>(false)
    let isAutoPlay = ref<Boolean>(true)
    let currentSubtitle = ref<string | null>(null)
    const current_video = ref()
    eventbus.on("setup-lightbox", x => {
      let arg = x as LightboxEventBusModal
      if (globalData.value.instanceId != arg.instanceId) return
      var video = props.videos.find(x => x.identifier == arg.identifier && x.type == arg.contentType)
      if (!video) return
      currentSubtitle.value = arg.language ?? null
      currentVideo.value = video
      isAutoPlay.value = arg.autoplay ?? true
      isLightboxVisible.value = true
    })
    return { currentVideo, isLightboxVisible, currentSubtitle, isAutoPlay, current_video, globalData }
  },
  mounted() {
    eventBus.on(Postplay.AUTO_PLAY, ({ mid }: any) => {
      const currentMomentIndex = this.videos.findIndex(m => {
        return m.mid == mid
      })
      if (currentMomentIndex + 1 < this.videos.length && this.videos[currentMomentIndex + 1].identifier != -1) {
        const nextMoment = this.videos[currentMomentIndex + 1]
        console.log({ currentMomentIndex, videos: this.videos })
        setTimeout(() => {
          if (nextMoment.mid == null) return
          eventBus.emit(
            "setup-lightbox",
            new LightboxEventBusModal(this.globalData.instanceId, nextMoment.mid, null, ContentType.Moment, true)
          )
        }, 0)
        this.hideLightbox()
      }
    })
  },
  methods: {
    hideLightbox() {
      this.currentVideo = null
      this.isLightboxVisible = false
    }
  }
})
