export function getBackgroundColor(el: HTMLElement | undefined): string | undefined {
  if (!el) return undefined
  // get default style for current browser
  var defaultStyle = getDefaultBackground()
  // get computed color for el
  var backgroundColor = window.getComputedStyle(el).backgroundColor
  // if we got a real value, return it
  if (backgroundColor != defaultStyle) return backgroundColor
  // if we've reached the top parent el without getting an explicit color, return default
  if (!el.parentElement) return defaultStyle
  // otherwise, recurse and try again on parent element
  return getBackgroundColor(el.parentElement)
}

function getDefaultBackground() {
  // have to add to the document in order to use getComputedStyle
  var div = document.createElement("div")
  document.body.appendChild(div)
  var bg = window.getComputedStyle(div).backgroundColor
  document.body.removeChild(div)
  return bg
}
/**
 * Return the extracted [r, g, b, a] values from a string like "rgba(0, 5, 255, 0.8)",
 * If no alpha is specified, return undefined for it.
 */
export function rgbaFromString(str: string): Array<number | undefined> {
  const m = str.match(/^rgba?\s*\(\s*(\d+)\s*,\s*(\d+)\s*,\s*(\d+)\s*(?:,\s*(\d+(?:\.\d+)?)\s*)?\)$/i)
  if (m) return [parseFloat(m[1]), parseFloat(m[2]), parseFloat(m[3]), m[4] === undefined ? undefined : parseFloat(m[4])]
  else throw new Error("Color " + str + " did not match pattern rgb[a](r, g, b[, a]).")
}
