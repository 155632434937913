
import { WebinarInfo } from "@/models/WebinarInfo"
import VideoPlayer from "@/components/shared/VideoPlayer.vue"
import IframeVideoPlayer from "@/components/shared/IframeVideoPlayer.vue"
import { defineComponent, PropType, ref } from "@vue/runtime-core"
import setupOptions from "@/composables/option-handler"
import { ContentType } from "@/models/ContentType"
import { formatDate } from "@/services/DurationFormatter"
import injectStrict from "@/utils/injectStrict"
import { EventBusKey } from "@/symbols"
import getMomentId from "@/services/ParameterServices"
import SocialShare from "@/components/shared/SocialShare.vue"
import { listenOnScrollForArrows } from "@/services/scrollArrowStatus"
import HorizontalMoment from "@/components/HorizontalComponents/HorizontalMoment.vue"

export default defineComponent({
  components: { VideoPlayer, IframeVideoPlayer, HorizontalMoment, SocialShare },
  props: {
    webinarInfo: { type: Object as PropType<WebinarInfo>, required: true },
    usetheaterMode: { type: Boolean, required: false, default: false }
  },
  setup(props) {
    let pos = { top: 0, left: 0, x: 0, y: 0 }
    const scrollContainer = ref<HTMLElement | undefined>()
    var optionsObj = setupOptions(props.webinarInfo)
    var was_dragger = false
    let eventbus = injectStrict(EventBusKey)
    var height = ref<Number | null>(null)
    let isAnySocialMediaButtonEnable = false
    if (props.webinarInfo.share_buttons != null)
      isAnySocialMediaButtonEnable = Boolean(
        Object.values(props.webinarInfo.share_buttons).reduce((prev, now) => false || prev || now)
      )
    var arrowStatusService = listenOnScrollForArrows(scrollContainer)
    optionsObj.options = optionsObj.options.filter(m => m.id != "-1")
    return {
      optionsObj,
      ContentType,
      scrollContainer,
      pos,
      was_dragger,
      formatDate,
      height,
      eventbus,
      arrowStatusService,
      isAnySocialMediaButtonEnable
    }
  },
  created() {
    this.eventbus.on("setHeight", (value: any) => {
      this.height = value
    })
  },
  mounted() {
    this.addScroller()
    this.focus()
    this.arrowStatusService.setup()
  },
  unmounted() {
    this.arrowStatusService.unset()
  },
  methods: {
    changeSelection(id: string) {
      if (!this.was_dragger) this.optionsObj.updateCurrentSelection(id)
      else this.was_dragger = false
    },
    addScroller() {
      var self = this
      self.scrollContainer?.addEventListener("mousedown", (e: any) => mouseDownHandler(e))
      const mouseDownHandler = function (e: any) {
        if (!self.scrollContainer) return
        self.pos = {
          // The current scroll
          left: self.scrollContainer.scrollLeft,
          top: self.scrollContainer.scrollTop,
          // Get the current mouse position
          x: e.clientX,
          y: e.clientY
        }
        self.scrollContainer.style.cursor = "grabbing"
        self.scrollContainer.style.userSelect = "none"
        document.addEventListener("mousemove", self.mouseMoveHandler)
        document.addEventListener("mouseup", self.mouseUpHandler)
      }
    },
    mouseMoveHandler(e: any) {
      if (!this.scrollContainer) return
      // How far the mouse has been moved
      const dx = e.clientX - this.pos.x
      const dy = e.clientY - this.pos.y
      if (dx > 5 || dx < -5) {
        this.was_dragger = true
      }
      // Scroll the element
      //this.scrollContainer.scrollTop = pos.top - dy;
      this.scrollContainer.scrollLeft = this.pos.left - dx
    },
    mouseUpHandler() {
      document.removeEventListener("mousemove", this.mouseMoveHandler)
      document.removeEventListener("mouseup", this.mouseUpHandler)
      if (!this.scrollContainer) return
      this.scrollContainer.style.cursor = "default"
      this.scrollContainer.style.removeProperty("user-select")
    },
    scrollLeft() {
      if (!this.scrollContainer) return
      this.scrollContainer.scrollLeft = this.scrollContainer.scrollLeft - 350
      //this.scrollContainer.scrollLeft = this.pos.left - dx
    },
    scrollRight() {
      if (!this.scrollContainer) return
      this.scrollContainer.scrollLeft = this.scrollContainer.scrollLeft + 350
    },
    focus(i: string | null = null) {
      const url = window.location.search
      if (url.length == 0) return
      var id = getMomentId(url)
      const momentdiv = document.getElementById("moment-" + id) as HTMLElement
      if (momentdiv) {
        this.changeSelection(id)
        if (this.scrollContainer)
          this.scrollContainer.scrollIntoView({ behavior: "smooth", block: "center", inline: "center" })
        momentdiv.scrollIntoView({ behavior: "smooth", block: "center", inline: "center" })
        momentdiv.classList.toggle("moment-focus")
        setTimeout(() => momentdiv.classList.toggle("moment-focus"), 1500)
      }
    }
  }
})
