<template>
  <div>
    <div class="social-share-object-container" v-if="isAnySocialMediaButtonEnable">
      <SocialShare
        :id="optionsObj.currentSelection.value.id"
        :title="translateMoment(optionsObj.currentSelection.value).title"
        :description="translateMoment(optionsObj.currentSelection.value).description"
        :share_buttons="webinarInfo.share_buttons"
        v-if="optionsObj.currentSelection.value.id != -1"
      />
    </div>
    <div id="slider-player" class="rnd-crns" :key="optionsObj.currentSelection.value.id">
      <VideoPlayer
        :mid="optionsObj.currentSelection.value.id"
        :poster="optionsObj.currentSelection.value.video_urls.poster_url"
        :video_urls="optionsObj.currentSelection.value.video_urls"
        :subtitleUrls="optionsObj.currentSelection.value.subtitle_urls"
        :subtitles="optionsObj.currentSelection.value.subtitles"
        :continuefromTime="optionsObj.currentSelection.value.end_time"
        :duration="optionsObj.currentSelection.value.duration"
        :videoType="optionsObj.currentSelection.value.id == -1 ? ContentType.Webinar : ContentType.Moment"
        :title="translateMoment(optionsObj.currentSelection.value).title"
        :useDemoOverlay="webinarInfo.use_demo_overlay"
        :height="height"
        v-if="
          !optionsObj.currentSelection.value.video_urls.iframe_url ||
          optionsObj.currentSelection.value.video_urls.iframe_url == ''
        "
        :logoOverlay="webinarInfo.overlay_logo"
        :isgated="webinarInfo.is_gated"
        :ma="webinarInfo.ma"
        :cta="webinarInfo.cta"
        :usetheaterMode="usetheaterMode"
        :post_play="webinarInfo.post_play"
        :autoPlay="autoPlay"
        class="full-video-player"
      />
      <IframeVideoPlayer
        v-else
        :iframe_url="optionsObj.currentSelection.value.video_urls.iframe_url"
        :poster="optionsObj.currentSelection.value.video_urls.poster_url"
        :duration="optionsObj.currentSelection.value.duration"
      />
    </div>
    <div class="holder" v-if="webinarInfo.moments.length > 1">
      <div
        class="left btn"
        @click="scrollLeft()"
        v-if="
          arrowStatusService.arrowStatus.value.horizontalScroll &&
          !arrowStatusService.arrowStatus.value.horizontalScrollAtLeft
        "
      >
        <svg xmlns="http://www.w3.org/2000/svg" width="34" height="34" viewBox="0 0 24 24" class="icon-color">
          <path d="M7.41 15.41L12 10.83l4.59 4.58L18 14l-6-6-6 6z" class="icon-color" />
        </svg>
      </div>

      <div
        class="right btn"
        @click="scrollRight()"
        v-if="
          arrowStatusService.arrowStatus.value.horizontalScroll &&
          !arrowStatusService.arrowStatus.value.horizontalScrollAtRight
        "
      >
        <svg xmlns="http://www.w3.org/2000/svg" width="34" height="34" viewBox="0 0 24 24" class="icon-color">
          <path d="M7.41 15.41L12 10.83l4.59 4.58L18 14l-6-6-6 6z" class="icon-color" />
        </svg>
      </div>
      <div class="slider" ref="scrollContainer">
        <div class="moment-holder" id="parmonic-moments">
          <HorizontalOption
            :id="'moment-' + m.id"
            v-for="(m, i) in optionsObj.options"
            :key="i"
            :poster_url="m.video_urls.poster_url"
            :title="translateMoment(m).title"
            :description="translateMoment(m).description"
            :isSelected="m.id == optionsObj.currentSelection.value.id"
            :duration="formatDate(m.duration)"
            @click="changeSelection(m.id)"
            :isWebinar="m.id == -1"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { WebinarInfo } from "@/models/WebinarInfo"
import VideoPlayer from "@/components/shared/VideoPlayer.vue"
import IframeVideoPlayer from "@/components/shared/IframeVideoPlayer.vue"
import HorizontalOption from "@/components/layouts/HorizontalOption.vue"
import { defineComponent, PropType, ref } from "@vue/runtime-core"
import setupOptions from "@/composables/option-handler"
import { ContentType } from "@/models/ContentType"
import { formatDate } from "@/services/DurationFormatter"
import injectStrict from "@/utils/injectStrict"
import { EventBusKey } from "@/symbols"
import SocialShare from "@/components/shared/SocialShare.vue"
import getMomentId from "@/services/ParameterServices"
import { listenOnScrollForArrows } from "@/services/scrollArrowStatus"
import eventBus from "@/event-bus"
import { GlobalDataKey, translationKey } from "@/symbols"
import { Postplay } from "@/models/PostPlayOptions"
import { isMobile } from "@/services/deviceDetection"

export default defineComponent({
  components: { VideoPlayer, IframeVideoPlayer, HorizontalOption, SocialShare },
  props: {
    webinarInfo: { type: Object as PropType<WebinarInfo>, required: true },
    usetheaterMode: { type: Boolean, required: false, default: false }
  },
  setup(props) {
    let pos = { top: 0, left: 0, x: 0, y: 0 }
    const scrollContainer = ref<HTMLElement | undefined>()
    var optionsObj = setupOptions(props.webinarInfo)
    var was_dragger = false
    const globalData = injectStrict(GlobalDataKey).value
    let eventbus = injectStrict(EventBusKey)
    var height = ref<Number | null>(null)
    let isAnySocialMediaButtonEnable = false
    if (props.webinarInfo.share_buttons != null)
      isAnySocialMediaButtonEnable = Boolean(
        Object.values(props.webinarInfo.share_buttons).reduce((prev, now) => false || prev || now)
      )
    var autoPlay = ref(false)

    var arrowStatusService = listenOnScrollForArrows(scrollContainer)
    const { translateMoment } = injectStrict(translationKey)
    return {
      optionsObj,
      ContentType,
      scrollContainer,
      pos,
      was_dragger,
      formatDate,
      height,
      eventbus,
      arrowStatusService,
      autoPlay,
      globalData,
      isAnySocialMediaButtonEnable,
      translateMoment
    }
  },
  created() {
    this.eventbus.on("setHeight", (value: any) => {
      this.height = value
    })
  },
  mounted() {
    this.addScroller()
    this.focus()
    this.arrowStatusService.setup()
    if (!isMobile()) {
      eventBus.on(Postplay.AUTO_PLAY, (mid: any) => {
        const currentMomentIndex = window.momentList.findIndex(
          m => m.mid == mid.mid && m.instanceId == this.globalData.instanceId
        )
        if (currentMomentIndex + 1 < window.momentList.length) {
          this.autoPlay = true
          const nextMoment = window.momentList[currentMomentIndex + 1]
          this.optionsObj.updateCurrentSelection(`${nextMoment.mid}`)
        }
      })
    }
  },
  unmounted() {
    this.arrowStatusService.unset()
  },
  methods: {
    changeSelection(id: string) {
      this.autoPlay = false
      if (!this.was_dragger) this.optionsObj.updateCurrentSelection(id)
      else this.was_dragger = false
    },
    addScroller() {
      var self = this
      self.scrollContainer?.addEventListener("mousedown", (e: any) => mouseDownHandler(e))
      const mouseDownHandler = function (e: any) {
        if (!self.scrollContainer) return
        self.pos = {
          // The current scroll
          left: self.scrollContainer.scrollLeft,
          top: self.scrollContainer.scrollTop,
          // Get the current mouse position
          x: e.clientX,
          y: e.clientY
        }
        self.scrollContainer.style.cursor = "grabbing"
        self.scrollContainer.style.userSelect = "none"
        document.addEventListener("mousemove", self.mouseMoveHandler)
        document.addEventListener("mouseup", self.mouseUpHandler)
      }
    },
    mouseMoveHandler(e: any) {
      if (!this.scrollContainer) return
      // How far the mouse has been moved
      const dx = e.clientX - this.pos.x
      const dy = e.clientY - this.pos.y
      if (dx > 5 || dx < -5) {
        this.was_dragger = true
      }
      // Scroll the element
      //this.scrollContainer.scrollTop = pos.top - dy;
      this.scrollContainer.scrollLeft = this.pos.left - dx
    },
    mouseUpHandler() {
      document.removeEventListener("mousemove", this.mouseMoveHandler)
      document.removeEventListener("mouseup", this.mouseUpHandler)
      if (!this.scrollContainer) return
      this.scrollContainer.style.cursor = "default"
      this.scrollContainer.style.removeProperty("user-select")
    },
    scrollLeft() {
      if (!this.scrollContainer) return
      this.scrollContainer.scrollLeft = this.scrollContainer.scrollLeft - 190
      //this.scrollContainer.scrollLeft = this.pos.left - dx
    },
    scrollRight() {
      if (!this.scrollContainer) return
      this.scrollContainer.scrollLeft = this.scrollContainer.scrollLeft + 190
    },
    focus(i: string | null = null) {
      const url = window.location.search
      if (url.length == 0) return
      var id = getMomentId(url)
      if (parseInt(id) > 0) {
        const momentdiv = document.getElementById("slider-player") as HTMLElement
        if (momentdiv) {
          this.changeSelection(id)
          momentdiv.scrollIntoView(true)
        }
      }
    }
  }
})
</script>

<style lang="scss" scoped>
.social-share-object-container {
  min-height: 1.875em;
  display: flex;
  justify-content: right;
}

.icon-color {
  stroke: transparent;
  fill: var(--theme-contrast);
}

.slider {
  overflow-x: scroll;
  width: 100%;
  height: fit-content;

  -ms-overflow-style: none;
  scrollbar-width: none;
  position: relative;
}

.moment-holder {
  display: flex;
  column-gap: 18px;
  margin-left: -18px;
  margin-top: 14px;
  width: fit-content;
  margin-bottom: 5px;
}

.slider::-webkit-scrollbar {
  display: none;
}

.holder {
  width: 100%;
  position: relative;
}

.btn {
  position: absolute;
  display: flex;
  height: 100%;
  top: 0px;
  border: 0px;
  align-items: center;
  justify-content: center;
  z-index: 1;
  cursor: pointer;

  svg {
    background-color: var(--theme-color-9);
    border-radius: 360px;
  }
}

.left {
  left: 0px;

  svg {
    transform: rotate(-90deg);
    box-shadow: -2px 0px rgba(0, 0, 0, 0.4);
  }

  svg:hover {
    transform: rotate(-90deg) scale(1.1);
  }
}

.right {
  right: 0px;

  svg {
    transform: rotate(90deg);
    box-shadow: 2px 0px rgba(0, 0, 0, 0.4);
  }

  svg:hover {
    transform: rotate(90deg) scale(1.1);
  }
}
</style>
