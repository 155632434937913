
import { defineComponent, PropType, Ref } from "vue"
import { PosterUrl } from "@/models/PosterUrl"
export default defineComponent({
  props: {
    poster_url: { type: String, required: true },
    poster_urls: { type: Object as PropType<Array<PosterUrl>>, required: false, default: [] },
    play: { type: Function, required: true },
    isPosterVisible: {
      type: Object as PropType<Ref<Boolean>>,
      required: true
    }
  },
  setup(props) {
    var cssVars = {
      "--default-poster": `url(${props.poster_url})`,
      "--background-240": `url(${geturl(240)})`,
      "--background-480": `url(${geturl(480)})`,
      "--background-960": `url(${geturl(960)})`,
      "--background-1440": `url(${geturl(1440)})`,
      "--background-1920": `url(${geturl(1920)})`
    }
    function geturl(width: number): string {
      if (!props.poster_urls) props.poster_url
      var urlObj = props.poster_urls.find(x => x.width == width)
      return urlObj?.url ?? props.poster_url
    }
    return { cssVars }
  }
})
