
import { MAData, PlayerCallback } from "@/models/ma/MAData"
import { EventBusKey, GlobalDataKey } from "@/symbols"
import injectStrict from "@/utils/injectStrict"
import { defineComponent, PropType, ref } from "@vue/runtime-core"
import MAFormModal from "./MAFormModal.vue"

export default defineComponent({
  components: { MAFormModal },
  props: {
    ma: { type: Object as PropType<MAData>, required: false },
    themeCss: { type: Object, required: true, default: {} }
  },
  setup(props) {
    let eventbus = injectStrict(EventBusKey)
    let globalData = injectStrict(GlobalDataKey)
    let maModal = ref<InstanceType<typeof MAFormModal>>()

    eventbus.on("gated-play", x => {
      let arg = x as PlayerCallback
      if (globalData.value.instanceId != arg.instanceId) return

      if (props.ma) {
        maModal.value?.showModal(arg.callback)
      }
    })

    return {
      maModal
    }
  }
})
