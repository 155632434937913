
import { SubtitleUrls } from "@/models/captions/SubtitleUrls"
import { computed, defineComponent, PropType, Ref } from "vue"
import { translationKey } from "@/symbols"
import injectStrict from "@/utils/injectStrict"
import { getLanguageString } from "@/utils/getLanguageString"
export default defineComponent({
  props: {
    isPosterVisible: {
      type: Object as PropType<Ref<Boolean>>,
      required: true
    },
    subtitleArray: {
      type: Object as PropType<Ref<SubtitleUrls[]>>,
      required: true
    },
    clickCaption: { type: Function, required: true }
  },
  setup(props) {
    const { translateLabels } = injectStrict(translationKey)
    const posterCaptions = computed(() => props.subtitleArray.value.filter(x => x.language != "en").filter((x, i) => i < 5))
    return { posterCaptions, getLanguageString, translateLabels }
  }
})
