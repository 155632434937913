<template>
  <div class="aw-hubspot-form" ref="root">
    <ScriptComponent :value="formScript" @onscriptloaded="scriptLoaded" />
    <div id="hsform_container" :style="hsFormVars"></div>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, PropType, ref, watch } from "vue"
import ScriptComponent from "./ScriptComponent.vue"
import { MAData } from "@/models/ma/MAData"
import { setHubspotKnownCache } from "@/services/MarketingAutomationApi"

declare var hbspt: any

export default defineComponent({
  components: { ScriptComponent },
  props: {
    config: Object as PropType<MAData>
  },
  setup: function (props, { emit }) {
    const formScript = `<script charset="utf-8" type="text/javascript" src="//js.hsforms.net/forms/embed/v2.js"><\/script>`
    let root = ref<HTMLElement>()
    let hsFormIframeHeight = ref(150)
    let formContainer = ref<HTMLElement>()
    watch(
      () => formContainer.value?.clientHeight,
      v => {
        if (v) hsFormIframeHeight.value = v
      }
    )

    let hsFormVars = computed(() => {
      return {
        "--hs-form-iframe-height": hsFormIframeHeight.value + "px"
      } as any
    })

    const updateFormHeight = () => {
      setTimeout(() => {
        // form height doesn't change immediatly, so added setTimeout
        if (formContainer.value?.clientHeight) hsFormIframeHeight.value = formContainer.value.clientHeight
      }, 50)
    }

    let isDOM = (node: any) => {
      return typeof node == "object" && "nodeType" in node && node.nodeType === 1 && node.cloneNode
    }

    let scriptLoaded = () => {
      hbspt.forms.create({
        target: "#hsform_container",
        region: `${props.config?.region}`,
        portalId: `${props.config?.ma_account_id}`,
        formId: `${props.config?.form_id}`,
        onFormSubmitted: (form: any) => {
          setHubspotKnownCache(true)
          emit("formsubmitted", form)
        },
        onFormReady: (formEl: any) => {
          formContainer.value = (formEl.context ?? (isDOM(formEl) ? formEl : formEl[0])) as HTMLElement
          formContainer.value.addEventListener("change", x => updateFormHeight())
          formContainer.value.addEventListener("input", x => updateFormHeight())
          formContainer.value.addEventListener("submit", x => updateFormHeight())
          hsFormIframeHeight.value = formContainer.value.clientHeight
          setTimeout(() => updateFormHeight(), 500)
        }
      })
    }

    return {
      root,
      scriptLoaded,
      formScript,
      hsFormVars
    }
  }
})
</script>

<style lang="scss">
@use "../../assets/base.css";
@import "../../assets/ma/hubspot.css";

.aw-hubspot-form {
  font-family: var(--font-family);
  font-size: 1em;
  line-height: normal;

  iframe {
    width: 100%;
  }

  .hs-form > iframe {
    display: none;
  }

  .hs-form .hs-form-field {
    display: block;
    float: none;
    flex-wrap: wrap;
    margin-bottom: 15px;
    position: relative;
    width: 100%;
  }

  .hs-form .hs-form-field[style*="display:none"],
  .hs-form .hs-form-field[style*="display: none"] {
    display: none;
  }

  .hs-form .hs-form-field .input {
    margin-top: 3px;
  }

  .hs-form .hs-input {
    @extend .aw-form-control;
  }

  .hs-form .hs-form-required {
    margin-left: 3px;
  }

  .hs-form .hs-error-msgs {
    position: relative;
    top: initial;
    left: initial;
    right: initial;
    bottom: initial;
    clear: both;
    margin: 0px;
  }

  .hs-form .hs-error-msgs .hs-error-msg {
    background-color: initial;
    background-image: initial;
    box-shadow: none;
    border: none;
    text-shadow: initial;
    color: #e51b00;
    padding: 0px;
  }

  .hs-form .hs-button.primary {
    @extend .aw-btn;
    @extend .aw-btn-theme;
  }

  .hs-form .no-list,
  .hs-form .inputs-list {
    list-style-type: none;
  }
  .no-list li {
    padding-left: 2px;
  }
  .inputs-list li {
    padding-left: 0px;
  }

  .hs-input[type="checkbox"] {
    float: left;
    width: auto;
    margin-right: 9px;
  }
  .hs-form .hs_error_rollup {
    margin-bottom: 15px;
  }

  .g-recaptcha-response {
    display: none;
  }

  [style*="display:none"],
  [style*="display: none"] {
    display: none;
  }

  .hs-form-iframe {
    position: static;
    border: none;
    display: block;
    overflow: hidden;
    width: 100%;
    height: var(--hs-form-iframe-height);
  }

  .grecaptcha-badge {
    width: 256px;
    height: 60px;
    box-shadow: grey 0px 0px 5px;
  }

  .grecaptcha-badge iframe {
    width: 256px;
    height: 60px;
  }
}
</style>
