import { InjectionKey, Ref } from "vue"
import IGlobalData from "@/models/GlobalData"
import { RootLogger } from "loglevel"
import { InternationalizationInterface } from "@/utils/internationalization"
import mitt from "mitt"
const emittor = mitt()
export const GlobalDataKey: InjectionKey<Ref<IGlobalData>> = Symbol("IGlobalData")
export const LoggerKey: InjectionKey<RootLogger> = Symbol("Logger")
export const EventBusKey: InjectionKey<typeof emittor> = Symbol("Emmitter")
export const translationKey: InjectionKey<InternationalizationInterface> = Symbol("Internationalization")
