import { VideoInfo } from "@/models/VideoInfo"
import logger from "../logLevel"

export default function isVideoUrlsValid(videoUrls: VideoInfo, logg: typeof logger | undefined) {
  var str = ""
  if (!videoUrls.dash_url) str += "dash-url,"
  if (!videoUrls.hls_url) str += "hls-url,"
  if (!videoUrls.poster_url) {
    if (logg) logg.error(`Parmonic Widget Error: Missing Urls (poster-url)`)
  }
  if (str != "") {
    if (logg) logg.error(`Parmonic Widget Error: Missing Urls (${str})`)
    return false
  }
  return true
}
