export default {
  "Full Video": "Video completo",
  "Related Resources": "Risorse Correlate",
  Blog: "Blog",
  Play: "Giocare",
  "No Captions": "Nessuna Didascalia",
  Captions: "Didascalie",
  "Toggle fullscreen": "Attiva/Disattiva schermo intero",
  "playback speed": "Velocità di riproduzione",
  "Play with captions in": "Riproduci con didascalie in",
  Seek: "Cerca",
  "Watch Trailer": "Guarda il trailer",
  "Watch full video": "Guarda il video completo",
  "Watch Video": "Guarda il video",
  Speakers: "Altoparlanti",
  Infographic: "Infografica",
  eBook: "eBook",
  Webinar: "Webinar",
  Video: "Video",
  "White Paper": "Libro bianco",
  Slides: "Diapositive",
  Article: "Articolo",
  "Icon not found exception": "Eccezione icona non trovata",
  "Key Moments": "Momenti Chiave",
  Resources: "Risorse"
}
