import { CookieManagerService } from "./CookieManagerService"
import { KNOWN_USER, LocalStorageService } from "./LocalStorageService"

export interface KnownUserData {
  email?: string
}

export function getParmonicKnownUser(): KnownUserData | undefined {
  let provider = window.parmonic_known_user_provider
  if (!provider) return undefined

  if (provider === "cookie") {
    let cookieData = CookieManagerService.getByName("__parmonic_pages_auth_user")
    return cookieData ? (JSON.parse(cookieData) as KnownUserData) : undefined
  } else if (provider === "local-storage") {
    let service = new LocalStorageService()
    return service.getKnownUser()
  } else {
    return provider()
  }
}
