const RGB_COLOR_REGEX = /\((\d+),\s*(\d+),\s*(\d+)(,\s*(\d*.\d*))?\)/
const shorthandRegex = /^#?([a-f\d])([a-f\d])([a-f\d])$/i
export default class Color {
  public r: number = 255
  public g: number = 255
  public b: number = 255
  public a: number = 1
  constructor(r?: string | number, g?: number, b?: number, a?: number) {
    if (typeof r === "string") {
      r = r.trim()
      if (r.indexOf("#") === 0) {
        r = r.replace(shorthandRegex, function (m, r1, g1, b1) {
          return r1 + r1 + g1 + g1 + b1 + b1
        })
        r = r.substring(r.indexOf("#") + 1)
        this.r = parseInt(r.substring(0, 2), 16)
        this.g = parseInt(r.substring(2, 4), 16)
        this.b = parseInt(r.substring(4, 6), 16)
      } else if (r.indexOf("rgb") === 0) {
        const res = RGB_COLOR_REGEX.exec(r)
        if (!res) throw new Error("Color could not be parsed")
        this.r = parseInt(res[1], 10)
        this.g = parseInt(res[2], 10)
        this.b = parseInt(res[3], 10)
        this.a = res[5] ? parseFloat(res[5]) : 1
      }
    } else {
      this.r = r || 0
      this.g = g || 0
      this.b = b || 0
      this.a = a || 1
    }
  }
  toHex() {
    return "#" + this.r.toString(16) + this.g.toString(16) + this.b.toString(16)
  }

  toRgb() {
    return `rgb(${this.r}, ${this.g}, ${this.b})`
  }

  toRgba() {
    return `rgba(${this.r}, ${this.g}, ${this.b}, ${this.a})`
  }
  islight() {
    if (this.a == 0) return true
    return (this.r + this.g + this.b) / 3 > 152
  }
  tohsl() {
    let r = this.r / 255
    let g = this.g / 255
    let b = this.b / 255
    let cmin = Math.min(r, g, b),
      cmax = Math.max(r, g, b),
      delta = cmax - cmin,
      h = 0,
      s = 0,
      l = 0
    if (delta == 0) h = 0
    // Red is max
    else if (cmax == r) h = ((g - b) / delta) % 6
    // Green is max
    else if (cmax == g) h = (b - r) / delta + 2
    // Blue is max
    else h = (r - g) / delta + 4

    h = Math.round(h * 60)

    // Make negative hues positive behind 360°
    if (h < 0) h += 360
    // Calculate lightness
    l = (cmax + cmin) / 2

    // Calculate saturation
    s = delta == 0 ? 0 : delta / (1 - Math.abs(2 * l - 1))

    // Multiply l and s by 100
    s = +(s * 100).toFixed(1)
    l = +(l * 100).toFixed(1)

    return [h, s, l]
  }
  getDarketVersion() {
    let [h, s, l] = this.tohsl()
    //Make color dark
    l = 4
    // Must be fractions of 1
    s /= 100
    l /= 100

    let c = (1 - Math.abs(2 * l - 1)) * s,
      x = c * (1 - Math.abs(((h / 60) % 2) - 1)),
      m = l - c / 2,
      r = 0,
      g = 0,
      b = 0
    if (0 <= h && h < 60) {
      r = c
      g = x
      b = 0
    } else if (60 <= h && h < 120) {
      r = x
      g = c
      b = 0
    } else if (120 <= h && h < 180) {
      r = 0
      g = c
      b = x
    } else if (180 <= h && h < 240) {
      r = 0
      g = x
      b = c
    } else if (240 <= h && h < 300) {
      r = x
      g = 0
      b = c
    } else if (300 <= h && h < 360) {
      r = c
      g = 0
      b = x
    }
    r = Math.round((r + m) * 255)
    g = Math.round((g + m) * 255)
    b = Math.round((b + m) * 255)

    return [r, g, b]
  }
}
