import { singleton, inject } from "tsyringe"
import type GaEvent from "@/models/googleAnalytics/GaEvent"
import { RootLogger } from "loglevel"
import { getHostUrl } from "@/utils/getHostUrl"

export interface IGoogleAnalytics {
  setUp(id: number | null, logger: RootLogger): void
  registerVideoStart(ge: GaEvent): void
  registerVideoComplete(ge: GaEvent): void
  registerVideoShared(ge: GaEvent): void
  registerVideoProgress(ge: GaEvent): void
}
export class GoogleAnalyticsService implements IGoogleAnalytics {
  id: number | null = null
  private host_url: string | undefined = getHostUrl()
  provider: string = "parmonic"
  private logger?: RootLogger
  setUp(id: number | null, logger: RootLogger): void {
    this.id = id
    this.logger = logger
  }
  registerVideoStart(ge: GaEvent): void {
    this.registerEvent(ge, "video_start")
  }
  registerVideoComplete(ge: GaEvent): void {
    this.registerEvent(ge, "video_complete")
  }
  registerVideoShared(ge: GaEvent): void {
    this.registerEvent(ge, "share")
  }
  registerVideoProgress(ge: GaEvent): void {
    this.registerEvent(ge, "video_progress")
  }
  registerEvent(ge: GaEvent, event: string) {
    // If GTM is defined, we push to dataLayer
    if ((window as any).google_tag_manager) {
      this.pushToDataLayer(ge, event)
    }

    // if GTM is not defined, but gtag is, we push to gtag
    else if (typeof window.gtag === "function") {
      window.gtag("event", event, {
        item_id: ge.item_id ?? this.id,
        content_type: ge.content_type,
        video_title: ge.video_title,
        video_url: this.host_url,
        video_provider: "parmonic",
        video_percent: ge.video_percent
      })
    } else {
      // nothing appears to be defined -- still push to dataLayer
      this.pushToDataLayer(ge, event)
    }
  }

  pushToDataLayer(ge: GaEvent, event: string) {
    window.dataLayer = window.dataLayer || []

    window.dataLayer.push({
      event: event, // extra event prop for GTM
      item_id: ge.item_id ?? this.id,
      content_type: ge.content_type,
      video_title: ge.video_title,
      video_url: this.host_url,
      video_provider: "parmonic",
      video_percent: ge.video_percent
    })
  }
}

export class MockGoogleAnalyticsService implements IGoogleAnalytics {
  id: number | null = null
  private host_url: string | undefined = getHostUrl()
  provider: string = "parmonic"
  private logger?: RootLogger
  setUp(id: number | null, logger: RootLogger): void {
    this.id = id
    this.logger = logger
  }
  registerVideoStart(ge: GaEvent): void {
    var event = {
      event: "video_start",
      item_id: ge.item_id ?? this.id,
      content_type: ge.content_type,
      video_title: ge.video_title,
      video_url: this.host_url,
      video_provider: "parmonic"
    }
    if (this.logger) this.logger.debug(`Mock GoogleAnalytics registered:: ${JSON.stringify(event)}`)
  }
  registerVideoComplete(ge: GaEvent): void {
    var event = {
      event: "video_complete",
      item_id: ge.item_id ?? this.id,
      content_type: ge.content_type,
      video_title: ge.video_title,
      video_url: this.host_url,
      video_provider: "parmonic"
    }
    if (this.logger) this.logger.debug(`Mock GoogleAnalytics registered:: ${JSON.stringify(event)}`)
  }
  registerVideoShared(ge: GaEvent): void {
    var event = {
      event: "share",
      item_id: ge.item_id ?? this.id,
      content_type: ge.content_type,
      video_title: ge.video_title,
      video_url: this.host_url,
      video_provider: "parmonic"
    }
    if (this.logger) this.logger.debug(`Mock GoogleAnalytics registered:: ${JSON.stringify(event)}`)
  }
  registerVideoProgress(ge: GaEvent): void {
    var event = {
      event: "video_progress",
      item_id: ge.item_id ?? this.id,
      content_type: ge.content_type,
      video_title: ge.video_title,
      video_url: this.host_url,
      video_provider: "parmonic",
      video_percent: ge.video_percent
    }
    if (this.logger) this.logger.debug(`Mock GoogleAnalytics registered:: ${JSON.stringify(event)}`)
  }
}
@singleton()
export class GoogleAnalyticsClient {
  private _client: IGoogleAnalytics
  constructor(@inject("GoogleAnalyticsService") public client: IGoogleAnalytics) {
    this._client = client
  }
  public setUp(id: number | null, logger: RootLogger) {
    this._client.setUp(id, logger)
  }
  public registerVideoStart(ge: GaEvent) {
    this._client.registerVideoStart(ge)
  }
  public registerVideoShared(ge: GaEvent) {
    this._client.registerVideoShared(ge)
  }
  public registerVideoComplete(ge: GaEvent) {
    this._client.registerVideoComplete(ge)
  }
  public registerVideoProgress(ge: GaEvent) {
    this._client.registerVideoProgress(ge)
  }
}
