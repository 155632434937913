import { SubtitleUrls } from "@/models/captions/SubtitleUrls"

var { default: srtParser2 } = require("srt-parser-2")
import axios from "axios"
import { computed, Ref, ref } from "vue"
import { StringToSeconds } from "@/services/DurationFormatter"
import CaptionData, { CaptionSegment } from "@/models/captions/CaptionsData"
const CAPTIONS_OFF = "off"
const CAPTIONS_OFF_SVG = `<svg xmlns="http://www.w3.org/2000/svg" class="icon-control" viewBox="0 0 32 32" fill="none">
<path fill-rule="evenodd" clip-rule="evenodd" d="M27 8H5L5 24H27V8ZM5 6C3.89543 6 3 6.89543 3 8V24C3 25.1046 3.89543 26 5 26H27C28.1046 26 29 25.1046 29 24V8C29 6.89543 28.1046 6 27 6H5Z" fill="white"/>
<path d="M15.1918 14.5639C14.9233 12.625 13.4318 11.5 11.4972 11.5C9.21733 11.5 7.5 13.1619 7.5 15.983C7.5 18.7997 9.19602 20.4659 11.4972 20.4659C13.5724 20.4659 14.9489 19.1236 15.1918 17.4574L13.598 17.4489C13.3977 18.4716 12.5625 19.0469 11.5185 19.0469C10.1037 19.0469 9.08949 17.9858 9.08949 15.983C9.08949 14.0142 10.0952 12.919 11.5227 12.919C12.5838 12.919 13.4148 13.5199 13.598 14.5639H15.1918Z" fill="white"/>
<path d="M24.1097 14.5639C23.8413 12.625 22.3498 11.5 20.4151 11.5C18.1353 11.5 16.418 13.1619 16.418 15.983C16.418 18.7997 18.114 20.4659 20.4151 20.4659C22.4904 20.4659 23.8668 19.1236 24.1097 17.4574L22.516 17.4489C22.3157 18.4716 21.4805 19.0469 20.4364 19.0469C19.0217 19.0469 18.0075 17.9858 18.0075 15.983C18.0075 14.0142 19.0131 12.919 20.4407 12.919C21.5018 12.919 22.3327 13.5199 22.516 14.5639H24.1097Z" fill="white"/>
</svg>`
const CAPTIONS_SVG = `<svg xmlns="http://www.w3.org/2000/svg" class="icon-control" viewBox="0 0 32 32" fill="none">
<path fill-rule="evenodd" clip-rule="evenodd" d="M5 6C3.89543 6 3 6.89543 3 8V24C3 25.1046 3.89543 26 5 26H27C28.1046 26 29 25.1046 29 24V8C29 6.89543 28.1046 6 27 6H5ZM11.6363 11.5C13.571 11.5 15.0625 12.625 15.3309 14.564H13.7372C13.5539 13.5199 12.723 12.9191 11.6619 12.9191C10.2343 12.9191 9.22865 14.0142 9.22865 15.983C9.22865 17.9858 10.2429 19.0469 11.6576 19.0469C12.7017 19.0469 13.5369 18.4716 13.7372 17.4489L15.3309 17.4574C15.088 19.1236 13.7116 20.4659 11.6363 20.4659C9.33518 20.4659 7.63916 18.7997 7.63916 15.983C7.63916 13.162 9.35649 11.5 11.6363 11.5ZM20.5543 11.5C22.4889 11.5 23.9804 12.625 24.2489 14.564H22.6551C22.4719 13.5199 21.6409 12.9191 20.5799 12.9191C19.1523 12.9191 18.1466 14.0142 18.1466 15.983C18.1466 17.9858 19.1608 19.0469 20.5756 19.0469C21.6196 19.0469 22.4549 18.4716 22.6551 17.4489L24.2489 17.4574C24.006 19.1236 22.6296 20.4659 20.5543 20.4659C18.2532 20.4659 16.5571 18.7997 16.5571 15.983C16.5571 13.162 18.2745 11.5 20.5543 11.5Z" fill="white"/>
</svg>`
export default function setUp(subtitleUrls: SubtitleUrls[]) {
  //setup variables
  //langiage of the currentcaption
  const currentCaptions = ref(CAPTIONS_OFF)
  const captionBtnString = computed(() => (currentCaptions.value == CAPTIONS_OFF ? CAPTIONS_OFF_SVG : CAPTIONS_SVG))
  //All urls of the captions
  let urlsArray: Ref<SubtitleUrls[]> = ref(subtitleUrls)
  //Captions already imported and parsed before. adding off manually to it
  let loadedCaptions: CaptionData[] = [new CaptionData(CAPTIONS_OFF, [])]
  //CurrentCaption Data
  let currentCaptionData: Ref<CaptionData> = ref(loadedCaptions[0])

  //used to set the captions
  async function setCaption(language: string) {
    //If captions were already parsed load them
    let caption_in_memory = getcaptionsDownloaded(language)
    if (caption_in_memory) {
      currentCaptionData.value = caption_in_memory
      currentCaptions.value = caption_in_memory.language
      return
    }
    //if captions exist but not parsed load them
    let caption_urls = getcaptions(language)
    if (caption_urls) {
      let caption_data = await PopulateSubtitleSegments(caption_urls.language, caption_urls.srt_url)
      loadedCaptions.push(caption_data)
      currentCaptions.value = caption_data.language
      currentCaptionData.value = caption_data
      return
    }
    //said captions dont exist it is an error state
    //this should not be possible //TODO throw Error here
  }
  let getcaptions = (lang: string): SubtitleUrls | undefined => urlsArray.value.find(x => x.language == lang)
  let getcaptionsDownloaded = (lang: string): CaptionData | undefined => loadedCaptions.find(x => x.language == lang)
  function updateCaptionsArray(subtitleUrlArray: Array<SubtitleUrls>) {
    currentCaptions.value = CAPTIONS_OFF
    urlsArray.value = subtitleUrlArray
    loadedCaptions = [new CaptionData(CAPTIONS_OFF, [])]
    currentCaptionData.value = loadedCaptions[0]
  }
  return {
    currentCaptions,
    currentCaptionData,
    subtitleArray: urlsArray,
    updateCaptionsArray,
    setCaption,
    captionBtnString
  }
}
//Funciton to get the subtitles
// change it to usable formatt
// and return
async function PopulateSubtitleSegments(lang: string, srt_url: string): Promise<CaptionData> {
  let parser = new srtParser2()
  let response = await axios.get(srt_url)
  let parsed = parser.fromSrt(response.data)
  parsed.forEach((elem: any) => {
    elem.endTime = StringToSeconds(elem.endTime, true)
    elem.startTime = StringToSeconds(elem.startTime, true)
  })
  let subtitleObj = parsed as CaptionSegment[]
  let captionData = new CaptionData(lang, subtitleObj)
  return captionData
}
