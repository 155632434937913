
import { isFullScreenAvailable } from "@/services/fullScreenService"
import { ComputedRef, defineComponent, PropType, Ref, ref } from "vue"
import setUpFullScreen from "@/composables/shared/fullscreen-hander"
import { isMobile } from "@/services/deviceDetection"
import replaceclass from "@/services/replaceClass"
import { SubtitleUrls } from "@/models/captions/SubtitleUrls"
import CCMenuComponent from "@/components/VideoPlayerComponents/CCMenuComponent.vue"
import injectStrict from "@/utils/injectStrict"
import { EventBusKey, translationKey } from "@/symbols"
import PlaybackSpeedComponent from "@/components/VideoPlayerComponents/PlaybackSpeedComponent.vue"

export default defineComponent({
  components: {
    CCMenuComponent,
    PlaybackSpeedComponent
  },
  props: {
    IS_IOS: { type: Boolean, required: true },
    playerContainer: {
      type: Object as PropType<Ref<HTMLElement | undefined>>,
      required: true
    },
    videoPlayer: {
      type: Object as PropType<Ref<HTMLVideoElement | undefined>>,
      required: true
    },
    captionBtnString: { type: Object as PropType<Ref<String>>, required: true },
    canUseCaptions: {
      type: Object as PropType<Ref<Boolean>>,
      required: true
    },
    subtitleArray: {
      type: Object as PropType<Ref<SubtitleUrls[]>>,
      required: true
    },
    clickCaption: { type: Function, required: false },
    currentCaptions: { type: Object as PropType<Ref<String>>, required: true },
    height: { type: Object as PropType<ComputedRef<number>>, required: true }
  },
  setup(props) {
    let captionHolder = ref<HTMLElement>()
    let isCaptionsDropdownShown = ref<Boolean>()
    let captionScrollTimer = ref(0)
    let canFullScreen = ref(isFullScreenAvailable() && !props.IS_IOS)
    let fullscreenObj = setUpFullScreen(props.playerContainer)
    const { translateLabels } = injectStrict(translationKey)
    let eventbus = injectStrict(EventBusKey)
    return {
      eventbus,
      canFullScreen,
      fullscreenObj,
      captionScrollTimer,
      captionHolder,
      isCaptionsDropdownShown,
      translateLabels
    }
  },
  mounted() {
    this.eventbus.on("remove-fullscreen", () => {
      console.debug("Exiting")
      this.fullscreenObj.exitFullscreen()
    })
  },
  methods: {
    togglefullscreen() {
      this.fullscreenObj.togglefullscreen()
    },
    offCaptionsHover() {
      this.captionScrollTimer = setTimeout(() => {
        this.hideCaptions()
      }, 200)
    },
    toggleCaptions() {
      if (this.isCaptionsDropdownShown == true) this.hideCaptions()
      else this.showCaptions()
    },
    showCaptions() {
      if (isMobile(navigator.userAgent, navigator.maxTouchPoints)) return
      clearTimeout(this.captionScrollTimer)
      replaceclass(this.captionHolder as HTMLElement, `hide`, `show`)
      this.isCaptionsDropdownShown = true
    },
    hideCaptions() {
      clearTimeout(this.captionScrollTimer)
      replaceclass(this.captionHolder as HTMLElement, `show`, `hide`)
      this.isCaptionsDropdownShown = false
    }
  }
})
