
import "@/assets/_base.scss"

import VideoPlayer from "../components/shared/VideoPlayer.vue"
import IframeVideoPlayer from "../components/shared/IframeVideoPlayer.vue"
import { SingleVideoInfo } from "../models/SingleVideoInfo"
import { AwEventType } from "../models/AwEventType"
import setThemeData from "../services/setThemeData"
import { GoogleAnalyticsClient } from "@/services/GoogleAnalyticsService"
import { AnalyticsClient } from "@/services/AnalyticsService"
import { WebinarDataService } from "@/services/WebinarDataServices"
import { container } from "tsyringe"
import { computed, defineComponent, ref } from "@vue/runtime-core"
import injectStrict from "@/utils/injectStrict"
import { GlobalDataKey, LoggerKey } from "@/symbols"
import getVideoType from "@/utils/getVideoType"
import { ContentType } from "@/models/ContentType"
import { AwEventStoreV2 } from "@/models/AwEventStoreV2"
import MAGate from "@/components/MA/MAGate.vue"
import getCssVariables from "@/utils/getCssVariables"
import SeoJsonLdComponentVue from "@/components/shared/SeoJsonLdComponent.vue"
import { LightboxVideo } from "@/models/lightbox/LightboxModels"
import TheaterPlayer from "@/components/shared/TheaterPlayer.vue"
import { isIOS } from "@/services/deviceDetection"
import singleSkeleton from "@/components/skeletons/single.vue"

export default defineComponent({
  components: { VideoPlayer, IframeVideoPlayer, MAGate, SeoJsonLdComponentVue, TheaterPlayer, singleSkeleton },
  props: {
    fetchId: { type: String, required: true }
  },
  setup(props) {
    const globalData = injectStrict(GlobalDataKey).value
    const logger = injectStrict(LoggerKey)
    const videoType: ContentType | undefined = getVideoType(props.fetchId)
    const id = ref(-1)
    const adId = ref(-1)
    const videoInfo = ref<SingleVideoInfo | null>(null)
    const loadingmsg = ref("")
    const widgetHeight = computed<number>(() => globalData.height)
    let redirect_url = computed(() => globalData.redirect_data.url)
    let redirectText = computed(() => globalData.redirect_data.text)
    const themeCss = computed(() => getCssVariables(globalData.theme_data))
    const dataService = container.resolve(WebinarDataService)
    const analytics = container.resolve(AnalyticsClient)
    const gaAnalytics = container.resolve(GoogleAnalyticsClient)

    let lightboxVideos = computed<Array<LightboxVideo>>(() => {
      let lgtboxmoments: Array<LightboxVideo> = []
      if (videoInfo.value) {
        lgtboxmoments.push(
          new LightboxVideo(
            videoInfo.value.moment_id ? parseInt(videoInfo.value.moment_id) : -1,
            videoInfo.value.video_urls,
            videoInfo.value.subtitles,
            videoInfo.value.duration,
            videoInfo.value.moment_id ? parseInt(videoInfo.value.moment_id) : -1,
            videoType ?? ContentType.Webinar,
            videoInfo.value.title,
            0
          )
        )
      }
      return lgtboxmoments
    })
    let usetheaterMode = computed(
      () =>
        videoInfo.value &&
        videoInfo.value.use_theater_mode == true &&
        !!window.parmonic_disable_theater_mode == false &&
        !isIOS(navigator.userAgent, navigator.maxTouchPoints)
    )

    return {
      lightboxVideos,
      dataService,
      widgetHeight,
      themeCss,
      globalData,
      id,
      gaAnalytics,
      videoInfo,
      loadingmsg,
      analytics,
      redirect_url,
      logger,
      redirectText,
      videoType,
      adId,
      usetheaterMode
    }
  },
  created() {
    this.dataService.getData(this.fetchId).then(returnValue => {
      this.loadingmsg = "The video could not be loaded."
      if (returnValue == undefined) {
        this.logger!.error(`Parmonic Widget error: Video not found`)
        return
      }
      this.videoInfo = returnValue as SingleVideoInfo
      this.adId = this.videoInfo.ad_id
      this.id = this.videoInfo.id
      setThemeData(this.videoInfo.theme_data, this.globalData)
      var webinarId = this.id > -1 && !(this.videoType == ContentType.Misc) ? this.id : null
      var miscId = this.id > -1 && this.videoType == ContentType.Misc ? this.id : null
      this.analytics.setUp(
        webinarId,
        this.adId > -1 ? this.adId : null,
        miscId,
        null,
        this.globalData.analyticsEventsCaptureEndPoint,
        this.logger
      )
      this.gaAnalytics.setUp(webinarId ?? miscId ?? this.adId, this.logger)

      var eventV2: AwEventStoreV2 = {
        eventType: AwEventType.WidgetViewed,
        type: this.videoType,
        momentId: this.videoInfo.moment_id ? parseInt(this.videoInfo.moment_id) : undefined
      }
      this.analytics.register(eventV2, null)
    })
  }
})
