<template>
  <div
    :class="`option-holder ${isSelected ? 'selected' : ''}`"
    :aria-label="`${translateLabels('Play')}-${title ?? description ?? 'video'}`"
  >
    <div class="poster-img">
      <div class="banner" v-if="isWebinar">Full video</div>
      <div class="duration">
        <svg width="7" height="7" viewBox="0 0 13 14" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path
            d="M0.845047 2.67638C0.845047 1.1242 2.53628 0.16363 3.86942 0.958634L11.1197 5.28229C12.4205 6.05803 12.4205 7.94203 11.1197 8.71778L3.86943 13.0415C2.53629 13.8365 0.845043 12.876 0.845044 11.3238L0.845047 2.67638Z"
            class="pw-play-btn"
          /></svg
        >{{ duration }}
      </div>
      <img draggable="false" :src="poster_url" />
    </div>
    <div class="text-holder">
      <div class="title" v-if="title" v-html="stripHtmlTags(title)"></div>
      <div :class="`description ${title ? '' : 'big'}`" v-if="description" v-html="stripHtmlTags(description)"></div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "@vue/runtime-core"
import { stripHtmlTags } from "@/utils/stripHtmlTags"
import injectStrict from "@/utils/injectStrict"
import { translationKey } from "@/symbols"

export default defineComponent({
  props: {
    isWebinar: { type: Boolean, required: false, default: false },
    isSelected: { type: Boolean, required: false, default: false },
    poster_url: { type: String, required: true },
    title: { type: String, required: false, default: "" },
    description: { type: String, required: false, default: "" },
    duration: { type: String, required: false, default: "00:00" }
  },
  setup() {
    const { translateLabels } = injectStrict(translationKey)
    return { stripHtmlTags, translateLabels }
  }
})
</script>

<style lang="scss" scoped>
.banner {
  position: absolute;
  top: 0px;
  left: -6px;
  font-family: var(--font-family);
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: normal;
  padding: 2px 6px;
  background-color: var(--theme-color);
  color: var(--theme-contrast);
  cursor: pointer;
}
.pw-play-btn {
  fill: var(--theme-color);
}
.poster-img {
  position: relative;
  width: 115px;
  height: fit-content;
  margin-right: 3px;
  flex-shrink: 0;
  flex-grow: 0;
  cursor: pointer;
  img {
    min-width: 115px;
    width: 115px;
    border-radius: 6px;
    cursor: pointer;
  }
}
.option-holder {
  width: calc(95% - 14px);
  position: relative;
  height: fit-content;
  padding: 6px;
  align-self: flex-end;
  /* White */
  background: #ffffff;
  cursor: pointer;

  /* Background grey */
  border: 1px solid #f0f0f0;
  box-shadow: 0px 3.17466px 5.55566px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: row;
  border-radius: 6px;
}
.text-holder {
  flex-grow: 1;
  margin-left: 12.5px;
  cursor: pointer;
}
.title {
  -webkit-box-orient: vertical;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  font-family: var(--font-family);
  font-style: normal;
  font-weight: 500;
  font-size: 0.75em;
  line-height: normal;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: left;
  color: var(--font-color);
  cursor: pointer;
}
.description {
  -webkit-box-orient: vertical;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  font-family: var(--font-family);
  font-style: normal;
  font-weight: 400;
  font-size: 0.625em;
  line-height: normal;
  margin: 0.375em 0px;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: left;
  flex-grow: 1;
  color: var(--font-color);
  cursor: pointer;
}
.description.big {
  -webkit-line-clamp: 4;
}
.duration {
  font-family: -apple-system, BlinkMacSystemFont, avenir next, avenir, segoe ui, helvetica neue, helvetica, Ubuntu, roboto,
    noto, arial, sans-serif;
  font-style: normal;
  font-weight: bold;
  font-size: 12px;
  line-height: normal;

  align-items: center;
  padding: 4px 6px;
  cursor: pointer;

  height: fit-content;
  color: var(--theme-color);
  background: var(--theme-contrast);
  border-radius: 3px;
  position: absolute;
  bottom: 5px;
  right: 2px;
  opacity: 0.8;
  filter: drop-shadow(0.125em 0.125em 0.25em rgba(0, 0, 0, 0.3));
  svg {
    margin-right: 2px;
    display: inline;
    width: 7px;
    height: 7px;
  }
}

.selected {
  background: var(--theme-color-2);

  /* Background grey */
  border: 2px solid var(--theme-color);
}
.selected::before {
  content: "";
  display: block;
  width: 0;
  height: 0;
  position: absolute;

  border-top: 8px solid transparent;
  border-bottom: 8px solid transparent;
  border-right: 8px solid var(--theme-color);
  left: -8px;

  top: 50%;
  transform: translateY(-50%);
}
</style>
