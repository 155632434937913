<template>
  <div class="pw-player-playBtn-holder player-adon" @click="play" v-if="isPosterVisible.value">
    <button type="button" role="button" :aria-label="`${translateLabels('Play')} - ${title}`" v-if="!isTrailer">
      <svg class="shaka-big-play" viewBox="0 0 61 61" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect width="61" height="61" rx="12" class="shaka-play-button-color" fill-opacity="0.8" />
        <path
          class="shaka-play-button-contrast"
          d="M22 21.5763C22 19.5799 24.1634 18.3445 25.8688 19.367L40.752 28.2906C42.416 29.2884 42.416 31.7115 40.752 32.7092L25.8688 41.633C24.1635 42.6555 22 41.4201 22 39.4237L22 21.5763Z"
        />
      </svg>
    </button>
    <button
      type="button"
      role="button"
      :aria-label="`${translateLabels('Watch Trailer')} - ${title}`"
      class="pw-trailer-play-button"
      @click="play"
      v-else
    >
      <svg class="cont-play-arrow" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M8.00001 8.73444C8.00001 7.13218 9.7458 6.14062 11.1219 6.96127L23.132 14.1234C24.4748 14.9241 24.4748 16.8689 23.132 17.6697L11.1219 24.8319C9.7458 25.6526 8 24.661 8 23.0587L8.00001 8.73444Z"
          class="play-arrow"
        />
      </svg>
      <span class="wath-trailer-txt" v-html="`${translateLabels('Watch Trailer')} | ${totalDuration}`"></span>
    </button>
    <div class="parmonic-video-duration" ref="duration" v-html="totalDuration" v-if="totalDuration && !isTrailer"></div>
  </div>
</template>
<script lang="ts">
import { defineComponent, PropType, Ref } from "vue"
import injectStrict from "@/utils/injectStrict"
import { translationKey } from "@/symbols"

export default defineComponent({
  props: {
    play: { type: Function, required: true },
    isPosterVisible: {
      type: Object as PropType<Ref<Boolean>>,
      required: true
    },
    totalDuration: { type: String, required: false },
    isTrailer: { type: Boolean, required: false, default: false },
    title: { type: String, required: true }
  },
  setup() {
    const { translateLabels } = injectStrict(translationKey)
    return { translateLabels }
  }
})
</script>

<style lang="scss" scoped>
button {
  all: unset;
  background: none;
  cursor: pointer;
  padding: 4px;
}

button:active {
  background: none;
}

button:focus {
  outline: 2px solid var(--theme-color);
  outline-offset: 2px;
}

.pw-trailer-play-button {
  background-color: var(--theme-color);
  display: flex;
  opacity: 0.9;
  flex-direction: row;
  align-items: center;
  padding: 8px 24px 8px 16px;
  border-radius: 8px;
  cursor: pointer;
}

.pw-player-playBtn-holder:hover {
  .shaka-big-play rect {
    fill-opacity: 1;
  }
  .shaka-big-play {
    transform: scale(90%);
  }
  .pw-trailer-play-button {
    background-color: var(--theme-color);
    opacity: 1;
  }
}
</style>
