<template>
  <div>
    <div v-if="loading" class="text-center">Loading..</div>
    <div ref="rootEl"></div>
  </div>
</template>

<script lang="ts">
import { defineComponent, defineEmit, onMounted, ref, watch } from "@vue/runtime-core"

export default defineComponent({
  props: {
    value: String
  },
  setup(props, { emit }) {
    const rootEl = ref<HTMLElement>()
    let loading = ref(false)

    let updateHtml = () => {
      if (!rootEl.value) return
      rootEl.value.innerHTML = ""

      let divEls = document.createElement("div")
      divEls.innerHTML = props.value ?? ""
      rootEl.value.append(divEls)

      let scripts = divEls.querySelectorAll("script")
      if (!scripts.length) return

      loading.value = true

      const loadNext = (i: number) => {
        if (i < scripts.length) {
          const el = scripts[i]
          let newScript = document.createElement("script")
          let inlineScript = document.createTextNode(el.innerHTML)
          newScript.appendChild(inlineScript)
          if (el.src) {
            newScript.src = el.src
            newScript.onload = () => loadNext(i + 1)
          }
          newScript.defer = el.defer
          newScript.type = el.type
          rootEl.value?.appendChild(newScript)
          if (!el.src) {
            loadNext(i + 1)
          }
        } else {
          loading.value = false
          emit("onscriptloaded", this)
        }
      }

      loadNext(0)
    }

    onMounted(() => updateHtml())
    watch(() => props.value, updateHtml)

    return { rootEl, loading }
  }
})
</script>
