import { KnownUserData } from "./setupKnownUser"

const SESSIONID = "PARMONIC_SESSION_ID"
export const KNOWN_USER = "PARMONIC_KNOWN_USER"

export class LocalStorageService {
  public setSessionId(id: string) {
    localStorage[SESSIONID] = id
  }

  public getSessionId(): string | null {
    return localStorage[SESSIONID]
  }

  public getKnownUser(): KnownUserData | undefined {
    let json = localStorage[KNOWN_USER]
    if (json) return JSON.parse(json) as KnownUserData
  }

  public setKnownUser(user: KnownUserData | undefined) {
    localStorage[KNOWN_USER] = user ? JSON.stringify(user) : undefined
  }
}
