<template>
  <div class="m-r align-self-center row">
    <MomentSkeleon :loadingmsg="loadingmsg" v-for="i in 5" :key="i" />
    <div class="loading-video main-video-skeleton">
      <svg class="loading-svg-icon shaka-big-play" viewBox="0 0 61 61" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect width="61" height="61" rx="12" fill="#ccc" />
        <path
          fill="#fff"
          d="M22 21.5763C22 19.5799 24.1634 18.3445 25.8688 19.367L40.752 28.2906C42.416 29.2884 42.416 31.7115 40.752 32.7092L25.8688 41.633C24.1635 42.6555 22 41.4201 22 39.4237L22 21.5763Z"
        />
      </svg>
      <div class="loading-message">{{ loadingmsg }}</div>
    </div>
  </div>
</template>
<style scoped>
@import "../../assets/moment-focus.css";
@import "../../assets/grid.css";
@import "../../assets/vendor/bootatrap-grid.css";
@import "../../assets/skeleton.css";
.skeleton-moment {
  padding: 10px;
}
</style>
<script lang="ts">
import { defineComponent } from "@vue/runtime-core"
import MomentSkeleon from "./moment.vue"
export default defineComponent({
  props: {
    loadingmsg: { type: String, required: false, default: "" }
  },
  components: { MomentSkeleon }
})
</script>
