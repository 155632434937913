<template>
  <div class="demo-overlay" @click="play" v-if="useDemoOverlay"></div>
</template>
<script lang="ts">
import { defineComponent } from "vue"

export default defineComponent({
  props: {
    useDemoOverlay: { type: Boolean, required: true }
  }
})
</script>

<style scoped>
.demo-overlay {
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 4;

  background-image: url("https://js.static.parmonic.ai/static/demo-overlay.png");
  background-repeat: no-repeat;
  background-size: contain;
  background-position-x: 0;
  opacity: 0.8;

  width: 100%;
  height: 100%;

  max-height: 35%;
  max-width: 50%;
}
</style>
