export default {
  "Full Video": "Ganzes Video",
  "Related Resources": "Verwandte Ressourcen",
  Blog: "Blog",
  Play: "Spielen",
  "No Captions": "Keine Untertitel",
  Captions: "Untertitel",
  "Toggle fullscreen": "Vollbild umschalten",
  "playback speed": "Wiedergabegeschwindigkeit",
  "Play with captions in": "Mit Untertiteln abspielen in",
  Seek: "Springen",
  "Watch Trailer": "Trailer ansehen",
  "Watch full video": "Vollständiges Video ansehen",
  "Watch Video": "Video ansehen",
  Speakers: "Lautsprecher",
  Infographic: "Infografik",
  eBook: "eBook",
  Webinar: "Webinar",
  Video: "Video",
  "White Paper": "Weißes Papier",
  Slides: "Folien",
  Article: "Artikel",
  "Icon not found exception": "Icon-NotFoundException",
  "Key Moments": "Schlüsselmomente",
  Resources: "Ressourcen"
}
