
import { defineComponent } from "@vue/runtime-core"
import { stripHtmlTags } from "@/utils/stripHtmlTags"
import injectStrict from "@/utils/injectStrict"
import { translationKey } from "@/symbols"

export default defineComponent({
  props: {
    isWebinar: { type: Boolean, required: false, default: false },
    isSelected: { type: Boolean, required: false, default: false },
    poster_url: { type: String, required: true },
    title: { type: String, required: false, default: "" },
    description: { type: String, required: false, default: "" },
    duration: { type: String, required: false, default: "00:00" }
  },
  setup() {
    const { translateLabels } = injectStrict(translationKey)
    return { stripHtmlTags, translateLabels }
  }
})
