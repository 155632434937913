export default {
  "Full Video": "Полное видео",
  "Related Resources": "Relaterade Resurser",
  Blog: "Blogg",
  Play: "Spela",
  "No Captions": "Inga Undertexter",
  Captions: "Undertexter",
  "Toggle fullscreen": "Växla helskärm",
  "playback speed": "Uppspelningshastighet",
  "Play with captions in": "Spela med undertexter på",
  Seek: "Sök",
  "Watch Trailer": "Se trailer",
  "Watch full video": "Se hela videon",
  "Watch Video": "Titta på video",
  Speakers: "Högtalare",
  Infographic: "Infografik",
  eBook: "eBook",
  Webinar: "Webbinarium",
  Video: "Video",
  "White Paper": "Vitt papper",
  Slides: "Dias",
  Article: "Artikel",
  "Icon not found exception": "Ikonen hittades inte undantag",
  "Key Moments": "Viktiga Ögonblick",
  Resources: "Resurser"
}
