import axios, { AxiosRequestConfig } from "axios"
import { singleton, inject } from "tsyringe"
import isPreview from "./isPreview"
export interface IWebinarDataService {
  setUp(publishWebinarDataEndPoint: string, queryEndpoint: string, data: object): void
  getData(filename: string): Promise<unknown>
}
export class ApiDataService implements IWebinarDataService {
  private PublishedWebinarDataEndPoint = ""
  private queryEndpoint = ""
  setUp(publishWebinarDataEndPoint: string, queryEndpoint: string = "", data: object = {}) {
    this.PublishedWebinarDataEndPoint = publishWebinarDataEndPoint
    this.queryEndpoint = queryEndpoint
  }
  async getData(filename: string) {
    if (!filename)
      return new Promise(function (resolve, reject) {
        return resolve(undefined)
      })
    const url = !this.queryEndpoint
      ? `${this.PublishedWebinarDataEndPoint}${filename}.json`
      : `${this.PublishedWebinarDataEndPoint}${filename}.json${this.queryEndpoint}`
    if (isPreview()) {
      axios.defaults.headers = {
        "Cache-Control": "no-cache",
        Pragma: "no-cache",
        Expires: "0"
      }
    }
    return await axios
      .get(url)
      .then(async function (response) {
        return response.data
      })
      .catch(function (error) {
        return undefined
      })
  }
}
export class OptionDataService implements IWebinarDataService {
  private data = {}
  setUp(publishWebinarDataEndPoint: string, queryEndpoint: string = "", data: object) {
    this.data = data
  }
  async getData(filename: string) {
    const self = this
    return new Promise(function (resolve, reject) {
      return resolve(self.data)
    })
  }
}
@singleton()
export class WebinarDataService {
  private _client: IWebinarDataService
  constructor(@inject("WebinarDataService") public client: IWebinarDataService) {
    this._client = client
  }
  public setUp(publishWebinarDataEndPoint: string, queryEndpoint: string = "", data: object) {
    this._client.setUp(publishWebinarDataEndPoint, queryEndpoint, data)
  }
  public async getData(filename: string = ""): Promise<unknown> {
    return await this._client.getData(filename)
  }
}
