import IDocument from "@/interfaces/IDoccument"
import IElement from "@/interfaces/IElement"

const doc: IDocument = document
export function toggleFullScreen(elm: HTMLElement) {
  const element: IElement = elm
  if (doc.fullscreenElement || doc.webkitFullscreenElement || doc.mozFullScreenElement || doc.msFullscreenElement) {
    exitFullScreen()
  } else {
    enterFullScreen(element)
  }
}
export function exitFullScreen() {
  if (doc.fullscreenElement || doc.webkitFullscreenElement || doc.mozFullScreenElement || doc.msFullscreenElement) {
    if (doc.exitFullscreen) {
      doc.exitFullscreen()
    } else if (doc.mozCancelFullScreen) {
      doc.mozCancelFullScreen()
    } else if (doc.webkitExitFullscreen) {
      doc.webkitExitFullscreen()
    } else if (doc.msExitFullscreen) {
      doc.msExitFullscreen()
    }
  }
}
export function enterFullScreen(element: IElement) {
  if (element.requestFullscreen) {
    element.requestFullscreen()
  } else if (element.mozRequestFullScreen) {
    element.mozRequestFullScreen()
  } else if (element.webkitRequestFullscreen) {
    element.webkitRequestFullscreen()
  } else if (element.msRequestFullscreen) {
    element.msRequestFullscreen()
  }
}
export function isFullScreenAvailable(): boolean {
  return (doc.fullscreenEnabled || doc.webkitFullscreenEnabled || doc.msFullscreenEnabled) ?? false
}
