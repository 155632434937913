<template>
  <div>
    <div id="parmonic-moments" ref="momentContainer">
      <div class="timeline-line"></div>
      <div class="moment-holder" v-for="i in 5" :key="i">
        <div class="video-icon">
          <div class="icon">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 293.11 293.1">
              <g>
                <circle class="icon-video-color-1" cx="146.55" cy="146.55" r="139.55" />
                <path
                  class="icon-video-color-2"
                  d="M146.55,293.1C65.74,293.1,0,227.36,0,146.55S65.74,0,146.55,0s146.55,65.74,146.55,146.55-65.74,146.55-146.55,146.55Zm0-279.1C73.46,14,14,73.46,14,146.55s59.46,132.55,132.55,132.55,132.55-59.46,132.55-132.55S219.64,14,146.55,14Z"
                />
              </g>
              <rect class="icon-video-color-2" x="85.2" y="103.77" width="83.72" height="83.72" rx="11.5" ry="11.5" />
              <path
                class="icon-video-color-2"
                d="M174.85,129.54v32.87l24.09,17.93c.25,.11,4.53,1.91,8.4-.75,3.54-2.42,3.72-6.49,3.74-6.91v-55.47c-.08-.54-.72-4.39-4.3-6.54-2.82-1.69-6.4-1.68-9.34,0-7.53,6.29-15.07,12.58-22.6,18.86Z"
              />
            </svg>
          </div>
        </div>

        <div class="momemt-box">
          <div class="video-holder">
            <div class="rnd-crns amplily-player">
              <div class="loading-video">
                <svg
                  class="loading-svg-icon loading-svg-icon-small shaka-big-play"
                  viewBox="0 0 61 61"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <rect width="61" height="61" rx="12" fill="#ccc" />
                  <path
                    fill="#fff"
                    d="M22 21.5763C22 19.5799 24.1634 18.3445 25.8688 19.367L40.752 28.2906C42.416 29.2884 42.416 31.7115 40.752 32.7092L25.8688 41.633C24.1635 42.6555 22 41.4201 22 39.4237L22 21.5763Z"
                  />
                </svg>
                <div class="loading-message">{{ loadingmsg }}</div>
              </div>
            </div>
          </div>
          <div class="info">
            <div class="skeleton-title skeleton"></div>
            <div class="skeleton-paragraph skeleton"></div>
            <div class="skeleton-paragraph skeleton"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<style lang="scss" scoped>
@import "../../assets/skeleton.css";
@media (max-width: 500px) {
  #parmonic-moments {
    .timeline-line {
      display: none;
    }
  }
  #parmonic-moments .moment-holder {
    .video-icon {
      display: none;
    }
    .momemt-box {
      display: block;
      padding: 10px;
      width: 100%;
      .video-holder {
        width: 100%;
      }
      .info {
        width: 100%;
        padding: 0px 5px;
      }
    }
  }
}
#parmonic-moments {
  width: 100%;
  margin-top: 50px;
  position: relative;
  .timeline-line {
    position: absolute;
    height: calc(100% - var(--reseller-moment-height));
    left: 20px;
    width: 5px;
    background-color: #eee;
    top: calc(var(--reseller-moment-height) / 2);
    border-radius: 20px;
    min-height: 100%;
  }
}
.moment-holder:nth-child(odd) {
  .momemt-box {
    flex-direction: row-reverse;
    .info {
      padding-left: 0px;
      padding-right: 20px;
    }
  }
}
.moment-holder:nth-child(even) {
  .momemt-box {
    flex-direction: row;
    .info {
      padding-left: 20px;
      padding-right: 0px;
    }
  }
}
.moment-holder {
  display: flex;
  margin-bottom: 40px;
  .video-icon {
    width: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    margin-right: 20px;
    .icon {
      width: 44px;
      height: 44px;
      border-radius: 50px;
      .icon-video-color-1 {
        fill: var(--theme-contrast);
      }
      .icon-video-color-2 {
        fill: #eee;
      }
      &::after {
        position: absolute;
        right: 0;
        transform: translateX(100%) translateY(-50%);
        top: 50%;
        content: "";
        margin-left: auto;
        margin-right: 0px;
        padding: 0;
        border-top: 20px solid transparent;
        border-bottom: 20px solid transparent;
        border-right: 20px solid #eeeeee5c;
      }
    }
  }
  .momemt-box {
    width: calc(100% - 70px);
    display: flex;
    background-color: #eeeeee5c;
    border-radius: 4px;
    padding: 40px;
    line-height: initial; /* A hack... TODO: try to not set a line height everywhere, so that it larger font sizes do not look squished */
    .video-holder {
      width: 50%;
    }

    .info {
      width: 50%;

      h3 {
        padding: 0px;
        font-size: 1.625em;
        line-height: normal;
        font-family: var(--font-family);
        color: var(--font-color);
      }
      p {
        font-size: 1.25em;
        line-height: normal;
        font-family: var(--font-family);
        color: var(--font-color);
        margin: 0.5em 0px;
      }
    }
  }
}
</style>
<script lang="ts">
import { defineComponent } from "@vue/runtime-core"
export default defineComponent({
  props: {
    loadingmsg: { type: String, required: false, default: "" }
  }
})
</script>
