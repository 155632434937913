function addPlayer(el: HTMLVideoElement | undefined) {
  if (!el) return
  setArray()
  ;(window as any).videoPlayers.push(el)
}
function pauseAll(currentVideo: HTMLVideoElement | undefined) {
  if (!currentVideo) return
  setArray()
  ;(window as any).videoPlayers.forEach((video: HTMLVideoElement) => {
    if (currentVideo !== video) {
      video.pause()
    }
  })
}
function setArray() {
  ;(window as any).videoPlayers = (window as any).videoPlayers ?? []
  return (window as any).videoPlayers
}

export default function setUp() {
  return { addPlayer, pauseAll }
}
