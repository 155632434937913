<template>
  <div class="loading player-adon">
    <div class="loader">
      <div class="loading-text" v-html="loading_text"></div>
      <br />
      <span v-if="isLoadingFullVideo">Fetching the full video. Please wait...</span>
    </div>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, ref } from "@vue/runtime-core"

export default defineComponent({
  props: {
    isLoadingFullVideo: { type: Boolean, required: false, default: false }
  },
  setup() {
    var count = ref<number>(0)
    var loading_text = computed(() => {
      var str = "Loading"
      if (count.value > 2) count.value = 0
      for (var i = 0; i < count.value + 1; i++) {
        str += " ."
      }
      return str
    })
    let timer = 0
    return { count, loading_text, timer }
  },
  mounted() {
    this.timer = setInterval(() => {
      this.count++
    }, 400)
  },
  beforeUnmount() {
    clearInterval(this.timer)
  }
})
</script>

<style lang="scss" scoped>
.loading {
  pointer-events: none;
  background-color: rgba(0, 0, 0, 0.329);
  z-index: 1;
  color: white;
  font-family: -apple-system, BlinkMacSystemFont, avenir next, avenir, segoe ui, helvetica neue, helvetica, Ubuntu, roboto,
    noto, arial, sans-serif;
  font-style: normal;
  font-weight: normal;
  text-align: center;
  font-size: 14px;
  line-height: normal;
  .loader {
    margin: auto;
    margin-top: 25%;
  }
  .loading-text {
    width: 100%;
    text-align: center;
    font-weight: bold;
    font-size: 16px;
    line-height: normal;
  }
}
</style>
