<template>
  <div class="aw-pardot-form">
    <div class="aw-form" :style="formVars" ref="root">
      <ScriptComponent :value="config?.embedCode" />
    </div>
  </div>
</template>

<script lang="ts">
import { computed, defineComponent, onUnmounted, PropType, ref } from "vue"
import ScriptComponent from "./ScriptComponent.vue"
import { MAData } from "@/models/ma/MAData"

export default defineComponent({
  components: { ScriptComponent },
  props: {
    config: Object as PropType<MAData>
  },
  setup: function (props, { emit }) {
    let root = ref<HTMLElement>()
    let iframeHeight = ref(150)

    let formVars = computed(() => {
      return {
        "--aw-form-iframe-height": iframeHeight.value + "px"
      } as any
    })

    let scriptLoaded = (context: any) => {
      iframeHeight.value = context.clientHeight
    }

    window.addEventListener("message", messageReceived)
    onUnmounted(() => window.removeEventListener("message", messageReceived))

    interface iframeContext {
      type: "iframe-changed" | undefined
      iframeHeight: number | undefined
    }

    type iframeMessage = "form-submitted" | iframeContext

    function messageReceived(ev: MessageEvent<iframeMessage>) {
      if (ev.data == "form-submitted") emit("formsubmitted", ev.data)
      else if (ev.data?.type == "iframe-changed" && ev.data?.iframeHeight) {
        iframeHeight.value = ev.data.iframeHeight
      }
    }

    return {
      root,
      scriptLoaded,
      formVars
    }
  }
})
</script>

<style lang="scss">
.aw-pardot-form {
  .aw-form {
    font-family: var(--font-family);
    font-size: 1em;
    line-height: normal;
  }

  .aw-pardot-form iframe {
    width: 100%;
    height: var(--aw-form-iframe-height);
  }
}
</style>
