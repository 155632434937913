import { formatDatefromSeconds } from "@/services/DurationFormatter"
import { ref, computed } from "vue"
export default function setUpDuration(duration: number) {
  let dur = ref(duration)
  let currentTime = ref(0)
  let durationString = computed(() => {
    return `${formatDatefromSeconds(currentTime.value)}`
  })
  let totalDurationString = computed(() => formatDatefromSeconds(dur.value))

  return {
    dur,
    currentTime,
    durationString,
    totalDurationString
  }
}
