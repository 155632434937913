export default function getMomentId(url: string) {
  const query = url.split("?")[1]
  const vars = query.split("&")
  let id = ""
  for (let i = 0; i < vars.length; i++) {
    const pair = vars[i].split("=")
    if (pair[0] == "moment") {
      id = pair[1]
    }
  }
  return id
}

export function GetQueryParameter(key: string): string | null {
  const queryString = window.location.search
  const urlParams = new URLSearchParams(queryString)
  var val = urlParams.get(key)
  if (val) return val
  return null
}

export function SetQueryParameter(key: string, value: string, removeParams: string[] | null) {
  var url = new URL(window.location.href)
  url.searchParams.delete(key)
  if (removeParams) {
    removeParams.forEach(k => {
      url.searchParams.delete(k)
    })
  }
  url.searchParams.append(key, value)
  window.history.pushState(null, document.title, url)
}
export function RemoveQueryParameter(keys: string[]) {
  var url = new URL(window.location.href)
  keys.forEach(key => {
    url.searchParams.delete(key)
  })

  window.history.pushState(null, document.title, url)
}
