export default {
  "Full Video": "الفيديو كامل",
  "Related Resources": "الموارد ذات الصلة",
  Blog: "مدوّنة",
  Play: "لعب",
  "No Captions": "لا توجد تسميات",
  Captions: "تسميات",
  "Toggle fullscreen": "تبديل وضع ملء الشاشة",
  "playback speed": "سرعة التشغيل",
  "Play with captions in": "تشغيل مع تسميات في",
  Seek: "ابحث",
  "Watch Trailer": "شاهد الإعلان",
  "Watch full video": "شاهد الفيديو الكامل",
  "Watch Video": "شاهد الفيديو",
  Speakers: "السماعات",
  Infographic: "إنفوجرافيك",
  eBook: "كتاب إلكتروني",
  Webinar: "ندوة عبر الإنترنت",
  Video: "فيديو",
  "White Paper": "ورقة بيضاء",
  Slides: "شرائح",
  Article: "مقالة",
  "Icon not found exception": "استثناء عدم العثور على الرمز",
  "Key Moments": "لحظات مفتاحية",
  Resources: "موارد"
}
