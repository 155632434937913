
import { computed, defineComponent, PropType, ref, Ref } from "vue"
import injectStrict from "@/utils/injectStrict"
import { translationKey } from "@/symbols"
import { container } from "tsyringe"
import { AnalyticsClient } from "@/services/AnalyticsService"
import { AwEventType } from "@/models/AwEventType"
import { RelatedResourceData } from "@/models/RelatedResourceData"

export default defineComponent({
  props: {
    cta: { type: Object as PropType<RelatedResourceData>, required: false, default: null },
    isPosterVisible: { type: Object as PropType<Ref<Boolean>>, required: true },
    dur: { type: Object as PropType<Ref<number>>, required: true },
    continuefromTime: { type: Number, default: 0, required: false },
    hasEnded: { type: Object as PropType<Ref<Boolean>>, required: true },
    currentTime: { type: Object as PropType<Ref<number>>, required: true }
  },
  setup(props) {
    const analytics = container.resolve(AnalyticsClient)
    const mouseHover = ref(false)
    const isCTACollapse = computed(() => {
      return (
        (props.currentTime.value > 5 && props.currentTime.value < 60) ||
        (props.dur.value - props.currentTime.value <= 5 && props.dur.value - props.currentTime.value > 0) ||
        (props.dur.value - props.currentTime.value <= 0 && props.continuefromTime > 0) ||
        mouseHover.value
      )
    })

    let handleClick = (res: RelatedResourceData | undefined | null) => {
      window.parmonicFocusedVideo.pause()
      if (res?.id) analytics.register({ eventType: AwEventType.ResourceClicked, relatedResourceId: res?.id }, null)
    }

    let showEndedPage = computed(() => {
      return !props.continuefromTime && props.hasEnded.value
    })

    const { translateResource } = injectStrict(translationKey)
    return {
      cta: props.cta,
      isCTACollapse,
      handleClick,
      showEndedPage,
      mouseHover,
      translateResource
    }
  },
  mounted() {}
})
