import { ref, computed } from "vue"
import { MomentInfo } from "@/models/MomentInfo"
import { WebinarInfo } from "@/models/WebinarInfo"
import { RelatedResourceData } from "@/models/RelatedResourceData"
import { SpeakerInfo } from "@/models/SpeakerInfo"
import { Translation } from "@/models/translations"
import LocalizationResources from "@/localization/localization-resource-index"
const language = ref(getDefaultBrowserLanguage())

function getDefaultBrowserLanguage() {
  const language = navigator.language
  return language.split("-")[0]
}

const internationalization: () => InternationalizationInterface = () => {
  window.addEventListener("languagechange", (event: any) => {
    const { lang } = event?.detail
    if (lang) setLang(lang)
  })
  const setLang = (lang: string) => {
    language.value = lang
  }
  const translateMoment = (momentData: MomentInfo) => {
    return translate(momentData, ["title", "description"])
  }
  const translateWebinar = (webinarData: WebinarInfo) => {
    return translate(webinarData, ["title", "description"])
  }
  const translateResource = (resource: RelatedResourceData) => {
    return translate(resource, ["title"])
  }
  const translateSpeaker = (speaker: SpeakerInfo) => {
    return translate(speaker, ["title", "name", "description"])
  }
  const translateTrailerBox = (data: any) => {
    return translate(data, ["title"])
  }
  const translateLabels = (labelIndex: string) => {
    //return LoLalizationResources[language.value]?[labelIndex] ?? labelIndex
    const localLanguage = LocalizationResources[language.value]
    if (localLanguage) return localLanguage[labelIndex] ?? labelIndex
    return labelIndex
  }

  const translate = (data: Record<string, any>, fields: string[]): Record<string, any> => {
    const translatedLanguage =
      computed(() => {
        return data.translations?.find((t: Translation) => t.language_code === language.value)
      }).value ?? data

    const formatLanguage: Record<string, any> = {}
    fields.forEach((field: string) => {
      formatLanguage[field] = translatedLanguage[field] ?? data[field]
    })

    return formatLanguage
  }

  return {
    language,
    setLang,
    translateMoment,
    translateWebinar,
    translateResource,
    translateSpeaker,
    translateTrailerBox,
    translateLabels
  }
}

export interface InternationalizationInterface {
  language: any
  setLang: (lang: string) => void
  translateMoment: (momentData: MomentInfo) => Record<string, any>
  translateWebinar: (webinarData: WebinarInfo) => Record<string, any>
  translateResource: (resource: RelatedResourceData) => Record<string, any>
  translateSpeaker: (speaker: SpeakerInfo) => Record<string, any>
  translateTrailerBox: (data: any) => Record<string, any>
  translateLabels: (labelIndex: string) => string | undefined
}

export default internationalization
