<template>
  <div id="aw-fw">
    <h3 v-if="showHead" v-html="translateLabels('Full Video')"></h3>
    <div class="rnd-crns">
      <VideoPlayer
        v-if="!fullvideo.iframe_url || fullvideo.iframe_url == ''"
        :video_urls="fullvideo"
        :duration="duration"
        :subtitleUrls="subtitleUrls"
        :subtitles="subtitles"
        :videoType="ContentType.Webinar"
        :useDemoOverlay="useDemoOverlay"
        :title="title"
        :logoOverlay="logoOverlay"
        :isgated="isgated"
        :ma="ma"
        :usetheaterMode="usetheaterMode"
        :cta="cta"
        class="full-video-player"
      />
      <IframeVideoPlayer v-else :iframe_url="fullvideo.iframe_url" :poster="fullvideo.poster_url" :duration="duration" />
    </div>
  </div>
</template>
<style lang="scss" scoped>
@import "../../assets/fullVideoComponent.css";
</style>
<script lang="ts">
import { VideoInfo } from "@/models/VideoInfo"
import VideoPlayer from "@/components/shared/VideoPlayer.vue"
import IframeVideoPlayer from "@/components/shared/IframeVideoPlayer.vue"
import { SubtitleUrls } from "@/models/captions/SubtitleUrls"
import { translationKey } from "@/symbols"
import injectStrict from "@/utils/injectStrict"
import { defineComponent, PropType } from "vue"
import { ContentType } from "@/models/ContentType"
import LogoOverlayData from "@/models/LogoOverlayData"
import { MAData } from "@/models/ma/MAData"
import { RelatedResourceData } from "@/models/RelatedResourceData"

export default defineComponent({
  components: { VideoPlayer, IframeVideoPlayer },
  props: {
    fullvideo: Object as PropType<VideoInfo>,
    showHead: Boolean,
    duration: String,
    subtitleUrls: Object as PropType<SubtitleUrls>,
    subtitles: Array as PropType<Array<SubtitleUrls>>,
    useDemoOverlay: { type: Boolean, required: false, default: false },
    title: { type: String, required: true },
    logoOverlay: { type: Object as PropType<LogoOverlayData>, required: false, default: null },
    isgated: Boolean,
    ma: Object as PropType<MAData>,
    cta: { type: Object as PropType<RelatedResourceData>, required: false },
    usetheaterMode: { type: Boolean, required: false, default: false }
  },
  setup() {
    const { translateLabels } = injectStrict(translationKey)
    return { ContentType, translateLabels }
  }
})
</script>
