import { ContentType } from "@/models/ContentType"
import { SubtitleUrls } from "@/models/captions/SubtitleUrls"
import { VideoInfo } from "@/models/VideoInfo"
export class LightboxEventBusModal {
  instanceId: string | undefined
  identifier: number
  language: string | null | undefined
  autoplay: boolean
  contentType: string
  public constructor(
    instanceId: string | undefined,
    identifier: number,
    language: string | null | undefined,
    contentType: string,
    autoplay?: boolean
  ) {
    this.instanceId = instanceId
    this.identifier = identifier
    this.language = language
    this.autoplay = autoplay ?? true
    this.contentType = contentType
  }
}
export class LightboxVideo {
  identifier: number
  video_urls: VideoInfo
  subitleUrls: SubtitleUrls[]
  mid: number | null
  duration: string
  type: string
  title: string
  continuefromTime: number | null
  public constructor(
    id: number,
    video_urls: VideoInfo,
    subitleUrls: SubtitleUrls[],
    duration: string,
    mid: number | null,
    type: string,
    title: string,
    continuefromTime: number | null
  ) {
    this.identifier = id
    this.video_urls = video_urls
    this.subitleUrls = subitleUrls
    this.mid = mid
    this.duration = duration
    this.title = title
    this.type = type
    this.continuefromTime = continuefromTime
  }
}
