<template>
  <div :class="containerClassList" ref="rootElm" :style="{ minHeight: height + '!important' }">
    <iframe
      ref="frame"
      v-if="!isPosterVisible"
      :src="`${iframe_url}`"
      frameborder="0"
      allow="accelerometer; autoplay; encrypted-media; gyroscope"
      allowfullscreen
      :height="height"
      :style="{ height: 'auto !important' }"
    ></iframe>
    <PlayButtonComponent :isPosterVisible="getisPosterVisible()" :play="clickPoster" :totalDuration="dur" />
    <div class="pw-player-poster player-adon" @click="clickPoster()" v-if="isPosterVisible">
      <img :src="poster" alt="" srcset="" />
    </div>
  </div>
</template>
<script lang="ts">
import { computed, defineComponent, ref } from "vue"
import { formatDate } from "@/services/DurationFormatter"
import injectStrict from "@/utils/injectStrict"
import { EventBusKey, GlobalDataKey } from "@/symbols"
import PlayButtonComponent from "@/components/VideoPlayerComponents/PlayButtonComponent.vue"
export default defineComponent({
  components: { PlayButtonComponent },
  props: {
    iframe_url: String,
    poster: String,
    duration: String,
    isgated: Boolean,
    mid: Number
  },
  setup(props) {
    const dur = props.duration == undefined ? "00:00" : formatDate(props.duration)
    const globalData = injectStrict(GlobalDataKey)
    let eventbus = injectStrict(EventBusKey)
    let height = ref("100%")
    let isPosterVisible = ref(true)
    const rootElm = ref<HTMLElement>()
    let getisPosterVisible = () => isPosterVisible
    let width = ref(rootElm.value ? rootElm.value.clientWidth : 0)
    let containerClassList = computed(() => {
      return {
        small: width.value < 300,
        normal: width.value < 700 && width.value > 299,
        "aw-e": true
      }
    })
    return {
      containerClassList,
      width,
      getisPosterVisible,
      globalData,
      isPosterVisible,
      rootElm,
      dur,
      height,
      eventbus
    }
  },
  mounted() {
    this.init()
    this.eventbus.on("carouselSetupCompleted", this.init)
    this.handleResize()
    window.addEventListener("resize", this.handleResize)
  },
  methods: {
    init() {
      this.height = `${(this.rootElm?.clientWidth ?? 0) / 1.77}px`
    },
    clickPoster() {
      if (this.isgated) {
        this.clickGate()
      } else {
        this.eventbus.emit("playing-iframe-Video", this.mid)
        this.isPosterVisible = false
      }
    },
    clickGate() {
      this.eventbus.emit("gate-event")
    },
    handleResize() {
      this.width = this.rootElm ? this.rootElm.clientWidth : 0
    }
  }
})
</script>
<style scoped>
@import "../../assets/video/shaka.css";
@import "../../assets/video/player-poster.css";
@import "../../assets/video/shaka-play-button.css";
.aw-e {
  position: relative;
  top: 0;
  left: 0;
  width: 100%;
  display: flex;
}
.aw-e > iframe {
  width: 100%;
  height: 100%;
}
.aw-vp {
  width: 100%;
  height: 100%;
  top: 0px;
  left: 0px;
  position: absolute;
  z-index: 5;
  display: flex;
}
.aw-bh {
  height: 100%;
  width: 100%;
  z-index: 6;
  display: flex;
  align-items: center;
}
.aw-bh > button {
  display: flex;
  align-items: center;
  align-self: center;
  margin-left: calc(50% - 31.5px);
}
</style>
