
import { VideoInfo } from "@/models/VideoInfo"
import isVideoUrlsValid from "@/utils/VideoUrlsValidator"
import { defineComponent, PropType, Ref } from "vue"

export default defineComponent({
  props: {
    videoUrls: {
      type: Object as PropType<Ref<VideoInfo>>,
      required: true
    },
    videoPlaybackError: { type: Object as PropType<Ref<boolean>>, required: true, defult: false }
  },
  setup(props) {
    props.videoPlaybackError.value = !isVideoUrlsValid(props.videoUrls.value, undefined)
  }
})
