
import VideoPlayer from "@/components/shared/VideoPlayer.vue"
import IframePlayer from "@/components/shared/IframeVideoPlayer.vue"
import { defineComponent, PropType, ref } from "@vue/runtime-core"
import injectStrict from "@/utils/injectStrict"
import { GlobalDataKey, translationKey } from "@/symbols"
import { stripHtmlTags } from "@/utils/stripHtmlTags"
import { MomentInfo } from "@/models/MomentInfo"
import LogoOverlayData from "@/models/LogoOverlayData"
import { MAData } from "@/models/ma/MAData"
import { ContentType } from "@/models/ContentType"
import { ShareButtons } from "@/models/ShareButtons"
import SocialShare from "@/components/shared/SocialShare.vue"
import { setupSocialShare } from "@/composables/social-share"
import { RelatedResourceData } from "@/models/RelatedResourceData"

export default defineComponent({
  components: { VideoPlayer, IframePlayer, SocialShare },
  props: {
    moment: { type: Object as PropType<MomentInfo>, required: true },
    usetheaterMode: { type: Boolean, required: false, default: false },
    logoOverlay: { type: Object as PropType<LogoOverlayData>, required: false, default: null },
    isgated: Boolean,
    ma: { type: Object as PropType<MAData>, required: false, default: null },
    cta: { type: Object as PropType<RelatedResourceData>, default: null },
    useDemoOverlay: { type: Boolean, required: false, default: false },
    post_play: { type: String, required: false, default: undefined },
    share_buttons: { type: Object as PropType<ShareButtons | null>, required: false, default: null }
  },
  setup() {
    const globalData = injectStrict(GlobalDataKey).value
    let socialShareUrl = ref(globalData.socialShareUrl)
    const { registerSocialShare } = setupSocialShare()
    const { translateMoment, translateLabels } = injectStrict(translationKey)
    return { stripHtmlTags, ContentType, translateMoment, translateLabels }
  },
  methods: {
    focus() {
      const elm = this.$refs.tile as HTMLDivElement
      elm.scrollIntoView({ behavior: "smooth", block: "center", inline: "center" })
    }
  }
})
