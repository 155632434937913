<template>
  <div id="aw--c" :style="themeCss">
    <div id="aw-sl" style="font-size: 1em !important">
      <SeoJsonLdComponentVue :webinarInfo="webinarInfo" v-if="webinarInfo != undefined" />
      <TranslationDropdown :languages="webinarInfo.translation_language_codes" v-if="webinarInfo != undefined" />
      <TheaterPlayer
        :videos="lightboxVideos"
        :logoOverlay="webinarInfo.overlay_logo"
        :useDemoOverlay="webinarInfo.use_demo_overlay"
        :themeCss="themeCss"
        :ma="webinarInfo.ma"
        :cta="webinarInfo.cta"
        :isGated="webinarInfo.is_gated"
        :post_play="webinarInfo.post_play"
        v-if="webinarInfo && lightboxVideos && lightboxVideos.length > 0 && usetheaterMode == true"
      />
      <div v-if="webinarInfo != undefined" :key="webinarInfo.video_urls.dash_url">
        <MAGate :ma="webinarInfo.ma" :themeCss="themeCss" />
        <TitleTrailerCompoent
          :widgetData="webinarInfo"
          :usetheaterMode="usetheaterMode"
          v-if="webinarInfo.is_info_tab_visible == true"
        />
        <PlaylistLayout :webinarInfo="webinarInfo" class="forWide" :usetheaterMode="usetheaterMode" />
        <div class="forNarrow">
          <div v-if="webinarInfo.is_full_video_visible == true && webinarInfo.is_full_video_on_top == true">
            <h3 class="full-video-label">Full Video</h3>
            <div class="rnd-crns margin-bottom">
              <VideoPlayer
                :poster="webinarInfo.video_urls.poster_url"
                :video_urls="webinarInfo.video_urls"
                :subtitleUrls="webinarInfo.subtitle_urls"
                :subtitles="webinarInfo.subtitles"
                :duration="webinarInfo.duration"
                :videoType="ContentType.Webinar"
                :title="translateWebinar(webinarInfo).title"
                :useDemoOverlay="webinarInfo.use_demo_overlay"
                v-if="!webinarInfo.video_urls.iframe_url || webinarInfo.video_urls.iframe_url == ''"
                :logoOverlay="webinarInfo.overlay_logo"
                :isgated="webinarInfo.is_gated"
                :ma="webinarInfo.ma"
                :usetheaterMode="usetheaterMode"
                :cta="webinarInfo.cta"
                :post_play="webinarInfo.post_play"
              />
              <IframeVideoPlayer
                v-else
                :iframe_url="webinarInfo.video_urls.iframe_url"
                :poster="webinarInfo.video_urls.poster_url"
                :duration="webinarInfo.duration"
              />
            </div>
          </div>
          <div class="video-holder" :id="'moment-' + m.id" v-for="(m, i) in webinarInfo.moments" :key="i">
            <div class="rnd-crns">
              <VideoPlayer
                :poster="m.video_urls.poster_url"
                :video_urls="m.video_urls"
                :subtitleUrls="m.subtitle_urls"
                :subtitles="m.subtitles"
                :continuefromTime="m.end_time"
                :duration="m.duration"
                :videoType="ContentType.Moment"
                :title="translateMoment(m).title"
                :mid="m.id"
                :useDemoOverlay="webinarInfo.use_demo_overlay"
                v-if="!m.video_urls.iframe_url || m.video_urls.iframe_url == ''"
                :logoOverlay="webinarInfo.overlay_logo"
                :isgated="webinarInfo.is_gated"
                :ma="webinarInfo.ma"
                :usetheaterMode="usetheaterMode"
                :cta="webinarInfo.cta"
                :post_play="webinarInfo.post_play"
              />
              <IframeVideoPlayer
                v-else
                :iframe_url="m.video_urls.iframe_url"
                :poster="m.video_urls.poster_url"
                :duration="m.duration"
              />
            </div>
            <div>
              <p v-html="translateMoment(m).title" class="mom-t" v-if="m.title" />
              <div v-html="translateMoment(m).description" class="mom-d" />
            </div>
          </div>
          <div v-if="webinarInfo.is_full_video_visible == true && webinarInfo.is_full_video_on_top != true">
            <h3 class="full-video-label">Full Video</h3>
            <div class="rnd-crns">
              <VideoPlayer
                :poster="webinarInfo.video_urls.poster_url"
                :video_urls="webinarInfo.video_urls"
                :subtitleUrls="webinarInfo.subtitle_urls"
                :subtitles="webinarInfo.subtitles"
                :duration="webinarInfo.duration"
                :videoType="ContentType.Webinar"
                :title="translateWebinar(webinarInfo).title"
                :useDemoOverlay="webinarInfo.use_demo_overlay"
                v-if="!webinarInfo.video_urls.iframe_url || webinarInfo.video_urls.iframe_url == ''"
                :logoOverlay="webinarInfo.overlay_logo"
                :isgated="webinarInfo.is_gated"
                :ma="webinarInfo.ma"
                :usetheaterMode="usetheaterMode"
                :cta="webinarInfo.cta"
                :post_play="webinarInfo.post_play"
              />
              <IframeVideoPlayer
                v-else
                :iframe_url="webinarInfo.video_urls.iframe_url"
                :poster="webinarInfo.video_urls.poster_url"
                :duration="webinarInfo.duration"
              />
            </div>
          </div>
        </div>
        <div id="parmonic-resources">
          <RelatedResourceComponent :resources="webinarInfo.related_resources" />
        </div>
        <div id="parmonic-speakers">
          <SpeakerSection :speakers="webinarInfo.speakers" />
        </div>
      </div>
      <PlaylistSkeleton :loadingmsg="loadingmsg" v-else />
    </div>
  </div>
</template>

<script lang="ts">
import "@/assets/_base.scss"

import VideoPlayer from "../components/shared/VideoPlayer.vue"
import IframeVideoPlayer from "../components/shared/IframeVideoPlayer.vue"
import { WebinarInfo } from "@/models/WebinarInfo"
import { AwEventType } from "@/models/AwEventType"
import setThemeData from "@/services/setThemeData"
import { GoogleAnalyticsClient } from "@/services/GoogleAnalyticsService"
import { AnalyticsClient } from "@/services/AnalyticsService"
import { container } from "tsyringe"
import { WebinarDataService } from "@/services/WebinarDataServices"
import { getFullVideo } from "@/services/getFullVideoData"
import { computed, defineComponent, ref } from "@vue/runtime-core"
import injectStrict from "@/utils/injectStrict"
import { GlobalDataKey, LoggerKey, translationKey } from "@/symbols"
import PlaylistLayout from "@/components/layouts/PlaylistLayout.vue"
import { ContentType } from "@/models/ContentType"
import { AwEventStoreV2 } from "@/models/AwEventStoreV2"
import RelatedResourceComponent from "@/components/shared/RelatedResourceComponent.vue"
import TitleTrailerCompoent from "@/components/shared/InfoTab.vue"
import SpeakerSection from "@/components/ResellerLayout/SpeakerSection.vue"

import MAGate from "@/components/MA/MAGate.vue"
import getCssVariables from "@/utils/getCssVariables"
import { isIOS, isMobile } from "@/services/deviceDetection"
import TheaterPlayer from "@/components/shared/TheaterPlayer.vue"
import SeoJsonLdComponentVue from "@/components/shared/SeoJsonLdComponent.vue"
import momentAutoOpenTheatreMode from "@/composables/shared/moment-autoopen-theatre"
import { EventBusKey } from "@/symbols"
import getTheaterVideos from "@/utils/getTheaterVideos"
import PlaylistSkeleton from "@/components/skeletons/playlist.vue"
import { Postplay } from "@/models/PostPlayOptions"
import handleAutoPlayEvent from "@/services/handle-autoplay"
import TranslationDropdown from "@/components/shared/Translation.vue"

export default defineComponent({
  components: {
    VideoPlayer,
    IframeVideoPlayer,
    PlaylistLayout,
    RelatedResourceComponent,
    MAGate,
    TheaterPlayer,
    SeoJsonLdComponentVue,
    TitleTrailerCompoent,
    SpeakerSection,
    TranslationDropdown,
    PlaylistSkeleton
  },
  props: {
    fetchId: { type: String, required: true }
  },
  setup() {
    let webinarId = ref(-1)
    let webinarInfo = ref<WebinarInfo | null>(null)
    let loadingmsg = ref("")
    const globalData = injectStrict(GlobalDataKey).value
    let eventbus = injectStrict(EventBusKey)
    const themeCss = computed(() => getCssVariables(globalData.theme_data))
    const dataService = container.resolve(WebinarDataService)
    const analytics = container.resolve(AnalyticsClient)
    const gaAnalytics = container.resolve(GoogleAnalyticsClient)
    let logger = injectStrict(LoggerKey)
    let win = ref({
      width: window.innerWidth
    })
    window.addEventListener("resize", handleResize)
    function handleResize() {
      win.value.width = window.innerWidth
    }
    let lightboxVideos = computed(() => getTheaterVideos(webinarInfo))
    let usetheaterMode = computed(
      () =>
        webinarInfo.value &&
        webinarInfo.value.use_theater_mode == true &&
        !!window.parmonic_disable_theater_mode == false &&
        !isIOS(navigator.userAgent, navigator.maxTouchPoints)
    )
    const { translateMoment, translateWebinar } = injectStrict(translationKey)
    return {
      eventbus,
      usetheaterMode,
      lightboxVideos,
      gaAnalytics,
      win,
      dataService,
      webinarInfo,
      themeCss,
      globalData,
      webinarId,
      loadingmsg,
      logger,
      analytics,
      Postplay,
      ContentType,
      translateMoment,
      translateWebinar
    }
  },
  created() {
    this.loadData()
  },
  mounted() {
    if (isMobile()) {
      handleAutoPlayEvent(this.globalData.instanceId)
    }
  },
  methods: {
    loadData() {
      this.dataService.getData(this.fetchId).then(async returnValue => {
        this.loadingmsg = "The video could not be loaded."
        if (returnValue == undefined) {
          this.logger!.error(`Parmonic Widget error: Webinar not found`)
          return
        }
        this.webinarInfo = returnValue as WebinarInfo
        this.webinarId = this.webinarInfo.id
        setThemeData(this.webinarInfo.theme_data, this.globalData)
        this.analytics.setUp(this.webinarId, null, null, null, this.globalData.analyticsEventsCaptureEndPoint, this.logger)
        this.gaAnalytics.setUp(this.webinarId, this.logger)
        var eventV2: AwEventStoreV2 = { eventType: AwEventType.WidgetViewed, type: ContentType.FullWidget }
        this.analytics.register(eventV2, null)

        this.globalData.fullVideoInfo = getFullVideo(this.webinarInfo)
        if (this.webinarInfo.use_theater_mode == true) {
          setTimeout(() => {
            momentAutoOpenTheatreMode(this.globalData.instanceId, this.eventbus)
          }, 500)
        }
      })
    }
  }
})
</script>

<style lang="scss" scoped>
.full-video-label {
  font-size: 1.625em;
  margin-bottom: 0.675em;
  font-family: var(--font-family);
  font-weight: 500;
  line-height: normal;
  color: var(--font-color);
  text-align: center;
}
.mom-t {
  font-size: 1.25em;
  line-height: normal;
  margin: 0.625em 0px;
  font-weight: bold;
  width: 100%;
  color: var(--font-color);
}
.mom-d {
  font-size: 1em;
  margin-bottom: 3.75em;
  width: 100%;
  color: var(--font-color);
  line-height: normal;
}
.video-holder {
  padding-bottom: 30px;
}
.margin-bottom {
  margin-bottom: 30px;
}
.forWide {
  display: block;
}
.forNarrow {
  display: none;
}
@media (max-width: 768px) {
  .forWide {
    display: none;
  }
  .forNarrow {
    display: block;
  }
}
</style>
