import getMomentId from "./ParameterServices"

export function focusOnMoment(el: HTMLElement | undefined = undefined) {
  const url = window.location.search
  if (url.length == 0) return
  const momentdiv = document.getElementById("moment-" + getMomentId(url)) as HTMLElement
  if (momentdiv) {
    if (el) el.scrollIntoView(true)
    else momentdiv.scrollIntoView(true)
    momentdiv.classList.add("moment-focus")
    setTimeout(function () {
      removeFocusFromMoment(momentdiv)
    }, 2000)
  }
}
function removeFocusFromMoment(moment_elm: HTMLElement) {
  moment_elm.classList.add("moment-transition")
  moment_elm.classList.remove("moment-focus")
}
