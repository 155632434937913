<template>
  <div class="pw-url-errors player-adon" v-if="videoPlaybackError.value">
    <div class="error-icon">
      <svg width="32" height="32" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
        <circle r="14.5" transform="matrix(-1 0 0 1 16 16)" stroke="white" stroke-width="3" />
        <path
          d="M16 9.33325L16 17M16 21.5999L16 22.6666"
          stroke="white"
          stroke-width="3"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
      </svg>
    </div>
    Video could not be loaded.
  </div>
</template>
<style lang="scss" scoped>
@import "../../assets/video/error-page.css";
</style>
<script lang="ts">
import { VideoInfo } from "@/models/VideoInfo"
import isVideoUrlsValid from "@/utils/VideoUrlsValidator"
import { defineComponent, PropType, Ref } from "vue"

export default defineComponent({
  props: {
    videoUrls: {
      type: Object as PropType<Ref<VideoInfo>>,
      required: true
    },
    videoPlaybackError: { type: Object as PropType<Ref<boolean>>, required: true, defult: false }
  },
  setup(props) {
    props.videoPlaybackError.value = !isVideoUrlsValid(props.videoUrls.value, undefined)
  }
})
</script>
