
import { SubtitleUrls } from "@/models/captions/SubtitleUrls"
import { LoggerKey, translationKey } from "@/symbols"
import { getLanguageString } from "@/utils/getLanguageString"
import injectStrict from "@/utils/injectStrict"
import { defineComponent, PropType, ref, Ref } from "vue"

export default defineComponent({
  props: {
    subtitleArray: {
      type: Object as PropType<Ref<SubtitleUrls[]>>,
      required: true
    },
    clickCaption: { type: Function, required: false },
    currentCaptions: { type: Object as PropType<Ref<String>>, required: true }
  },
  setup() {
    let iterator = 0
    let captionList = new Array<HTMLElement>()
    let logger = injectStrict(LoggerKey)
    let captionMenuHolder = ref<HTMLElement>()
    const { translateLabels } = injectStrict(translationKey)
    return { getLanguageString, logger, captionMenuHolder, captionList, iterator, translateLabels }
  },
  mounted() {
    this.iterator = 0
    document.addEventListener("keydown", this.captionIterator)
    var collection = this.captionMenuHolder?.getElementsByTagName("li")
    if (!collection) return
    this.captionList = Array.from(collection)
  },
  beforeUnmount() {
    document.removeEventListener("keydown", this.captionIterator)
  },
  methods: {
    captionIterator(e: any) {
      if (["ArrowUp", "ArrowDown"].indexOf(e.code) > -1) e.preventDefault()
      else return
      if (["ArrowUp"].indexOf(e.code) > -1 && this.iterator - 1 >= 0) this.iterator--
      if (["ArrowDown"].indexOf(e.code) > -1 && this.iterator + 1 < this.captionList.length) this.iterator++
      this.captionList[this.iterator].focus()
    }
  }
})
