<template>
  <div class="holder">
    <div class="left btn arrow-left" @click="scrollLeft()">
      <svg xmlns="http://www.w3.org/2000/svg" width="34" height="34" viewBox="0 0 24 24" class="icon-color">
        <path d="M7.41 15.41L12 10.83l4.59 4.58L18 14l-6-6-6 6z" class="icon-color" />
      </svg>
    </div>

    <div
      class="right btn arrow-right"
      @click="scrollRight()"
      v-if="
        arrowStatusService.arrowStatus.value.horizontalScroll &&
        !arrowStatusService.arrowStatus.value.horizontalScrollAtRight
      "
    >
      <svg xmlns="http://www.w3.org/2000/svg" width="34" height="34" viewBox="0 0 24 24" class="icon-color">
        <path d="M7.41 15.41L12 10.83l4.59 4.58L18 14l-6-6-6 6z" class="icon-color" />
      </svg>
    </div>
    <div class="slider" ref="scrollContainer">
      <div class="moment-holder">
        <HorizontalMoment
          :class="'video-component moment-transition'"
          :id="'moment-' + m.id"
          v-for="(m, i) in optionsObj.options"
          :key="i"
          :moment="m"
          :usetheaterMode="usetheaterMode"
          :isgated="webinarInfo.is_gated"
          :logoOverlay="webinarInfo.overlay_logo"
          :ma="webinarInfo.ma"
          :useDemoOverlay="webinarInfo.use_demo_overlay"
          :share_buttons="webinarInfo.share_buttons"
          :cta="webinarInfo.cta"
          :post_play="webinarInfo.post_play"
        />
      </div>
    </div>
  </div>
</template>

<script lang="ts">
import { WebinarInfo } from "@/models/WebinarInfo"
import VideoPlayer from "@/components/shared/VideoPlayer.vue"
import IframeVideoPlayer from "@/components/shared/IframeVideoPlayer.vue"
import { defineComponent, PropType, ref } from "@vue/runtime-core"
import setupOptions from "@/composables/option-handler"
import { ContentType } from "@/models/ContentType"
import { formatDate } from "@/services/DurationFormatter"
import injectStrict from "@/utils/injectStrict"
import { EventBusKey } from "@/symbols"
import getMomentId from "@/services/ParameterServices"
import SocialShare from "@/components/shared/SocialShare.vue"
import { listenOnScrollForArrows } from "@/services/scrollArrowStatus"
import HorizontalMoment from "@/components/HorizontalComponents/HorizontalMoment.vue"

export default defineComponent({
  components: { VideoPlayer, IframeVideoPlayer, HorizontalMoment, SocialShare },
  props: {
    webinarInfo: { type: Object as PropType<WebinarInfo>, required: true },
    usetheaterMode: { type: Boolean, required: false, default: false }
  },
  setup(props) {
    let pos = { top: 0, left: 0, x: 0, y: 0 }
    const scrollContainer = ref<HTMLElement | undefined>()
    var optionsObj = setupOptions(props.webinarInfo)
    var was_dragger = false
    let eventbus = injectStrict(EventBusKey)
    var height = ref<Number | null>(null)
    let isAnySocialMediaButtonEnable = false
    if (props.webinarInfo.share_buttons != null)
      isAnySocialMediaButtonEnable = Boolean(
        Object.values(props.webinarInfo.share_buttons).reduce((prev, now) => false || prev || now)
      )
    var arrowStatusService = listenOnScrollForArrows(scrollContainer)
    optionsObj.options = optionsObj.options.filter(m => m.id != "-1")
    return {
      optionsObj,
      ContentType,
      scrollContainer,
      pos,
      was_dragger,
      formatDate,
      height,
      eventbus,
      arrowStatusService,
      isAnySocialMediaButtonEnable
    }
  },
  created() {
    this.eventbus.on("setHeight", (value: any) => {
      this.height = value
    })
  },
  mounted() {
    this.addScroller()
    this.focus()
    this.arrowStatusService.setup()
  },
  unmounted() {
    this.arrowStatusService.unset()
  },
  methods: {
    changeSelection(id: string) {
      if (!this.was_dragger) this.optionsObj.updateCurrentSelection(id)
      else this.was_dragger = false
    },
    addScroller() {
      var self = this
      self.scrollContainer?.addEventListener("mousedown", (e: any) => mouseDownHandler(e))
      const mouseDownHandler = function (e: any) {
        if (!self.scrollContainer) return
        self.pos = {
          // The current scroll
          left: self.scrollContainer.scrollLeft,
          top: self.scrollContainer.scrollTop,
          // Get the current mouse position
          x: e.clientX,
          y: e.clientY
        }
        self.scrollContainer.style.cursor = "grabbing"
        self.scrollContainer.style.userSelect = "none"
        document.addEventListener("mousemove", self.mouseMoveHandler)
        document.addEventListener("mouseup", self.mouseUpHandler)
      }
    },
    mouseMoveHandler(e: any) {
      if (!this.scrollContainer) return
      // How far the mouse has been moved
      const dx = e.clientX - this.pos.x
      const dy = e.clientY - this.pos.y
      if (dx > 5 || dx < -5) {
        this.was_dragger = true
      }
      // Scroll the element
      //this.scrollContainer.scrollTop = pos.top - dy;
      this.scrollContainer.scrollLeft = this.pos.left - dx
    },
    mouseUpHandler() {
      document.removeEventListener("mousemove", this.mouseMoveHandler)
      document.removeEventListener("mouseup", this.mouseUpHandler)
      if (!this.scrollContainer) return
      this.scrollContainer.style.cursor = "default"
      this.scrollContainer.style.removeProperty("user-select")
    },
    scrollLeft() {
      if (!this.scrollContainer) return
      this.scrollContainer.scrollLeft = this.scrollContainer.scrollLeft - 350
      //this.scrollContainer.scrollLeft = this.pos.left - dx
    },
    scrollRight() {
      if (!this.scrollContainer) return
      this.scrollContainer.scrollLeft = this.scrollContainer.scrollLeft + 350
    },
    focus(i: string | null = null) {
      const url = window.location.search
      if (url.length == 0) return
      var id = getMomentId(url)
      const momentdiv = document.getElementById("moment-" + id) as HTMLElement
      if (momentdiv) {
        this.changeSelection(id)
        if (this.scrollContainer)
          this.scrollContainer.scrollIntoView({ behavior: "smooth", block: "center", inline: "center" })
        momentdiv.scrollIntoView({ behavior: "smooth", block: "center", inline: "center" })
        momentdiv.classList.toggle("moment-focus")
        setTimeout(() => momentdiv.classList.toggle("moment-focus"), 1500)
      }
    }
  }
})
</script>

<style lang="scss" scoped>
.moment-focus {
  background-color: #f1d9ff;
}
.moment-transition {
  transition: background-color 0.5s linear;
  -moz-transition: background-color 0.5s linear;
  -webkit-transition: background-color 0.5s linear;
  -ms-transition: background-color 0.5s linear;
}

.social-share-object-container {
  min-height: 1.875em;
  display: flex;
  justify-content: right;
}

.icon-color {
  stroke: transparent;
  fill: var(--theme-contrast);
}

.slider {
  overflow-x: scroll;
  width: 100%;
  height: fit-content;

  -ms-overflow-style: none;
  scrollbar-width: none;
  position: relative;
}

.moment-holder {
  display: flex;
  column-gap: 18px;
  margin-left: -18px;
  margin-top: 14px;
  width: fit-content;
  margin-bottom: 5px;
}

.slider::-webkit-scrollbar {
  display: none;
}

.holder {
  width: 100%;
  position: relative;
}

.btn {
  position: absolute;
  top: 0px;
  border: 0px;
  z-index: 100;
  bottom: unset;
  top: 180px;
  display: block;

  svg {
    background-color: var(--theme-color-9);
    border-radius: 360px;
  }
}

.left {
  left: 0px;

  svg {
    transform: rotate(-90deg);
    box-shadow: -2px 0px rgba(0, 0, 0, 0.4);
  }

  svg:hover {
    transform: rotate(-90deg) scale(1.1);
  }
}

.right {
  right: 0px;

  svg {
    transform: rotate(90deg);
    box-shadow: 2px 0px rgba(0, 0, 0, 0.4);
  }

  svg:hover {
    transform: rotate(90deg) scale(1.1);
  }
}
.option-holder {
  width: 20 em;
}
.full-video-label {
  text-align: center;
  font-family: var(--font-family);
  font-weight: 500;
}
</style>
