import { Ref, ref, computed } from "vue"
const PLAY_SVG = `<svg class="icon-control" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M8.00001 8.73444C8.00001 7.13218 9.7458 6.14062 11.1219 6.96127L23.132 14.1234C24.4748 14.9241 24.4748 16.8689 23.132 17.6697L11.1219 24.8319C9.7458 25.6526 8 24.661 8 23.0587L8.00001 8.73444Z" fill="white"/>
</svg>
`
const PAUSE_SVG = `<svg class="icon-control"  viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
<rect x="7" y="7" width="6" height="18" rx="1" fill="white"/>
<rect x="19" y="7" width="6" height="18" rx="1" fill="white"/>
</svg>`
const REPLAY_SVG = `<svg class="icon-control" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
<path d="M9.5 16C9.5 12.4101 12.4101 9.5 16 9.5C18.1928 9.5 20.1336 10.5852 21.3124 12.2533L18.9764 12.0939C18.3499 12.0511 17.8074 12.5243 17.7646 13.1508C17.7219 13.7773 18.1951 14.3199 18.8216 14.3626L24.5415 14.753C25.168 14.7958 25.7105 14.3226 25.7533 13.696L26.1437 7.97614C26.1864 7.34964 25.7132 6.80709 25.0867 6.76434C24.4602 6.72158 23.9177 7.1948 23.8749 7.8213L23.6968 10.4306C21.9723 8.05135 19.168 6.5 16 6.5C10.7533 6.5 6.5 10.7533 6.5 16C6.5 21.2467 10.7533 25.5 16 25.5C19.5182 25.5 22.5878 23.5867 24.2282 20.7511C24.643 20.034 24.3979 19.1164 23.6808 18.7016C22.9637 18.2868 22.0461 18.5318 21.6313 19.2489C20.5052 21.1957 18.404 22.5 16 22.5C12.4101 22.5 9.5 19.5899 9.5 16Z" fill="white"/>
</svg>`
export function setUpPlayBtn(hasEnded: Ref<boolean>) {
  let isplaying = ref(false)
  let playbtnString = computed(() => (hasEnded.value ? REPLAY_SVG : isplaying.value ? PAUSE_SVG : PLAY_SVG))
  let getPlayButtonStringRef = () => playbtnString
  return { isplaying, playbtnString, getPlayButtonStringRef }
}
export const BUTTON_SVGS = { PLAY_SVG, PAUSE_SVG, REPLAY_SVG }
