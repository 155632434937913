
import { PropType, Ref, defineComponent, ref } from "vue"
import { translationKey } from "@/symbols"
import injectStrict from "@/utils/injectStrict"
export default defineComponent({
  props: {
    videoPlayer: {
      type: Object as PropType<Ref<HTMLVideoElement>>,
      required: true
    }
  },
  setup() {
    const speedValues = [2, 1.5, 1.25, 1, 0.75, 0.5]
    const speedListRef = ref()
    const currentSpeed = ref(1)
    const hadHovered = ref(false)
    const { translateLabels } = injectStrict(translationKey)
    return {
      speedListRef,
      speedValues,
      hadHovered,
      currentSpeed,
      translateLabels
    }
  },
  methods: {
    toggleSpeedList() {
      this.speedListRef.classList.toggle("show")
    },
    hoverOutSpeedList() {
      this.speedListRef.classList.remove("show")
    },
    changeSpeed(speed: number) {
      if (!this.$props.videoPlayer) return
      this.currentSpeed = speed
      this.$props.videoPlayer.value.playbackRate = speed
      this.toggleSpeedList()
    }
  }
})
