<template>
  <div class="container-custom" v-if="window.parmonic_hide_localization != true && languages.length > 0">
    <select @change="handleLanguageChange" class="select-language">
      <option :selected="l == 'en'" value="en">English</option>
      <option v-for="l in languages" :key="l" :selected="l === language" :value="l">{{ getLanguageString(l) ?? l }}</option>
    </select>
  </div>
</template>

<script lang="ts">
import { defineComponent } from "vue"
import { getLanguageString } from "@/utils/getLanguageString"
import injectStrict from "@/utils/injectStrict"
import { translationKey } from "@/symbols"

export default defineComponent({
  props: {
    languages: {
      type: Array as () => string[],
      required: false
    }
  },
  setup(props) {
    const { language, setLang } = injectStrict(translationKey)
    const handleLanguageChange = (event: Event) => {
      const selectedLanguage = (event.target as HTMLSelectElement).value
      setLang(selectedLanguage)
    }
    return {
      languages: props.languages || [],
      handleLanguageChange,
      getLanguageString,
      language
    }
  }
})
</script>

<style scoped>
.container-custom {
  width: 100%;
  padding-bottom: 0.62em;
}

.select-language {
  color: var(--theme-contrast);
  background: var(--theme-color);
  font-family: var(--font-family);
  font-size: 1em;
  margin-left: auto;
  display: block;
  padding: 0.32em;
  width: 200px;
  border-radius: 5px;
  outline: 0;
}
.select-language > option {
  appearance: none; /* remove default appearance set by the browser */
  font-family: var(--font-family);
  font-size: 1em;
  border-radius: 0.32em;
}
</style>
