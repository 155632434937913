
import { computed, defineComponent, PropType } from "@vue/runtime-core"
import injectStrict from "@/utils/injectStrict"
import { translationKey } from "@/symbols"
import { SpeakerInfo, SocialUrls } from "@/models/SpeakerInfo"
export default defineComponent({
  props: {
    speakers: {
      type: Object as PropType<Array<SpeakerInfo>>,
      required: false,
      default: null
    }
  },
  methods: {
    getImageStyle(url: string | null | undefined) {
      return `background-image: url("${url ?? "https://js.static.parmonic.ai/static/default-speaker-image.png"}") !important`
    },
    hasAny(urls: SocialUrls): boolean {
      if (urls) {
        return urls.twitter !== null || urls.linkedin !== null || urls.web !== null
      }
      return false
    },
    infoHasDescription(info: SpeakerInfo): boolean {
      return false
    }
  },
  setup(props) {
    const showComponent = computed(() => props.speakers && props.speakers.length > 0)
    const hasDescription = computed(() => {
      var has = false
      if (!props.speakers) return false
      props.speakers.forEach(element => {
        if (element.description) has = true
      })
      return has
    })
    const { translateSpeaker, translateLabels } = injectStrict(translationKey)
    return {
      showComponent,
      hasDescription,
      translateSpeaker,
      translateLabels
    }
  }
})
