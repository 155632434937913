<template>
  <section class="resource-holder" v-if="showRelatedResources">
    <h2 class="main-title" v-html="translateLabels('Related Resources')"></h2>
    <div class="tile-holder">
      <a
        class="icon-color tile"
        v-for="(r, i) in resources"
        :key="i"
        :href="r.link"
        target="_blank"
        :title="translateResource(r).title"
        @click="() => onClick(r)"
      >
        <div class="resource-row">
          <div class="icon">
            <div class="svg-holder" v-html="getResourceSvg(r.icon)"></div>
          </div>
          <div class="title-holder">
            <div class="info">
              <p v-html="getResourceLabel(r.icon)"></p>
            </div>
            <div class="th">
              <p class="title" v-html="translateResource(r).title"></p>
            </div>
          </div>
        </div>
      </a>
    </div>
  </section>
</template>

<script lang="ts">
import { computed, defineComponent, PropType } from "vue"
import injectStrict from "@/utils/injectStrict"
import { translationKey } from "@/symbols"
import { RelatedResourceData } from "@/models/RelatedResourceData"
import getResourceSvg from "@/services/relatedResourceSvgProvider"
import { container } from "tsyringe"
import { AnalyticsClient } from "@/services/AnalyticsService"
import { AwEventType } from "@/models/AwEventType"
export default defineComponent({
  props: {
    resources: { type: Object as PropType<Array<RelatedResourceData>>, required: false },
    allowaccess: { type: Boolean, required: false, default: true }
  },
  setup(props) {
    var showRelatedResources = computed(() => props.resources && props.resources.length > 0)
    const analytics = container.resolve(AnalyticsClient)

    let onClick = (res: RelatedResourceData) => {
      if (res.id) analytics.register({ eventType: AwEventType.ResourceClicked, relatedResourceId: res.id }, null)
    }

    const { translateResource, translateLabels } = injectStrict(translationKey)
    return { showRelatedResources, getResourceSvg, onClick, translateResource, translateLabels }
  },
  methods: {
    getResourceLabel(lbl: string): string {
      switch (lbl) {
        case "WEB":
          return this.translateLabels("Web") ?? ""
          break
        case "INFOGRAPHIC":
          return this.translateLabels("Infographic") ?? ""
          break
        case "EBOOK":
          return this.translateLabels("eBook") ?? ""
          break
        case "BLOGPOST":
          return this.translateLabels("Blog") ?? ""
          break
        case "WEBINAR":
          return this.translateLabels("Webinar") ?? ""
          break
        case "VIDEO":
          return this.translateLabels("Video") ?? ""
          break
        case "PAPER":
          return this.translateLabels("White Paper") ?? ""
          break
        case "SLIDEDECK":
          return this.translateLabels("Slides") ?? ""
          break
        case "ARTICLE":
          return this.translateLabels("Article") ?? ""
          break
        default:
          throw new Error(this.translateLabels("Icon not found exception ") ?? "")
          break
      }
    }
  }
})
</script>

<style lang="scss" scoped>
.resource-holder {
  width: 100%;
  margin-top: 3rem;
  .main-title {
    box-sizing: border-box;
    font-family: var(--font-family);
    font-weight: 500;
    line-height: normal;
    color: var(--font-color);
    font-size: 1.625em;
    margin-top: 1.375em;
    padding: 0;
    margin-bottom: 0.625em;
  }
  .tile-holder {
    display: flex;
    width: calc(100% + 20px);
    flex-wrap: nowrap;
    margin: -10px;
  }
  .tile {
    width: calc(33% - 60.6px); /* HACK ALERT */
    max-width: 350px;
    min-width: 200px;
    text-decoration: none;
    border: 2px solid var(--theme-contrast);
    background-color: var(--theme-color-6);
    border-radius: 8px;
    margin: 10px;
    padding: 20px;
  }
  .tile:hover {
    background-color: var(--theme-color-7);
  }
  .icon-color {
    fill: var(--theme-contrast);
    stroke: var(--theme-contrast);
  }
  .icon {
    width: 25%;
    .svg-holder {
      width: 80%;
      aspect-ratio: 1/1;
      display: flex;
      justify-content: center;
    }
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
.resource-row {
  display: flex;
  font-family: var(--font-family);
  line-height: normal;
  height: 100%;
  .title-holder {
    width: 75%;
    padding-left: 12px;
    .th {
      display: flex;
      align-items: center;
      width: 100%;
    }
    .title {
      -webkit-box-orient: vertical;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      text-overflow: ellipsis;
      overflow: hidden;
      margin: 0;
      padding: 5px;
      color: var(--theme-contrast);
      font-size: 1em;
      line-height: normal;
      padding-bottom: 0px;
    }
    .info {
      p {
        margin: 0;
        margin-left: 5px;
        color: white;
        font-size: 0.625em;
        padding: 0.25em 0.375em;
        line-height: normal;
        background-color: rgba(0, 0, 0, 0.1);
        width: fit-content;
        border-radius: 6px;
        font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell, "Open Sans",
          "Helvetica Neue", sans-serif;
      }
    }
  }
}
.truncate-overflow {
  --max-lines: 2;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: var(--max-lines);
  overflow: hidden;
}
@media (max-width: 770px) {
  .resource-holder {
    .tile-holder {
      flex-wrap: wrap;
      width: 100%;
    }
    .tile {
      min-width: 250px;
    }
  }
}
</style>
