export class CookieManagerService {
  public static getByPattern(pattern: string | RegExp): RegExpMatchArray | null {
    try {
      return document.cookie.match(pattern)
    } catch (e) {
      return null
    }
  }

  public static getByName(name: string): string | undefined {
    try {
      let matches = document.cookie.match(
        new RegExp("(?:^|; )" + name.replace(/([\.$?*|{}\(\)\[\]\\\/\+^])/g, "\\$1") + "=([^;]*)")
      )
      return matches ? decodeURIComponent(matches[1]) : undefined
    } catch (e) {
      return undefined
    }
  }

  public static setCookie(cname: string, cvalue: string, exdays: number) {
    const d = new Date()
    d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000)
    let expires = "expires=" + d.toUTCString()
    document.cookie = cname + "=" + cvalue + ";" + expires + ";path=/"
  }
}
