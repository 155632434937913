import localizationResourceAr from "@/localization/localization-resource-ar"
import localizationResourceDe from "@/localization/localization-resource-de"
import localizationResourceEs from "@/localization/localization-resource-es"
import localizationResourceFr from "@/localization/localization-resource-fr"
import localizationResourceHi from "@/localization/localization-resource-hi"
import localizationResourceIt from "@/localization/localization-resource-it"
import localizationResourceJa from "@/localization/localization-resource-ja"
import localizationResourceKo from "@/localization/localization-resource-ko"
import localizationResourceNl from "@/localization/localization-resource-nl"
import localizationResourcePt from "@/localization/localization-resource-pt"
import localizationResourceRu from "@/localization/localization-resource-ru"
import localizationResourceSv from "@/localization/localization-resource-sv"
import localizationResourceZh from "@/localization/localization-resource-zh"

interface LocalizationResources {
  [key: string]: any
}

const localizationResources: LocalizationResources = {
  ar: localizationResourceAr,
  de: localizationResourceDe,
  es: localizationResourceEs,
  fr: localizationResourceFr,
  hi: localizationResourceHi,
  it: localizationResourceIt,
  ja: localizationResourceJa,
  ko: localizationResourceKo,
  nl: localizationResourceNl,
  pt: localizationResourcePt,
  ru: localizationResourceRu,
  sv: localizationResourceSv,
  zh: localizationResourceZh
}

export default localizationResources
