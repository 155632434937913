
import { computed, defineComponent, PropType, ref } from "vue"
import ScriptComponent from "./ScriptComponent.vue"
import { MAData } from "@/models/ma/MAData"
import { setMarketoKnownCache } from "@/services/MarketingAutomationApi"

declare var MktoForms2: any

export default defineComponent({
  components: { ScriptComponent },
  props: {
    config: Object as PropType<MAData>
  },
  setup: function (props, { emit }) {
    const munchkinId = computed(() => props.config?.ma_account_id)
    const formId = computed(() => Number(props.config?.form_id))

    let formScript = computed(() => {
      if (!munchkinId.value || !formId.value) return undefined
      return `<script src="//${munchkinId.value}.mktoweb.com/js/forms2/js/forms2.min.js"><\/script>`
    })

    let root = ref<HTMLElement>()

    let scriptLoaded = () => {
      MktoForms2.loadForm(`//${munchkinId.value}.mktoweb.com`, munchkinId.value, formId.value, function (form: any) {
        form.onSuccess(function (values: any, followUpUrl: string) {
          // set cache and hide modal
          setMarketoKnownCache(true)
          emit("formsubmitted", form)
          // Return false to prevent the submission handler from taking the lead to the follow up url
          return false
        })
      })
    }

    return {
      root,
      scriptLoaded,
      formScript,
      formId
    }
  }
})
