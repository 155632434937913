import { ProviderType, MAData } from "@/models/ma/MAData"
import { MarketoLeadInfoResponse, MarketoLeadInfoRequest, PardotLeadInfoRequest } from "@/models/ma/MALeadInfo"
import axios from "axios"
import { getHubspotTrackerId, getMarketoTrackerId, getPardotVisitorId } from "./MarketingAutomationService"

export class MAConfig {
  public static baseUrl: string
}

const PARMONIC_TKR_MARKETO_KNOWN = "PARMONIC_TKR_MARKETO_KNOWN"
const PARMONIC_TKR_HUBSPOT_KNOWN = "PARMONIC_TKR_HUBSPOT_KNOWN"
const PARMONIC_TKR_PARDOT_KNOWN = "PARMONIC_TKR_PARDOT_KNOWN"

export function getMarketoKnownCache(): boolean {
  return localStorage[PARMONIC_TKR_MARKETO_KNOWN] ?? false
}

export function setMarketoKnownCache(isKnown: boolean) {
  localStorage[PARMONIC_TKR_MARKETO_KNOWN] = isKnown
}

export function getHubspotKnownCache(): boolean {
  return localStorage[PARMONIC_TKR_HUBSPOT_KNOWN] ?? false
}

export function setHubspotKnownCache(isKnown: boolean) {
  localStorage[PARMONIC_TKR_HUBSPOT_KNOWN] = isKnown
}

export function getPardotKnownCache(): boolean {
  return localStorage[PARMONIC_TKR_PARDOT_KNOWN] ?? false
}

export function setPardotKnownCache(isKnown: boolean) {
  localStorage[PARMONIC_TKR_PARDOT_KNOWN] = isKnown
}

export async function getMarketoKnownLeadApi(accountId: number | undefined, cookie: string | undefined): Promise<boolean> {
  let isKnown = getMarketoKnownCache()
  if (isKnown) return isKnown

  let request: MarketoLeadInfoRequest = { accountId, cookie }
  let x = await axios.post<MarketoLeadInfoResponse>(`${MAConfig.baseUrl}ma/marketo/lead-info`, request)
  isKnown = !!x?.data?.id
  setMarketoKnownCache(isKnown)
  return isKnown
}

export async function getHubspotKnownLeadApi(accountId: number | undefined, cookie: string | undefined): Promise<boolean> {
  let isKnown = getHubspotKnownCache()
  if (isKnown) return isKnown

  let request: MarketoLeadInfoRequest = { accountId, cookie }
  let x = await axios.post<MarketoLeadInfoResponse>(`${MAConfig.baseUrl}ma/hubspot/lead-info`, request)
  isKnown = !!x?.data?.id
  setHubspotKnownCache(isKnown)
  return isKnown
}

export async function getPardotKnownLeadApi(accountId: number | undefined, cookie: number | undefined): Promise<boolean> {
  let isKnown = getPardotKnownCache()
  if (isKnown) return isKnown

  let request: PardotLeadInfoRequest = { accountId, cookie }
  let x = await axios.post<MarketoLeadInfoResponse>(`${MAConfig.baseUrl}ma/pardot/lead-info`, request)
  isKnown = !!x?.data?.id
  setPardotKnownCache(!!x.data.id)
  return isKnown
}

export async function isKnownLead(type: ProviderType, accountId: number | undefined): Promise<boolean> {
  try {
    switch (type) {
      case "Marketo":
        return await getMarketoKnownLeadApi(accountId, getMarketoTrackerId())
      case "Hubspot":
        return await getHubspotKnownLeadApi(accountId, getHubspotTrackerId())
      case "Pardot":
        return await getPardotKnownLeadApi(accountId, getPardotVisitorId())
      default:
        throw "invalid type"
    }
  } catch (error) {
    return false
  }
}

export async function gateCheckIsKnown(ma: MAData | undefined) {
  if (!ma) return false // data is mandatory

  if ((await isKnownLead(ma?.type, ma!.account_id)) == false) {
    return false
  } else {
    return true
  }
}
