export default {
  "Full Video": "Vidéo complète",
  "Related Resources": "Ressources Connexes",
  Blog: "Blog",
  Play: "Jouer",
  "No Captions": "Pas de Sous-titres",
  Captions: "Sous-titres",
  "Toggle fullscreen": "Basculer en plein écran",
  "playback speed": "Vitesse de lecture",
  "Play with captions in": "Jouer avec des sous-titres en",
  Seek: "Recherche",
  "Watch Trailer": "Regarder la bande-annonce",
  "Watch full video": "Regarder la vidéo complète",
  "Watch Video": "Regarder la vidéo",
  Speakers: "Haut-parleurs",
  Infographic: "Infographie",
  eBook: "eBook",
  Webinar: "Webinaire",
  Video: "Vidéo",
  "White Paper": "Livre blanc",
  Slides: "Diapositives",
  Article: "Article",
  "Icon not found exception": "Exception d'icône non trouvée",
  "Key Moments": "Moments Clés",
  Resources: "Ressources"
}
