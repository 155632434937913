<template>
  <div class="caption-box">
    <text v-if="captionText != null" class="captions">{{ captionText }}</text>
  </div>
</template>
<script lang="ts">
import { computed, defineComponent, PropType, Ref } from "vue"
import CaptionData from "@/models/captions/CaptionsData"
export default defineComponent({
  props: {
    captionData: {
      type: Object as PropType<Ref<CaptionData>>,
      required: true
    },
    currentTime: { type: Object as PropType<Ref<Number>>, required: true }
  },
  setup(props) {
    const captionText = computed(() => {
      return (
        props.captionData.value.caption_segments.find(
          x => x.startTime < props.currentTime.value && x.endTime > props.currentTime.value
        )?.text ?? ""
      )
    })
    return { captionText }
  }
})
</script>
