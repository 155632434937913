<template>
  <Teleport to="body">
    <div id="aw--c" :style="themeCss">
      <div id="aw-sl" style="font-size: 1em !important">
        <div class="pw-lgtbx" v-if="isLightboxVisible == true && currentVideo != null">
          <div class="lgthbx-hldr">
            <div class="lgtbx-back-panel" @click.stop="hideLightbox">
              <div class="close" @click.stop="hideLightbox">
                <svg width="128" height="128" viewBox="0 0 128 128" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M75.7268 64.1048L125.567 14.2626C128.811 11.0206 128.811 5.77865 125.567 2.53662C122.325 -0.705404 117.083 -0.705404 113.841 2.53662L63.9993 52.3788L14.1586 2.53662C10.9151 -0.705404 5.67469 -0.705404 2.43266 2.53662C-0.810886 5.77865 -0.810886 11.0206 2.43266 14.2626L52.2733 64.1048L2.43266 113.947C-0.810886 117.189 -0.810886 122.431 2.43266 125.673C4.04836 127.29 6.17276 128.103 8.29564 128.103C10.4185 128.103 12.5414 127.29 14.1586 125.673L63.9993 75.8307L113.841 125.673C115.459 127.29 117.582 128.103 119.704 128.103C121.827 128.103 123.95 127.29 125.567 125.673C128.811 122.431 128.811 117.189 125.567 113.947L75.7268 64.1048Z"
                    class="stroke"
                  />
                </svg>
              </div>
            </div>
            <div class="lgtbx-plr-cntnr">
              <div class="rnd-crns">
                <VideoPlayer
                  :video_urls="currentVideo.video_urls"
                  :duration="currentVideo.duration"
                  :subtitles="currentVideo.subitleUrls"
                  :isgated="isGated"
                  :isTrailer="isTrailer"
                  :mid="currentVideo.mid"
                  :useDemoOverlay="useDemoOverlay"
                  :videoType="currentVideo.type"
                  :title="currentVideo.title"
                  :continuefromTime="currentVideo.continuefromTime"
                  :logoOverlay="logoOverlay"
                  :autoPlay="isAutoPlay"
                  :currentSubtitle="currentSubtitle"
                  :ma="ma"
                  :cta="cta"
                  :ref="current_video"
                  :post_play="post_play"
                  :isLightboxVideo="true"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </Teleport>
</template>
<style lang="scss" scoped>
.pw-lgtbx {
  position: fixed;
  width: 100%;
  height: 100%;

  z-index: 1999999998; /*Ma form modal has z-index 1999999999*/
  top: 0px;
  left: 0px;
  .lgthbx-hldr {
    width: 100%;
    height: 100%;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    .lgtbx-back-panel {
      position: relative;
      width: 100%;
      height: 100%;
      background: var(--lightbox-bg-color);
      .close {
        svg {
          width: 20px;
          height: 20px;
        }
        position: absolute;
        padding: 20px;
        left: auto;
        right: 0px;
        top: 0px;
        cursor: pointer;
        .stroke {
          fill: white;
        }
      }
    }
    .lgtbx-plr-cntnr {
      position: absolute;
      width: 90%;
      left: auto;
      right: auto;
      top: auto;
      bottom: auto;
      margin: auto auto;
    }
  }
}
@media (min-width: 640px) {
  .pw-lgtbx {
    .lgthbx-hldr {
      .lgtbx-plr-cntnr {
        width: 90%;
      }
    }
  }
}
@media (min-width: 768px) {
  .pw-lgtbx {
    .lgthbx-hldr {
      .lgtbx-plr-cntnr {
        width: 85%;
      }
    }
  }
}
@media (min-width: 1024px) {
  .pw-lgtbx {
    .lgthbx-hldr {
      .lgtbx-plr-cntnr {
        width: 900px;
      }
    }
  }
}
@media (min-width: 1280px) {
  .pw-lgtbx {
    .lgthbx-hldr {
      .lgtbx-plr-cntnr {
        width: 1000px;
      }
    }
  }
}
</style>

<script lang="ts">
import { defineComponent, PropType, ref } from "@vue/runtime-core"
import LogoOverlayData from "@/models/LogoOverlayData"
import injectStrict from "@/utils/injectStrict"
import { EventBusKey, GlobalDataKey } from "@/symbols"
import VideoPlayer from "@/components/shared/VideoPlayer.vue"
import { LightboxEventBusModal, LightboxVideo } from "@/models/lightbox/LightboxModels"
import { MAData } from "@/models/ma/MAData"
import { RelatedResourceData } from "@/models/RelatedResourceData"
import eventBus from "@/event-bus"
import { ContentType } from "@/models/ContentType"
import { Postplay } from "@/models/PostPlayOptions"

export default defineComponent({
  components: { VideoPlayer },
  props: {
    videos: { type: Object as PropType<Array<LightboxVideo>>, required: true },
    useDemoOverlay: { type: Boolean, required: false, default: false },
    logoOverlay: { type: Object as PropType<LogoOverlayData>, required: false, default: null },
    isTrailer: { type: Boolean, required: false, default: false },
    themeCss: { type: Object, required: true, default: {} },
    ma: { type: Object as PropType<MAData>, required: false, default: null },
    cta: { type: Object as PropType<RelatedResourceData>, default: null },
    post_play: { type: String, required: false, default: undefined },
    isGated: { type: Boolean, required: false, default: false }
  },
  setup(props) {
    let currentVideo = ref<LightboxVideo | null>(props.videos[0] ?? null)
    let eventbus = injectStrict(EventBusKey)
    let globalData = injectStrict(GlobalDataKey)
    let isLightboxVisible = ref<Boolean>(false)
    let isAutoPlay = ref<Boolean>(true)
    let currentSubtitle = ref<string | null>(null)
    const current_video = ref()
    eventbus.on("setup-lightbox", x => {
      let arg = x as LightboxEventBusModal
      if (globalData.value.instanceId != arg.instanceId) return
      var video = props.videos.find(x => x.identifier == arg.identifier && x.type == arg.contentType)
      if (!video) return
      currentSubtitle.value = arg.language ?? null
      currentVideo.value = video
      isAutoPlay.value = arg.autoplay ?? true
      isLightboxVisible.value = true
    })
    return { currentVideo, isLightboxVisible, currentSubtitle, isAutoPlay, current_video, globalData }
  },
  mounted() {
    eventBus.on(Postplay.AUTO_PLAY, ({ mid }: any) => {
      const currentMomentIndex = this.videos.findIndex(m => {
        return m.mid == mid
      })
      if (currentMomentIndex + 1 < this.videos.length && this.videos[currentMomentIndex + 1].identifier != -1) {
        const nextMoment = this.videos[currentMomentIndex + 1]
        console.log({ currentMomentIndex, videos: this.videos })
        setTimeout(() => {
          if (nextMoment.mid == null) return
          eventBus.emit(
            "setup-lightbox",
            new LightboxEventBusModal(this.globalData.instanceId, nextMoment.mid, null, ContentType.Moment, true)
          )
        }, 0)
        this.hideLightbox()
      }
    })
  },
  methods: {
    hideLightbox() {
      this.currentVideo = null
      this.isLightboxVisible = false
    }
  }
})
</script>
