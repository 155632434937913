<template>
  <div class="pw-continue-play player-adon" v-if="showEndedPage && !pressed_close">
    <div class="cont-plya-close" @click="clickClose()">
      <svg viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M5 5L15 15" class="stroke" stroke-width="2" stroke-linecap="round" />
        <path d="M5 15L15 5" class="stroke" stroke-width="2" stroke-linecap="round" />
      </svg>
    </div>
    <div class="pw-continue-play-button" @click="openCtaNewTab">
      <svg width="16" height="16" viewBox="0 0 128 128" fill="#fff" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M64.0772 30.8806C62.0185 30.8806 60.3458 31.5239 59.0591 32.8106C57.7724 34.0973 57.1291 35.77 57.1291 37.8287C57.1291 39.7845 57.7724 41.4572 59.0591 42.8468C60.3458 44.2364 62.0185 44.9312 64.0772 44.9312C66.1359 44.9312 67.8086 44.2364 69.0953 42.8468C70.382 41.4572 71.0253 39.7845 71.0253 37.8287C71.0253 35.77 70.382 34.0973 69.0953 32.8106C67.8086 31.5239 66.1359 30.8806 64.0772 30.8806ZM69.7901 62.6876C69.7901 59.2766 67.025 56.5115 63.614 56.5115C60.203 56.5115 57.4379 59.2766 57.4379 62.6876L57.4379 90.9433C57.4379 94.3543 60.203 97.1194 63.614 97.1194C67.025 97.1194 69.7901 94.3543 69.7901 90.9433V62.6876ZM64.0772 0C72.9296 0 81.2159 1.6727 88.9361 5.0181C96.6562 8.36349 103.424 12.9441 109.24 18.76C115.056 24.5758 119.637 31.3438 122.982 39.0639C126.327 46.7841 128 55.0704 128 63.9228C128 72.8782 126.327 81.2159 122.982 88.9361C119.637 96.6562 115.082 103.398 109.317 109.163C103.553 114.927 96.7849 119.508 89.0133 122.905C81.2417 126.302 72.9296 128 64.0772 128C55.1218 128 46.7583 126.302 38.9867 122.905C31.2151 119.508 24.4729 114.953 18.76 109.24C13.0471 103.527 8.49217 96.7849 5.09531 89.0133C1.69844 81.2417 0 72.8782 0 63.9228C0 55.0704 1.69844 46.7583 5.09531 38.9867C8.49217 31.2151 13.0728 24.4471 18.8372 18.6828C24.6015 12.9184 31.3438 8.36349 39.0639 5.0181C46.7841 1.6727 55.1218 0 64.0772 0ZM63.9228 12.1978C49.6148 12.1978 37.4427 17.2417 27.4065 27.3293C17.3703 37.417 12.3522 49.6663 12.3522 64.0772C12.3522 78.3852 17.3703 90.5573 27.4065 100.593C37.4427 110.63 49.6663 115.648 64.0772 115.648C78.3852 115.648 90.583 110.63 100.671 100.593C110.758 90.5573 115.802 78.3337 115.802 63.9228C115.802 49.6148 110.758 37.417 100.671 27.3293C90.583 17.2417 78.3337 12.1978 63.9228 12.1978Z"
        />
      </svg>
      <span class="cont-plat-txt"
        >{{ translateResource(cta).title.substring(0, 50).trim() }}<span v-if="cta.title.length > 50">...</span></span
      >
    </div>
  </div>
</template>
<style scoped>
.pw-continue-play-button:hover {
  opacity: 1;
}
</style>
<script lang="ts">
import { RelatedResourceData } from "@/models/RelatedResourceData"
import { computed, defineComponent, PropType, ref, Ref } from "vue"
import injectStrict from "@/utils/injectStrict"
import { translationKey } from "@/symbols"
import { AnalyticsClient } from "@/services/AnalyticsService"
import { container } from "tsyringe"
import { AwEventType } from "@/models/AwEventType"

export default defineComponent({
  props: {
    hasEnded: { type: Object as PropType<Ref<Boolean>>, required: true },
    dur: { type: Object as PropType<Ref<Number>>, required: true },
    cta: { type: Object as PropType<RelatedResourceData>, required: false, default: null }
  },
  setup(props) {
    const analytics = container.resolve(AnalyticsClient)
    const pressed_close = ref(false)
    let showEndedPage = computed(() => {
      pressed_close.value = false
      return props.hasEnded.value
    })

    const openCtaNewTab = () => {
      window.open(props.cta.link, "_blank")
      if (props.cta?.id)
        analytics.register({ eventType: AwEventType.ResourceClicked, relatedResourceId: props.cta?.id }, null)
    }

    const { translateResource } = injectStrict(translationKey)
    return { showEndedPage, pressed_close, openCtaNewTab, translateResource }
  },
  methods: {
    clickClose() {
      this.pressed_close = true
    }
  }
})
</script>
