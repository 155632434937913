
import { defineComponent, getCurrentInstance, PropType, ref } from "@vue/runtime-core"
import { GlobalDataKey } from "@/symbols"
import { setupSocialShare } from "@/composables/social-share"
import injectStrict from "@/utils/injectStrict"
import { ShareButtons } from "@/models/ShareButtons"
import momentShareUrl from "@/services/moment-share-url"
import { useShareLink } from "vue3-social-sharing"

export default defineComponent({
  props: {
    id: { type: Number, required: true },
    title: { type: String, required: true },
    description: { type: String, required: true },
    share_buttons: { type: Object as PropType<ShareButtons | null>, required: false, default: null }
  },
  setup(props) {
    const globalData = injectStrict(GlobalDataKey).value
    const socialShareUrl = momentShareUrl(props.share_buttons?.landing_page_url ?? globalData.socialShareUrl, props.id)
    const { registerSocialShare } = setupSocialShare()
    const { shareLink } = useShareLink()
    const share = (network: string) => {
      shareLink({
        network,
        url: socialShareUrl,
        title: props.title,
        description: props.description
      })
      registerSocialShare(props.id, props.title)
    }
    return { registerSocialShare, socialShareUrl, share }
  }
})
