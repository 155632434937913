<template>
  <div class="right-control-components">
    <PlaybackSpeedComponent :videoPlayer="videoPlayer" v-if="!IS_IOS" />
    <div class="cc-menu-button-container">
      <button
        type="button"
        role="button"
        :aria-label="`${translateLabels('Captions')}`"
        aria-haspopup="true"
        :aria-expanded="isCaptionsDropdownShown"
        v-if="canUseCaptions.value"
        class="pw-player-button"
        @click="toggleCaptions"
        ref="captionBtn"
        v-html="captionBtnString.value"
      ></button>
      <CCMenuComponent
        @mouseleave="offCaptionsHover"
        v-if="isCaptionsDropdownShown"
        :clickCaption="clickCaption"
        :currentCaptions="currentCaptions"
        :subtitleArray="subtitleArray"
      />
    </div>
    <button
      type="button"
      role="button"
      :aria-label="`${translateLabels('Toggle fullscreen')}`"
      v-if="canFullScreen"
      class="pw-player-button"
      @click="togglefullscreen()"
      aria-controls="ccmenu"
    >
      <svg class="icon-control" viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M5.5 7.40909C5.5 6.63087 6.13087 6 6.90909 6H11.4545C12.2328 6 12.8636 6.63087 12.8636 7.40909C12.8636 8.18731 12.2328 8.81818 11.4545 8.81818H8.31818V11.9545C8.31818 12.7328 7.68731 13.3636 6.90909 13.3636C6.13087 13.3636 5.5 12.7328 5.5 11.9545V7.40909ZM19.1364 7.40909C19.1364 6.63087 19.7672 6 20.5455 6H25.0909C25.8691 6 26.5 6.63087 26.5 7.40909V11.9545C26.5 12.7328 25.8691 13.3636 25.0909 13.3636C24.3127 13.3636 23.6818 12.7328 23.6818 11.9545V8.81818H20.5455C19.7672 8.81818 19.1364 8.18731 19.1364 7.40909ZM5.5 20.0455C5.5 19.2672 6.13087 18.6364 6.90909 18.6364C7.68731 18.6364 8.31818 19.2672 8.31818 20.0455V23.1818H11.4545C12.2328 23.1818 12.8636 23.8127 12.8636 24.5909C12.8636 25.3691 12.2328 26 11.4545 26H6.90909C6.13087 26 5.5 25.3691 5.5 24.5909V20.0455ZM23.6818 20.0455C23.6818 19.2672 24.3127 18.6364 25.0909 18.6364C25.8691 18.6364 26.5 19.2672 26.5 20.0455V24.5909C26.5 25.3691 25.8691 26 25.0909 26H20.5455C19.7672 26 19.1364 25.3691 19.1364 24.5909C19.1364 23.8127 19.7672 23.1818 20.5455 23.1818H23.6818V20.0455Z"
          fill="white"
        />
      </svg>
    </button>
  </div>
</template>
<script lang="ts">
import { isFullScreenAvailable } from "@/services/fullScreenService"
import { ComputedRef, defineComponent, PropType, Ref, ref } from "vue"
import setUpFullScreen from "@/composables/shared/fullscreen-hander"
import { isMobile } from "@/services/deviceDetection"
import replaceclass from "@/services/replaceClass"
import { SubtitleUrls } from "@/models/captions/SubtitleUrls"
import CCMenuComponent from "@/components/VideoPlayerComponents/CCMenuComponent.vue"
import injectStrict from "@/utils/injectStrict"
import { EventBusKey, translationKey } from "@/symbols"
import PlaybackSpeedComponent from "@/components/VideoPlayerComponents/PlaybackSpeedComponent.vue"

export default defineComponent({
  components: {
    CCMenuComponent,
    PlaybackSpeedComponent
  },
  props: {
    IS_IOS: { type: Boolean, required: true },
    playerContainer: {
      type: Object as PropType<Ref<HTMLElement | undefined>>,
      required: true
    },
    videoPlayer: {
      type: Object as PropType<Ref<HTMLVideoElement | undefined>>,
      required: true
    },
    captionBtnString: { type: Object as PropType<Ref<String>>, required: true },
    canUseCaptions: {
      type: Object as PropType<Ref<Boolean>>,
      required: true
    },
    subtitleArray: {
      type: Object as PropType<Ref<SubtitleUrls[]>>,
      required: true
    },
    clickCaption: { type: Function, required: false },
    currentCaptions: { type: Object as PropType<Ref<String>>, required: true },
    height: { type: Object as PropType<ComputedRef<number>>, required: true }
  },
  setup(props) {
    let captionHolder = ref<HTMLElement>()
    let isCaptionsDropdownShown = ref<Boolean>()
    let captionScrollTimer = ref(0)
    let canFullScreen = ref(isFullScreenAvailable() && !props.IS_IOS)
    let fullscreenObj = setUpFullScreen(props.playerContainer)
    const { translateLabels } = injectStrict(translationKey)
    let eventbus = injectStrict(EventBusKey)
    return {
      eventbus,
      canFullScreen,
      fullscreenObj,
      captionScrollTimer,
      captionHolder,
      isCaptionsDropdownShown,
      translateLabels
    }
  },
  mounted() {
    this.eventbus.on("remove-fullscreen", () => {
      console.debug("Exiting")
      this.fullscreenObj.exitFullscreen()
    })
  },
  methods: {
    togglefullscreen() {
      this.fullscreenObj.togglefullscreen()
    },
    offCaptionsHover() {
      this.captionScrollTimer = setTimeout(() => {
        this.hideCaptions()
      }, 200)
    },
    toggleCaptions() {
      if (this.isCaptionsDropdownShown == true) this.hideCaptions()
      else this.showCaptions()
    },
    showCaptions() {
      if (isMobile(navigator.userAgent, navigator.maxTouchPoints)) return
      clearTimeout(this.captionScrollTimer)
      replaceclass(this.captionHolder as HTMLElement, `hide`, `show`)
      this.isCaptionsDropdownShown = true
    },
    hideCaptions() {
      clearTimeout(this.captionScrollTimer)
      replaceclass(this.captionHolder as HTMLElement, `show`, `hide`)
      this.isCaptionsDropdownShown = false
    }
  }
})
</script>
<style scoped lang="scss">
button {
  all: unset;
  cursor: pointer;
  background: none;

  &:active {
    background: none;
  }

  &:focus {
    outline: 2px solid var(--theme-color);
    outline-offset: 2px;
  }
}
.cc-menu-button-container {
  display: inline-block;
}

::-webkit-scrollbar {
  width: 10px;
}
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.4);
  background-color: transparent;
  color: transparent;
  border-radius: 10px;
}
::-webkit-scrollbar-thumb {
  border-radius: 10px;
  box-shadow: inset 0 0 6px rgba(155, 155, 155, 0.9);
  background-color: rgba(155, 155, 155, 0.9);
}
.right-control-components {
  display: flex;
  align-items: center;
  gap: 10px;
}
</style>
