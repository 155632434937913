<template>
  <div class="cc-menu-box">
    <ul class="cc-menu" role="menu" ref="captionMenuHolder">
      <li
        class="caption-button"
        :class="{ selected: currentCaptions.value == 'off' }"
        @keypress="clickCaption(`off`)"
        @click="clickCaption(`off`)"
        :aria-label="`${translateLabels('No Captions')} ${currentCaptions.value == 'off' ? 'Checked' : ''}`"
        role="menuitemradio"
        tabindex="0"
      >
        <div>
          <svg
            v-if="currentCaptions.value == `off`"
            class="caption-radio"
            viewBox="0 0 12 12"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <rect class="caption-radio" rx="6" fill="white" />
          </svg>
          <svg v-else class="caption-radio" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M1 6C1 8.76142 3.23858 11 6 11C8.76142 11 11 8.76142 11 6C11 3.23858 8.76142 1 6 1C3.23858 1 1 3.23858 1 6ZM6 0C2.68629 0 0 2.68629 0 6C0 9.31371 2.68629 12 6 12C9.31371 12 12 9.31371 12 6C12 2.68629 9.31371 0 6 0Z"
              fill="#B7B7B7"
            />
          </svg>
        </div>
        <span v-html="`None`"></span>
      </li>
      <li
        :aria-label="`${caption.language ? getLanguageString(caption.language) : 'English'}
                    ${currentCaptions.value == caption.language ? 'Checked' : ''}`"
        tabindex="0"
        class="caption-button"
        :class="{ selected: currentCaptions.value == caption.language }"
        v-for="(caption, i) in subtitleArray.value"
        :key="i"
        @keypress="clickCaption(caption.language ? caption.language : `en`)"
        @click="clickCaption(caption.language ? caption.language : `en`)"
        role="menuitemradio"
      >
        <div>
          <svg
            v-if="currentCaptions.value == caption.language"
            class="caption-radio"
            viewBox="0 0 12 12"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <rect class="caption-radio" rx="6" fill="white" />
          </svg>
          <svg v-else class="caption-radio" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M1 6C1 8.76142 3.23858 11 6 11C8.76142 11 11 8.76142 11 6C11 3.23858 8.76142 1 6 1C3.23858 1 1 3.23858 1 6ZM6 0C2.68629 0 0 2.68629 0 6C0 9.31371 2.68629 12 6 12C9.31371 12 12 9.31371 12 6C12 2.68629 9.31371 0 6 0Z"
              fill="#B7B7B7"
            />
          </svg>
        </div>
        <span v-html="caption.language ? getLanguageString(caption.language) : 'English'"></span>
      </li>
    </ul>
  </div>
</template>
<script lang="ts">
import { SubtitleUrls } from "@/models/captions/SubtitleUrls"
import { LoggerKey, translationKey } from "@/symbols"
import { getLanguageString } from "@/utils/getLanguageString"
import injectStrict from "@/utils/injectStrict"
import { defineComponent, PropType, ref, Ref } from "vue"

export default defineComponent({
  props: {
    subtitleArray: {
      type: Object as PropType<Ref<SubtitleUrls[]>>,
      required: true
    },
    clickCaption: { type: Function, required: false },
    currentCaptions: { type: Object as PropType<Ref<String>>, required: true }
  },
  setup() {
    let iterator = 0
    let captionList = new Array<HTMLElement>()
    let logger = injectStrict(LoggerKey)
    let captionMenuHolder = ref<HTMLElement>()
    const { translateLabels } = injectStrict(translationKey)
    return { getLanguageString, logger, captionMenuHolder, captionList, iterator, translateLabels }
  },
  mounted() {
    this.iterator = 0
    document.addEventListener("keydown", this.captionIterator)
    var collection = this.captionMenuHolder?.getElementsByTagName("li")
    if (!collection) return
    this.captionList = Array.from(collection)
  },
  beforeUnmount() {
    document.removeEventListener("keydown", this.captionIterator)
  },
  methods: {
    captionIterator(e: any) {
      if (["ArrowUp", "ArrowDown"].indexOf(e.code) > -1) e.preventDefault()
      else return
      if (["ArrowUp"].indexOf(e.code) > -1 && this.iterator - 1 >= 0) this.iterator--
      if (["ArrowDown"].indexOf(e.code) > -1 && this.iterator + 1 < this.captionList.length) this.iterator++
      this.captionList[this.iterator].focus()
    }
  }
})
</script>
<style scoped lang="scss">
.cc-menu-box {
  position: relative;

  .cc-menu {
    position: absolute;
    bottom: 16px;
    right: 0;
    background-color: rgba(0, 0, 0, 0.88);
    box-shadow: 0px 1px 6px rgba(0, 0, 0, 0.22);
    border-radius: 4px;
    padding: 12px 0;
    overflow-y: auto;
    max-height: 120px;
  }
}
.caption-button {
  display: flex;
  flex-direction: row;
  align-items: center;
  color: #b7b7b7;
  padding: 4px 32px 4px 16px;
  font-weight: normal;
  font-family: -apple-system, BlinkMacSystemFont, avenir next, avenir, segoe ui, helvetica neue, helvetica, Ubuntu, roboto,
    noto, arial, sans-serif;

  cursor: pointer;

  &:hover,
  .selected {
    color: #ffffff;
  }

  &:focus {
    outline: 2px solid var(--theme-color);
    outline-offset: 2px;
  }

  .caption-radio {
    width: 12px;
    height: 12px;
    margin: 0px 8px;
  }
}
</style>
