
import { MomentInfo } from "@/models/MomentInfo"
import { defineComponent, PropType, ref } from "vue"
import { ContentType } from "@/models/ContentType"
import Player from "@/components/shared/VideoPlayer.vue"
import IframePlayer from "@/components/shared/IframeVideoPlayer.vue"
import injectStrict from "@/utils/injectStrict"
import { GlobalDataKey, translationKey } from "@/symbols"
import LogoOverlayData from "@/models/LogoOverlayData"
import { MAData } from "@/models/ma/MAData"
import { setupSocialShare } from "@/composables/social-share"
import { ShareButtons } from "@/models/ShareButtons"
import SocialShare from "@/components/shared/SocialShare.vue"
import { RelatedResourceData } from "@/models/RelatedResourceData"

export default defineComponent({
  components: { Player, IframePlayer, SocialShare },
  props: {
    moment: { type: Object as PropType<MomentInfo>, required: true },
    useDemoOverlay: { type: Boolean, required: false, default: false },
    cta: { type: Object as PropType<RelatedResourceData>, required: false, default: null },
    logoOverlay: { type: Object as PropType<LogoOverlayData>, required: false, default: null },
    isgated: Boolean,
    ma: { type: Object as PropType<MAData>, required: false, default: null },
    usetheaterMode: { type: Boolean, required: false, default: false },
    post_play: { type: String, required: false, default: undefined },
    share_buttons: { type: Object as PropType<ShareButtons | null>, required: false, default: null }
  },
  setup(props) {
    const globalData = injectStrict(GlobalDataKey).value
    let socialShareUrl = ref(globalData.socialShareUrl)
    const { registerSocialShare } = setupSocialShare()
    const { translateMoment } = injectStrict(translationKey)
    return { ContentType, socialShareUrl, registerSocialShare, translateMoment }
  }
})
