<template>
  <div class="playback-speed">
    <span :aria-label="`${translateLabels('playback speed')}`" @click="toggleSpeedList">{{ currentSpeed }}x</span>
    <ul class="speed-list" ref="speedListRef" @mouseleave="hoverOutSpeedList">
      <li
        v-for="speed in speedValues"
        :key="speed"
        @click="changeSpeed(speed)"
        :class="currentSpeed == speed ? 'selected-playback-speed' : ''"
        :aria-label="`${speed}x`"
      >
        {{ speed }}x
      </li>
    </ul>
  </div>
</template>
<script lang="ts">
import { PropType, Ref, defineComponent, ref } from "vue"
import { translationKey } from "@/symbols"
import injectStrict from "@/utils/injectStrict"
export default defineComponent({
  props: {
    videoPlayer: {
      type: Object as PropType<Ref<HTMLVideoElement>>,
      required: true
    }
  },
  setup() {
    const speedValues = [2, 1.5, 1.25, 1, 0.75, 0.5]
    const speedListRef = ref()
    const currentSpeed = ref(1)
    const hadHovered = ref(false)
    const { translateLabels } = injectStrict(translationKey)
    return {
      speedListRef,
      speedValues,
      hadHovered,
      currentSpeed,
      translateLabels
    }
  },
  methods: {
    toggleSpeedList() {
      this.speedListRef.classList.toggle("show")
    },
    hoverOutSpeedList() {
      this.speedListRef.classList.remove("show")
    },
    changeSpeed(speed: number) {
      if (!this.$props.videoPlayer) return
      this.currentSpeed = speed
      this.$props.videoPlayer.value.playbackRate = speed
      this.toggleSpeedList()
    }
  }
})
</script>
<style scoped>
.playback-speed {
  position: relative;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.playback-speed span {
  font-family: -apple-system, BlinkMacSystemFont, avenir next, avenir, segoe ui, helvetica neue, helvetica, Ubuntu, roboto,
    noto, arial, sans-serif;
  min-width: 45px;
  text-align: center;
  color: #fff;
  font-weight: 600;
  font-size: 16px;
  line-height: 1;
  margin-top: -4px;
  margin-right: -10px;
  cursor: pointer;
  display: block;
  transition: all ease-in-out 400ms;
}
.playback-speed span:hover {
  transform: scale(1.1);
}
.speed-list {
  background-color: rgba(0, 0, 0, 0.88);
  box-shadow: 0px 1px 6px rgba(0, 0, 0, 0.22);
  display: block;
  position: absolute;
  bottom: 35px;
  color: #575757;
  list-style: none;
  padding: 0;
  margin: 0;
  left: -30px;
  transition-property: transform, visibility, opacity;
  transition-timing-function: ease-in-out;
  transition-duration: 400ms;
  transform: translateY(-40px);
  visibility: hidden;
  opacity: 0;
  border-radius: 4px;
}
.show {
  transform: translateY(0px);
  visibility: visible;
  opacity: 1;
}
.speed-list li {
  font-family: -apple-system, BlinkMacSystemFont, avenir next, avenir, segoe ui, helvetica neue, helvetica, Ubuntu, roboto,
    noto, arial, sans-serif;
  padding: 0;
  font-size: 16px;
  line-height: 24px;
  width: 100px;
  text-align: center;
  cursor: pointer;
  transition-property: color, font-weight;
  transition-timing-function: ease-in-out;
  transition-duration: 200ms;
}
.speed-list li:hover {
  color: #fff;
  font-weight: 700;
}
.selected-playback-speed {
  color: #fff;
  font-weight: 700;
}
</style>
