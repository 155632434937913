export function isIOS(userAgent: string = navigator.userAgent, maxTouchPoints: number = navigator.maxTouchPoints) {
  if (/iOS/.test(detectPlatformFromUserAgent(userAgent))) {
    return true
  } else {
    return maxTouchPoints > 2 && /Mac/.test(detectPlatformFromUserAgent(userAgent))
  }
}
export function isMobile(userAgent: string = navigator.userAgent, maxTouchPoints: number = navigator.maxTouchPoints) {
  return /Android/i.test(detectPlatformFromUserAgent(userAgent)) || isIOS(userAgent, maxTouchPoints)
}

export function detectPlatformFromUserAgent(userAgent: string): string {
  if (userAgent.includes("Android")) {
    return "Android"
  } else if (userAgent.includes("Macintosh")) {
    return "Mac"
  } else if (userAgent.includes("Linux")) {
    return "Linux"
  } else if (userAgent.includes("Windows")) {
    return "Windows"
  } else if (userAgent.includes("iPhone") || userAgent.includes("iPad")) {
    return "iOS"
  } else {
    return "Unknown"
  }
}
