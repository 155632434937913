<template>
  <div v-html="videoString" v-if="videoString"></div>
</template>
<script lang="ts">
import { WebinarInfo } from "@/models/WebinarInfo"
import { MomentInfo } from "@/models/MomentInfo"
import { computed, defineComponent, PropType, Ref, ref } from "vue"
import { SingleVideoInfo } from "@/models/SingleVideoInfo"
import { formatDurationForSeo } from "@/services/DurationFormatter"
import { stripHtmlTags } from "@/utils/stripHtmlTags"
import HubWebinar from "@/models/hub/HubWebinar"
export default defineComponent({
  props: {
    webinarInfo: { type: Object as PropType<WebinarInfo>, required: false, default: null },
    videoInfo: { type: Object as PropType<SingleVideoInfo>, required: false, default: null },
    HubWebinars: { type: Object as PropType<Array<HubWebinar>>, required: false, default: null }
  },
  setup() {
    var videoObjects = ref<object>([])
    var videoString = computed(() => `<script type="application/ld+json">${JSON.stringify(videoObjects.value)}`)
    return { videoObjects, videoString }
  },
  mounted() {
    if (this.webinarInfo) {
      this.videoObjects = this.getVideosFromWebinarinfo(this.webinarInfo)
    }
    if (this.videoInfo) {
      this.videoObjects = this.getVideosFromSingleVideoInfo(this.videoInfo)
    }
    if (this.HubWebinars) {
      this.videoObjects = this.getVideosFromHubWebinars(this.HubWebinars)
    }
  },
  methods: {
    getVideoData(
      title: string,
      description: string | null,
      thumbnailUrl: string,
      duration: string,
      uploadDate: string,
      contentUrl: string | null | undefined
    ) {
      return {
        "@context": "https://schema.org",
        "@type": "VideoObject",
        name: this.removeScriptTags(title),
        description: this.removeScriptTags(!!description ? description : title),
        thumbnailUrl: thumbnailUrl,
        uploadDate: `${uploadDate}Z`,
        duration: formatDurationForSeo(duration),
        contentUrl: contentUrl
      }
    },
    removeScriptTags(str: string): string {
      var op = str.replace("<script>", "")
      op = op.replace("<\/script>", "")
      return op
    },
    getVideosFromWebinarinfo(info: WebinarInfo): object[] {
      let videos: object[] = []
      info.moments.forEach((moment: MomentInfo) => {
        var description = moment.description ? stripHtmlTags(moment.description) : null
        if (moment.upload_date && moment.title && moment.video_urls.poster_url) {
          videos.push(
            this.getVideoData(
              moment.title,
              description,
              moment.video_urls.poster_url,
              moment.duration,
              moment.upload_date,
              moment.video_urls.hls_url
            )
          )
        }
      })
      var description = info.description ? stripHtmlTags(info.description) : null
      if (info.upload_date && info.title && info.video_urls.poster_url && info.is_full_video_visible == true)
        videos.push(
          this.getVideoData(
            info.title,
            description,
            info.video_urls.poster_url,
            info.duration,
            info.upload_date,
            info.video_urls.hls_url
          )
        )
      if (info.trailer_data && info.trailer_data.upload_date && info.title && info.trailer_data.video_urls.poster_url)
        videos.push(
          this.getVideoData(
            `${info.title} - Trailer`,
            description,
            info.trailer_data.video_urls.poster_url,
            info.trailer_data.duration,
            info.trailer_data.upload_date,
            info.trailer_data.video_urls.hls_url
          )
        )
      return videos
    },

    getVideosFromSingleVideoInfo(info: SingleVideoInfo): object[] {
      let videos: object[] = []
      var description = info.description ? stripHtmlTags(info.description) : null

      if (info.upload_date && info.title && info.video_urls.poster_url)
        videos.push(
          this.getVideoData(
            info.title,
            description,
            info.video_urls.poster_url,
            info.duration,
            info.upload_date,
            info.video_urls.hls_url
          )
        )

      return videos
    },
    getVideosFromHubWebinars(vids: HubWebinar[]): object[] {
      let videos: object[] = []
      vids.forEach((v: HubWebinar) => {
        var description = v.description ? stripHtmlTags(v.description) : null

        if (v.upload_date && v.title && v.trailer.video_urls.poster_url) {
          videos.push(
            this.getVideoData(
              v.title,
              description,
              v.trailer.video_urls.poster_url,
              v.trailer.duration,
              v.upload_date,
              v.trailer.video_urls.hls_url
            )
          )
        }
      })
      return videos
    }
  }
})
</script>
