export default class CaptionData {
  language: string
  caption_segments: CaptionSegment[]
  public constructor(language: string, caption_segments: CaptionSegment[]) {
    this.language = language
    this.caption_segments = caption_segments
  }
}

export interface CaptionSegment {
  id: string
  startTime: number
  endTime: number
  text: string
}
