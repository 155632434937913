// See: https://github.com/Amplily/amplily-widget-v4/commits/f9e46c19d75ce100c30526eac368ceb88a49ad61/src/amplily-widget/src/addViewportMetaTag.ts

export function addMetaTag() {
  const metatags = Array.from(document.getElementsByTagName("meta"))
  let addTag = true
  metatags.forEach(element => {
    if (element.getAttribute("name") == "viewport") {
      addTag = false
    }
  })
  if (addTag) {
    const meta = document.createElement("meta")
    meta.setAttribute("name", "viewport")
    meta.setAttribute("content", "width=device-width,initial-scale=1.0")
    document.getElementsByTagName("head")[0].appendChild(meta)
  }
}
