
import { computed, defineComponent, ref } from "@vue/runtime-core"

export default defineComponent({
  props: {
    isLoadingFullVideo: { type: Boolean, required: false, default: false }
  },
  setup() {
    var count = ref<number>(0)
    var loading_text = computed(() => {
      var str = "Loading"
      if (count.value > 2) count.value = 0
      for (var i = 0; i < count.value + 1; i++) {
        str += " ."
      }
      return str
    })
    let timer = 0
    return { count, loading_text, timer }
  },
  mounted() {
    this.timer = setInterval(() => {
      this.count++
    }, 400)
  },
  beforeUnmount() {
    clearInterval(this.timer)
  }
})
