import { AwEventV2 } from "../models/AwEventV2"
import { LocalStorageService } from "@/services/LocalStorageService"

import axios from "axios"
import { RootLogger } from "loglevel"
export async function SendAnalytics(event: AwEventV2, url: string, logger: RootLogger | undefined) {
  await axios({
    method: "post",
    url: url,
    data: event
  })
    .then(res => {
      if (res.data.sessionId) new LocalStorageService().setSessionId(res.data.sessionId)
    })
    .catch(function (error) {
      if (logger) logger.error(error)
    })
}
