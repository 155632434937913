export default {
  "Full Video": "पूरा वडय",
  "Related Resources": "संबंधित संसाधन",
  Blog: "ब्लॉग",
  Play: "खेलना",
  "No Captions": "कोई कैप्शन नहीं",
  Captions: "कैप्शन",
  "Toggle fullscreen": "पूर्ण स्क्रीन टॉगल",
  "playback speed": "प्लेबैक स्पीड",
  "Play with captions in": "में कैप्शन के साथ खेलें",
  Seek: "खोजें",
  "Watch Trailer": "ट्रेलर देखें",
  "Watch full video": "पूरी वीडियो देखें",
  "Watch Video": "वीडियो देखें",
  Speakers: "वक्ता",
  Infographic: "इनफोग्राफिक",
  eBook: "ई-बुक",
  Webinar: "वेबिनार",
  Video: "वीडियो",
  "White Paper": "सफेद कागज",
  Slides: "स्लाइड्स",
  Article: "लेख",
  "Icon not found exception": "आइकन नहीं मिला विशिष्टता",
  "Key Moments": "महत्वपूर्ण क्षण",
  Resources: "संसाधन"
}
