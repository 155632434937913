
import { computed, defineComponent, PropType } from "@vue/runtime-core"
import injectStrict from "@/utils/injectStrict"
import { translationKey } from "@/symbols"
import { WebinarInfo } from "@/models/WebinarInfo"
import TrailerTimeline from "@/components/TrailerTimelineComponents/TrailerTimeline.vue"

export default defineComponent({
  components: { TrailerTimeline },
  props: {
    widgetData: {
      type: Object as PropType<WebinarInfo>,
      required: true
    },
    usetheaterMode: { type: Boolean, required: false, default: false }
  },
  setup(props) {
    const moments = computed(() => {
      if (!props.widgetData.moments) return []
      return Array.from(props.widgetData.moments, x => {
        return {
          id: x.id,
          duration: x.duration,
          start_time: x.start_time,
          end_time: x.end_time,
          title: x.title,
          poster_url: x.video_urls.poster_url,
          translations: x.translations
        }
      })
    })
    const showResources = computed(() => props.widgetData.related_resources && props.widgetData.related_resources.length > 0)
    const showMoments = computed(() => moments.value.length > 0)
    const showSpeakers = computed(() => props.widgetData.speakers && props.widgetData.speakers.length > 0)
    const { translateWebinar, translateLabels } = injectStrict(translationKey)
    return { moments, showResources, showMoments, showSpeakers, translateWebinar, translateLabels }
  }
})
