export default {
  "Full Video": "Video completo",
  "Related Resources": "Recursos Relacionados",
  Blog: "Blog",
  Play: "Jugar",
  "No Captions": "Sin Subtítulos",
  Captions: "Subtítulos",
  "Toggle fullscreen": "Alternar pantalla completa",
  "playback speed": "Velocidad de reproducción",
  "Play with captions in": "Reproducir con subtítulos en",
  Seek: "Avanzar/Retroceder",
  "Watch Trailer": "Ver avance",
  "Watch full video": "Ver video completo",
  "Watch Video": "Ver video",
  Speakers: "Altavoces",
  Infographic: "Infografía",
  eBook: "eBook",
  Webinar: "Seminario web",
  Video: "Video",
  "White Paper": "Libro blanco",
  Slides: "Diapositivas",
  Article: "Artículo",
  "Icon not found exception": "Excepción de icono no encontrado",
  "Key Moments": "Momentos Clave",
  Resources: "Recursos"
}
