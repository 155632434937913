
import { isIOS } from "@/services/deviceDetection"
import { GlobalDataKey, translationKey } from "@/symbols"
import injectStrict from "@/utils/injectStrict"
import { computed, defineComponent, PropType, ref, Ref } from "vue"

export default defineComponent({
  props: {
    continuefromTime: { type: Number, required: true },
    hasEnded: { type: Object as PropType<Ref<Boolean>>, required: true },
    dur: { type: Object as PropType<Ref<Number>>, required: true },
    updateWithFullVideo: { type: Function, required: true }
  },
  setup(props) {
    const globalData = injectStrict(GlobalDataKey)
    const { translateLabels } = injectStrict(translationKey)
    const pressed_close = ref(false)
    let showEndedPage = computed(() => {
      pressed_close.value = false
      return props.continuefromTime > 0 && props.hasEnded.value && globalData.value.fullVideoInfo.isReady
    })
    let cont_play_btn_txt = computed(() =>
      isIOS(navigator.userAgent, navigator.maxTouchPoints)
        ? translateLabels(`Watch full video`)
        : translateLabels(`Continue watching`)
    )
    return { showEndedPage, cont_play_btn_txt, pressed_close, translateLabels }
  },
  methods: {
    clickClose() {
      this.pressed_close = true
    }
  }
})
