
import { computed, defineComponent, PropType, ref, watch } from "vue"
import ScriptComponent from "./ScriptComponent.vue"
import { MAData } from "@/models/ma/MAData"
import { setHubspotKnownCache } from "@/services/MarketingAutomationApi"

declare var hbspt: any

export default defineComponent({
  components: { ScriptComponent },
  props: {
    config: Object as PropType<MAData>
  },
  setup: function (props, { emit }) {
    const formScript = `<script charset="utf-8" type="text/javascript" src="//js.hsforms.net/forms/embed/v2.js"><\/script>`
    let root = ref<HTMLElement>()
    let hsFormIframeHeight = ref(150)
    let formContainer = ref<HTMLElement>()
    watch(
      () => formContainer.value?.clientHeight,
      v => {
        if (v) hsFormIframeHeight.value = v
      }
    )

    let hsFormVars = computed(() => {
      return {
        "--hs-form-iframe-height": hsFormIframeHeight.value + "px"
      } as any
    })

    const updateFormHeight = () => {
      setTimeout(() => {
        // form height doesn't change immediatly, so added setTimeout
        if (formContainer.value?.clientHeight) hsFormIframeHeight.value = formContainer.value.clientHeight
      }, 50)
    }

    let isDOM = (node: any) => {
      return typeof node == "object" && "nodeType" in node && node.nodeType === 1 && node.cloneNode
    }

    let scriptLoaded = () => {
      hbspt.forms.create({
        target: "#hsform_container",
        region: `${props.config?.region}`,
        portalId: `${props.config?.ma_account_id}`,
        formId: `${props.config?.form_id}`,
        onFormSubmitted: (form: any) => {
          setHubspotKnownCache(true)
          emit("formsubmitted", form)
        },
        onFormReady: (formEl: any) => {
          formContainer.value = (formEl.context ?? (isDOM(formEl) ? formEl : formEl[0])) as HTMLElement
          formContainer.value.addEventListener("change", x => updateFormHeight())
          formContainer.value.addEventListener("input", x => updateFormHeight())
          formContainer.value.addEventListener("submit", x => updateFormHeight())
          hsFormIframeHeight.value = formContainer.value.clientHeight
          setTimeout(() => updateFormHeight(), 500)
        }
      })
    }

    return {
      root,
      scriptLoaded,
      formScript,
      hsFormVars
    }
  }
})
